import React, {FC} from "react";
import {
    choices,
    Edit,
    EditProps,
    email,
    FormTab, maxLength,
    minLength,
    PasswordInput,
    required,
    TabbedForm,
    TextInput,
} from "react-admin";
import {EnumInput} from "../EnumInput";
import {PartnerSelectInput} from "../Partner/PartnerSelectInput";
import {Resources} from "../../Resources";
import {RoleSelectInput} from "./RoleSelectInput";
import {ServerSideValidatedForm} from "../../GraphQL/ServerSideValidatedForm";
import {CustomerCondition, CustomerGroup} from "../../GraphQL/Generated";

export const CustomerEdit: FC<EditProps> = (props) => (
    <Edit {...props}>
        <ServerSideValidatedForm>
            <TabbedForm>
                <FormTab label={`resources.${Resources.Customer}.tabs.general`}>
                    <TextInput validate={[required(), email()]} source="emailAddress"/>
                    <PasswordInput validate={[minLength(8), maxLength(30)]} source="password"/>

                    <EnumInput validate={[required(), choices(Object.values(CustomerGroup))]} source="group"
                               name="CustomerGroup"/>

                    <EnumInput validate={[required(), choices(Object.values(CustomerCondition))]} source="condition"
                               name="CustomerCondition"/>

                    <PartnerSelectInput validate={required()} fullWidth/>
                </FormTab>
                <FormTab label={`resources.${Resources.Customer}.tabs.roles`}>
                    <RoleSelectInput validate={[required(), minLength(1)]} source="roles"/>
                </FormTab>
            </TabbedForm>
        </ServerSideValidatedForm>
    </Edit>
);