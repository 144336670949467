import {FC, memo, useCallback, useEffect, useState} from "react";
import {
    ArrayField,
    Button,
    Datagrid,
    DateField,
    FieldProps,
    FunctionField,
    ReferenceField,
    Show,
    ShowActionsProps,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    useMutation,
    useRecordContext,
    useRefresh,
    useTranslate,
} from "react-admin";
import {MoneyField} from "../Field/MoneyField";
import {PaymentField} from "../Field/PaymentField";
import {Resources} from "../../Resources";
import {AttributeList} from "../Attribute/AttributeList";
import {AddressField} from "../Field/AddressField";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField as TextFieldMui,
    Toolbar,
    Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {PartnerIconField} from "../Partner/PartnerIconField";
import {OrderXml} from "./OrderXml";
import {EnumField} from "../Field/EnumField";
import {CustomData} from "./CustomData";
import {Order, OrderStatus} from "../../GraphQL/Generated";
import classes from "../Show/CustomShow.module.scss";
import {useResourceTranslate} from "../../I18n/useResourceTranslate";

const useStyles = makeStyles({
    root: {
        marginBottom: "2em",
        marginTop: "1em",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
});

interface SendMailFieldProps extends FieldProps {
    type: string
}

const MailButton: FC<SendMailFieldProps> = memo((props) => {

    const record = useRecordContext(props);
    const order = record as Order;

    const [sendMail, {loading}] = useMutation({
        type: "create",
        resource: Resources.OrderMail,
        payload: {data: {orderId: order?.id, mailType: props.type.toUpperCase()}},
    });

    if(!order) {
        return null;
    }

    return <Button label={`resources.${Resources.Order}.mail.${props.type}`}
                   onClick={sendMail} disabled={loading}/>;
});

const CancelOrder: FC<FieldProps> = memo((props) => {

    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const [hint, setHint] = useState("");

    const record = useRecordContext(props);
    const translate = useResourceTranslate(Resources.Order);
    const order = record as Order;

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
        setHint("");
    }, [setOpen, setHint]);

    const [cancelOrder, {loading, loaded}] = useMutation({
        type: "create",
        resource: Resources.CancelOrder,
        payload: {data: {orderId: order?.id, hint: hint}},
    });

    useEffect(() => {
        if (loaded) {
            handleClose();
        }
    }, [loaded]);

    const onCancelClick = useCallback(() => {
        cancelOrder();
        handleClose();
        refresh();
    }, [cancelOrder, handleClose, refresh]);

    const onChange = useCallback((e) => {
        setHint(e.currentTarget.value);
    }, [setHint]);

    if(!order) {
        return null;
    }

    return (
        <>
            <Button disabled={loading || order.statusId === OrderStatus.Canceled}
                    onClick={handleClickOpen}
                    label={`resources.${Resources.Order}.cancel`}/>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>
                    {translate("cancel")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate("cancelHint")}
                    </DialogContentText>
                    <TextFieldMui defaultValue={hint}
                                  fullWidth
                                  onChange={onChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClick}
                            label={`resources.${Resources.Order}.cancel`}
                            disabled={loading || hint.length === 0}
                    />
                    <Button onClick={handleClose}
                            label={`resources.${Resources.Order}.close`}
                            disabled={loading}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
});

const ShowActions: FC<ShowActionsProps> = memo(() => {
    return (
        <Toolbar>
            <Box flexGrow="1">
                <h2 className={classes.headline}>Bestellung</h2>
            </Box>
            <Box display="flex" justifySelf="end" mr={4}>
                <MailButton type="admin"/>
                <MailButton type="customer"/>
                <CancelOrder/>
            </Box>
        </Toolbar>
    );
});

export const OrderShow: FC<ShowProps> = (props) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Show {...props} actions={<ShowActions/>}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <TabbedShowLayout>
                        <Tab label={`resources.${Resources.Order}.tabs.general`}>
                            <Typography variant="button">
                                {translate(`resources.${Resources.Order}.fields.products`)}
                            </Typography>
                            <ArrayField source="products" label="">
                                <Datagrid>
                                    <TextField source="quantity"/>
                                    <EnumField source="type"
                                               name="OrderProductType"
                                               addLabel={false}/>
                                    <FunctionField
                                        label={`resources.${Resources.Order}.fields.products`}
                                        render={
                                            () => (
                                                <>
                                                    <TextField source="name" display="block"/>
                                                    <ReferenceField reference={Resources.Product}
                                                                    source="product.id"
                                                                    link="show">
                                                        <TextField source="model" display="block"/>
                                                    </ReferenceField>
                                                    <CustomData source="customData"/>
                                                </>
                                            )
                                        }/>
                                    <MoneyField source="price" textAlign="right"/>
                                    <MoneyField source="totalPrice" textAlign="right"/>
                                </Datagrid>
                            </ArrayField>
                            <Typography variant="button">
                                {translate(`resources.${Resources.Order}.fields.orderTotal`)}
                            </Typography>
                            <ArrayField source="totals" label="">
                                <Datagrid>
                                    <TextField
                                        source="title"
                                        label={`resources.${Resources.Order}.fields.orderTotals.title`}
                                    />
                                    <MoneyField
                                        source="value"
                                        textAlign="right"
                                        label={`resources.${Resources.Order}.fields.orderTotals.value`}
                                    />
                                </Datagrid>
                            </ArrayField>
                        </Tab>
                        <Tab label={`resources.${Resources.Order}.tabs.history`}>
                            <ArrayField source="history" addLabel={false}>
                                <Datagrid>
                                    <DateField source="dateTime" showTime={true}/>
                                    <TextField source="status"/>
                                    <TextField source="comment"/>
                                </Datagrid>
                            </ArrayField>
                        </Tab>
                    </TabbedShowLayout>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <div className={classes.root}>
                        <AttributeList attributes={[
                            {name: "id", copyable: true},
                            "status",
                            {
                                name: "customer",
                                render: () => (
                                    <ReferenceField
                                        reference={Resources.Customer}
                                        label={`resources.${Resources.Order}.fields.customerName`}
                                        source="customer.id"
                                        link="show">
                                        <TextField source="name"/>
                                    </ReferenceField>
                                ),
                            },
                            {
                                name: "deliveryAddress",
                                render: () => (
                                    <AddressField source="deliveryAddress"/>
                                ),
                            },
                            {
                                name: "billingAddress",
                                render: () => (
                                    <AddressField source="billingAddress"/>
                                ),
                            },
                            {name: "creationDate", type: "date"},
                            {name: "lastModificationDate", type: "date"},
                            {
                                name: "partner",
                                render: () => (
                                    <ReferenceField reference={Resources.Partner}
                                                    source="partner.id"
                                                    link={false}>
                                        <PartnerIconField displayName={true}/>
                                    </ReferenceField>
                                ),
                            },
                            {
                                name: "paymentMethod",
                                render: () => (
                                    <PaymentField source="paymentMethod"/>
                                ),
                            },
                            {
                                name: "orderXml",
                                render: () => (
                                    <OrderXml/>
                                ),
                            },
                            {name: "catalogOrdered", type: "boolean"},
                        ]}/>
                    </div>
                </Grid>
            </Grid>
        </Show>
    );
};