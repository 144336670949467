import {ApolloLink, FetchResult, NextLink, Operation, RequestHandler, Observable} from "@apollo/client";
import {AppContextProps} from "../../Context/AppContext";
import {assertDefined} from "../../Assert";

export class AppContextLink extends ApolloLink {
    private readonly appContext: AppContextProps;

    constructor(appContext: AppContextProps, request?: RequestHandler) {
        super(request);
        this.appContext = appContext;
    }

    request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
        operation.setContext(() => ({
            appContext: this.appContext,
        }));

        assertDefined(forward);
        return forward(operation);
    }
}

/**
 * The fromOperation function retrieves the AppContext from a apollo link context. The AppContextLink must be present
 * in the link chain before any link that uses the `fromOperation` function.
 *
 * @param operation The apollo operation to retrieve the context from
 * @returns AppContextProps
 */
export function fromOperation(operation: Operation): AppContextProps {
    assertDefined(operation.getContext().appContext);
    return operation.getContext().appContext;
}