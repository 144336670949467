import {Edit, EditProps, TextInput} from "ra-ui-materialui";
import {required, SimpleForm} from "react-admin";
import React, {ReactElement} from "react";
import {EnumInput} from "../EnumInput";
import {PartnerSelectInput} from "../Partner/PartnerSelectInput";
import {Resources} from "../../Resources";

export const RedirectEdit = (props: EditProps): ReactElement => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="path"
                       fullWidth={true}
                       validate={required()}
                       helperText={`resources.${Resources.Redirect}.helperText.path`}
            />
            <EnumInput source="statusCode"
                       name="RedirectStatusCode"
                       validate={required()}
            />
            <TextInput source="target"
                       fullWidth={true}
                       validate={required()}
                       helperText={`resources.${Resources.Redirect}.helperText.target`}
            />
            <PartnerSelectInput validate={required()} fullWidth/>
        </SimpleForm>
    </Edit>
);
