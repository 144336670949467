import {FC, memo} from "react";
import {Entry} from "./Entry";
import {VscFile, VscFileCode, VscFileMedia, VscFolder} from "react-icons/vsc";
import {makeStyles} from "@material-ui/styles";

interface EntryIconProps {
    entry: Entry
}

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        display: "flex",
        fontSize: "24px",
        fontWeight: "bold",
        justifyContent: "center",
    },
});

export const EntryIcon: FC<EntryIconProps> = memo(({entry}) => {
    const classes = useStyles();

    const getIcon = () => {
        if (!entry.isDir) {
            if (entry.mimeType) {
                const [category, type] = entry.mimeType?.split("/");
                switch (category) {
                    case "image":
                        return <VscFileMedia/>;
                    case "text":
                        switch (type) {
                            case "x-php":
                            case "html":
                            case "javascript":
                                return <VscFileCode/>;
                        }
                        break;

                    case "application":
                        switch (type) {
                            case "json":
                                return <VscFileCode/>;
                        }
                        break;
                }
            }

            return <VscFile/>;
        }

        return <VscFolder/>;
    };

    return (
        <div className={classes.root}>{getIcon()}</div>
    );
});