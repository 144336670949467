import * as React from "react";
import {FC, memo} from "react";
import {MenuItemLink, useResourceDefinition, useTranslate} from "react-admin";
import {useSelector} from "react-redux";
import {ReduxState} from "ra-core";
import DefaultIcon from "@material-ui/icons/ViewList";
import {useQueryType} from "../../Hooks/useQueryType";

interface MenuItemProps {
    resource: string
}

export const MenuItem: FC<MenuItemProps> = memo(({resource}) => {
    const translate = useTranslate();
    const definition = useResourceDefinition({resource});
    const {name, hasList} = definition;
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const type = useQueryType(name);

    if (!hasList || type === null) {
        return null;
    }

    return (
        <MenuItemLink
            to={`/${name}`}
            primaryText={translate(`resources.${name}.name`, {smart_count: 2})}
            sidebarIsOpen={open}
            leftIcon={definition.icon ? <definition.icon/> : <DefaultIcon/>}
        />
    );
});