import {FC, useEffect} from "react";
import {Edit, EditProps, FormTab, TabbedForm, TextInput} from "react-admin";
import {Localized} from "../Language/Localized";
import {ServerSideValidatedForm} from "../../GraphQL/ServerSideValidatedForm";
import {Resources} from "../../Resources";
import path from "path";
import {useFilePathBreadcrumb} from "./Hooks/useFilePathBreadcrumb";
import {EnumInput} from "../EnumInput";

export const FileEdit: FC<EditProps> = (props) => {
    let basePath = `/${Resources.File}`;
    const match = props.match;
    const params = match?.params as { id?: string };
    const setPath = useFilePathBreadcrumb();

    useEffect(() => {
        if (params.id) {
            setPath(params.id);
        }
    }, [setPath, params.id]);

    if (params.id) {
        const dirname = path.dirname(params.id);
        if (dirname !== ".") {
            basePath += `/${dirname}`;
        }
    }

    return (
        <Localized {...props} resource={Resources.File}>
            <Edit {...props} id={params.id} basePath={basePath} resource={Resources.File}>
                <ServerSideValidatedForm>
                    <TabbedForm>
                        <FormTab label={`resources.${Resources.File}.tabs.general`}>
                            <TextInput source="imageMetadata.title"/>
                            <TextInput source="imageMetadata.alt"/>
                        </FormTab>
                        <FormTab label={`resources.${Resources.File}.tabs.permissions`}>
                            <EnumInput source="permissionMetadata.readRoles" name="Role" multiple={true} fullWidth/>
                            <EnumInput source="permissionMetadata.writeRoles" name="Role" multiple={true} fullWidth/>
                        </FormTab>
                    </TabbedForm>
                </ServerSideValidatedForm>
            </Edit>
        </Localized>
    );
};