import React, {FC} from "react";
import {Datagrid, List, TextField} from "ra-ui-materialui";
import {DefaultFilter} from "../DefaultFilter";

export const CategoryList: FC = (props) => (
    <List {...props}
          sort={{field: "slug", order: "ASC"}}
          filters={<DefaultFilter />}
    >
        <Datagrid rowClick="edit">
            <TextField source="slug" />
            <TextField source="name" sortable={false} />
            <TextField source="url" sortable={false} />
        </Datagrid>
    </List>
);