export const SET_RESOURCE_LANGUAGE = "SET_RESOURCE_LANGUAGE";

/**
 * Sets the currently selected language for a resource.
 */
interface SetResourceLanguageAction {
    type: typeof SET_RESOURCE_LANGUAGE
    payload: {
        language: string
        resource: string
    }
}

export type LanguageActionTypes = SetResourceLanguageAction