/**
 * The Resources enum holds the available Resources. A Resource is basically a GraphQL Type with a defined set of
 * queries and mutations.
 */
export enum Resources {
    Address = "Address",
    Category = "Category",
    Country = "Country",
    Coupon = "Coupon",
    CronJob = "CronJob",
    Customer = "Customer",
    ExpressionNode = "ExpressionNode",
    Faq = "Faq",
    FaqAnswer = "FaqAnswer",
    FaqQuestion = "FaqQuestion",
    File = "File",
    FileMeta = "FileMeta",
    FormCustomizer = "FormCustomizer",
    Language = "Language",
    OciFieldValue = "OciFieldValue",
    OciMappingField = "OciMappingField",
    Order = "Order",
    OrderMail = "OrderMail",
    CancelOrder = "CancelOrder",
    Partner = "Partner",
    Product = "Product",
    ProductGroup = "ProductGroup",
    Redirect = "Redirect",
    SearchOptimization = "SearchOptimization",
    SearchOptimizationReview = "SearchOptimizationReview",
    Setting = "Setting",
    Shipping = "Shipping",
    ShippingModule = "ShippingModule"
}