import {ApolloLink, FetchResult, NextLink, Observable, Operation, RequestHandler} from "@apollo/client";
import {AppContextProps} from "../../Context/AppContext";
import {sprintf} from "sprintf-js";
import {fromOperation} from "./AppContextLink";
import {assertDefined} from "../../Assert";
import {TokenType} from "../../Security/TokenType";

/**
 * The AuthorizationLink adds an "Authorization" Bearer Token to every request.
 */
export class AuthorizationLink extends ApolloLink {
    constructor(request?: RequestHandler) {
        super(request);
    }

    request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
        const appContext: AppContextProps = fromOperation(operation);

        assertDefined(forward);
        assertDefined(appContext.auth, "auth");

        if (appContext.auth.tokenType === TokenType.BearerToken) {
            const authorization = sprintf("Bearer %s", appContext.auth.data);
            operation.setContext(({headers}: Record<string, { headers: Record<string, string> }>) => ({
                headers: {
                    authorization,
                    ...headers,
                },
            }));
        }

        return forward(operation);
    }
}