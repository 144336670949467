import React from "react";
import {Datagrid, List, TextField} from "ra-ui-materialui";
import {DefaultFilter} from "../DefaultFilter";
import {BooleanField} from "../Field/BooleanField";

export const SearchOptimizationReviewList = (props: React.PropsWithoutRef<unknown>): React.ReactElement => (
    <List {...props}
          filters={<DefaultFilter/>}>
        <Datagrid rowClick="edit">
            <BooleanField source="solved"/>
            <TextField source="searchQuery"/>
            <TextField source="name"/>
            <TextField source="email"/>
        </Datagrid>
    </List>
);