import {FC, useCallback, useContext, useState} from "react";
import {Box, Button, IconButton} from "@material-ui/core";
import {Entry} from "./Entry";
import {Resources} from "../../../Resources";
import {makeStyles} from "@material-ui/core/styles";
import {UploadDialog} from "./UploadDialog";
import {ManagerDispatchContext} from "./Reducer";
import {CreateDirectoryDialog} from "./CreateDirectoryDialog";
import {useManagerContext} from "./Context";
import {useResourceTranslate} from "../../../I18n/useResourceTranslate";
import {Edit} from "@material-ui/icons";

interface ManagerActionsProps {
    selectedEntries: Entry[]
    currentPath: string
}

const useStyles = makeStyles({
    toolbar: {
        "marginBottom": "1rem",

        "& .MuiButton-root": {
            marginRight: 10,
        },
    },
});

export const ManagerActions: FC<ManagerActionsProps> = ({selectedEntries, currentPath}) => {
    const classes = useStyles();
    const translate = useResourceTranslate(Resources.File);
    const [uploadOpen, setUploadOpen] = useState<boolean>(false);
    const [createDirectoryOpen, setCreateDirectoryOpen] = useState<boolean>(false);
    const dispatch = useContext(ManagerDispatchContext);
    const {delete: deleteEntries, createDirectory, upload, editFile} = useManagerContext();

    const handleUploads = useCallback(async (files: File[]) => {
        setUploadOpen(false);
        await upload(files);
    }, [dispatch, upload]);

    const onCreateDirectory = useCallback(async function onCreateDirectory(path: string) {
        setCreateDirectoryOpen(false);
        await createDirectory(path);
    }, [dispatch, createDirectory]);

    return (
        <Box display="flex" className={classes.toolbar}>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setUploadOpen(true)}
                >
                    {translate("actions.upload", {
                        smart_count: 1,
                    })}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setCreateDirectoryOpen(true)}
                >
                    {translate("directory.actions.create", {
                        smart_count: 1,
                    })}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => deleteEntries(selectedEntries)}
                    disabled={selectedEntries.length === 0}
                >
                    {translate("actions.delete", {
                        smart_count: Math.max(selectedEntries.length, 1),
                    })}
                </Button>

                <IconButton
                    onClick={() => editFile(selectedEntries[0])}
                    disabled={selectedEntries.length === 0 || selectedEntries.length > 1}
                    title={translate("actions.edit")}
                >
                    <Edit/>
                </IconButton>
            </div>

            <UploadDialog
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                onUpload={handleUploads}
            />

            <CreateDirectoryDialog
                open={createDirectoryOpen}
                onClose={() => setCreateDirectoryOpen(false)}
                onCreate={onCreateDirectory}
                currentPath={currentPath}
            />
        </Box>
    );
};