import React, {useCallback} from "react";
import {InputProps, NumberInput, RadioButtonGroupInput, TextInput, useTranslate} from "react-admin";
import {ExpressionValueType} from "../../GraphQL/Generated";
import {Resources} from "../../Resources";
import {sprintf} from "sprintf-js";
import omit from "lodash/omit";

type OperatorValueInputProps = InputProps & {
    expressionValueType: ExpressionValueType
}

export const OperatorValueInput = (props: OperatorValueInputProps): React.ReactElement | null => {
    const {expressionValueType} = props;
    const translate = useTranslate();
    const childProps = omit(props, ["expressionValueType"]) as InputProps;
    const toString = useCallback((v: number|undefined) => v?.toString(), []);

    switch (expressionValueType) {
        case ExpressionValueType.Boolean:
            return (
                <RadioButtonGroupInput
                    {...childProps}
                    choices={[
                        {id: "1", name: "Wahr"},
                        {id: "0", name: "Falsch"},
                    ]}
                    row
                />
            );

        case ExpressionValueType.Array:
            return (
                <TextInput
                    {...childProps}
                    helperText={translate(
                        sprintf("resources.%s.valueHelperText.%s", Resources.ExpressionNode, ExpressionValueType.Array)
                    )}
                />
            );

        case ExpressionValueType.Numeric:
            return <NumberInput {...childProps} step={0.1} parse={toString}/>;
    }

    return <TextInput {...childProps} />;
};