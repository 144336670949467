import {FC} from "react";
import {Box, Grid} from "@material-ui/core";
import {Resources} from "../../Resources";
import {DashboardTile} from "./DashboardTile";
import {Orders} from "./Orders";
import {Authorized} from "../Authorized";

export const Dashboard: FC = () => {
    return (
        <Box mt={3} mb={4}>
            <Grid container spacing={3}>
                <Authorized resource={Resources.Customer}>
                    <Grid item md={3}>
                        <DashboardTile/>
                    </Grid>
                </Authorized>
                <Authorized resource={Resources.Product}>
                    <Grid item md={3}>
                        <DashboardTile/>
                    </Grid>
                </Authorized>
                <Authorized resource={Resources.Order}>
                    <Grid item md={3}>
                        <DashboardTile/>
                    </Grid>
                </Authorized>
                <Authorized resource={Resources.FaqQuestion}>
                    <Grid item md={3}>
                        <DashboardTile/>
                    </Grid>
                </Authorized>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Orders/>
                </Grid>
            </Grid>
        </Box>
    );
};