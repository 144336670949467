import {useEffect, useRef, useState} from "react";
import isEqual from "lodash/isEqual";

/**
 * The useKeyPress hook returns true when a certain key is pressed.
 *
 * @param targetKeys The keys the user has to press. See
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values}
 * for a list of keys.
 *
 * Source from https://usehooks.com/useKeyPress/
 */
export function useKeyPress(...targetKeys: string[]): boolean {
    const [active, setActive] = useState<boolean>(false);
    const keysPressedRef = useRef<Record<string, null>>();

    const setKeysPressed = (keysPressed: Record<string, null>) => {
        keysPressedRef.current = keysPressed;
    };

    const downHandler = (e: KeyboardEvent) => {
        const {key} = e;
        if (targetKeys.includes(key)) {
            const newMap = {...keysPressedRef.current, ...{[key]: null}};
            setKeysPressed(newMap);

            if (isEqual(Object.keys(newMap), targetKeys)) {
                setActive(true);
                e.preventDefault();
            }
        }
    };

    const upHandler = ({key}: KeyboardEvent) => {
        if (targetKeys.includes(key)) {
            const newKeys = {...keysPressedRef.current};
            delete newKeys[key];
            setKeysPressed(newKeys);
            setActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []);

    return active;
}