import {CacheInterface} from "./CacheInterface";

export class LocalStorageCache implements CacheInterface<string, string> {
    private localStorage: Storage;

    constructor(localStorage = window.localStorage) {
        this.localStorage = localStorage;
    }

    async get(key: string): Promise<string | null> {
        if (await this.has(key)) {
            return this.localStorage.getItem(key);
        }
        return null;
    }

    async has(key: string): Promise<boolean> {
        return this.localStorage.getItem(key) !== null;
    }

    async remove(key: string): Promise<void> {
        this.localStorage.removeItem(key);
    }

    async set(key: string, value: string): Promise<void> {
        this.localStorage.setItem(key, value);
    }
}