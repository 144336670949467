import {FC} from "react";
import {useAccessibleResourceMap} from "../Security/Authorization/Hooks";
import {ResourceContext} from "ra-core";
import {useResourceContext} from "react-admin";

export interface AuthorizedProps {
    // Optionally override the resource
    resource?: string
}

/**
 * Authorized only displays its children when the current user has access to `resource` and
 * the `all${resource}s` operation.
 *
 * @param props The Authorized Props.
 * @class
 */
export const Authorized: FC<AuthorizedProps> = (props) => {
    const resource = useResourceContext(props);
    const resourceMap = useAccessibleResourceMap();

    if (!resourceMap.canAccess(resource, `all${resource}s`)) {
        return null;
    }

    return (
        <ResourceContext.Provider value={resource}>
            {props.children}
        </ResourceContext.Provider>
    );
};