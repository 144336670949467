import {InputProps, ReferenceArrayInput, SelectArrayInput} from "react-admin";
import {Resources} from "../../Resources";
import React from "react";

export const PartnerSelectInput = (props: Omit<Omit<InputProps, "source">, "reference">): React.ReactElement => {
    return (
        <ReferenceArrayInput
            {...props}
            source="partnerIds"
            reference={Resources.Partner}
            sort={{ field: "name", order: "ASC" }}
        >
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
    );
};