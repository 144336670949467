import React, {PropsWithChildren} from "react";

interface IfProps {
    expr: unknown,
    children: React.ReactElement
}

/**
 * The If Component only shows its children when `expr` is truthy.
 *
 * @param props The element props
 * @returns React.ReactElement
 */
export const If = (props: PropsWithChildren<IfProps>): React.ReactElement | null => {
    if (props.expr) {
        return props.children;
    }
    return null;
};