import React, {FC, memo} from "react";
import {FieldProps, useRecordContext, useTranslate} from "react-admin";
import {Order} from "../../GraphQL/Generated";
import {Button} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import {Resources} from "../../Resources";

export const OrderXml: FC<FieldProps> = memo((props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    const order = record as Order;
    const uriContent = "data:application/xml," + encodeURIComponent(order.orderXml);
    return (
        <Button size="small"
                title="order.xml"
                href={uriContent}
                download="order.xml">
            <GetAppIcon/>
            <span>{translate(`resources.${Resources.Order}.download`)}</span>
        </Button>
    );
});