/**
 * Error is the base Error for all errors in the application. Don't use directly. See LogicError and RuntimeError
 */
export abstract class Error extends window.Error {
    private previous?: Error;

    protected constructor(message?: string, previous?: Error) {
        super(message);
        this.previous = previous;
    }
}