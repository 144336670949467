import {Entry} from "./Entry";
import {keyBy} from "lodash";
import {createContext, Dispatch, useReducer} from "react";

export type Actions =
    { type: "setEntries", entries: Entry[] } |
    { type: "setCurrentPath", path: string } |
    { type: "select", entries: Entry[] } |
    { type: "setCurrentEntry", entry: Entry } |
    { type: "clearCurrentEntry" } |
    { type: "setLoading", loading: boolean };


export const ManagerDispatchContext = createContext<Dispatch<Actions>>(() => {
    throw new Error("not implemented");
});

export interface State {
    entries: Entry[]
    pathMap: Record<string, Entry>
    selectedEntries: Entry[]
    currentPath: string
    currentEntry?: Entry
    loading: boolean
}

const updateEntries = (state: State, entries: Entry[]): State => {
    const {currentPath} = state;
    if (currentPath) {
        const lastSegmentIndex = currentPath.lastIndexOf("/");

        let prevPath = "";
        if (lastSegmentIndex !== undefined && lastSegmentIndex > -1) {
            prevPath = currentPath.slice(0, lastSegmentIndex);
        }

        entries = [
            {path: prevPath, name: "..", isDir: true},
            ...entries,
        ];
    }

    return {...state, entries: entries, pathMap: keyBy(entries, "path")};
};

const initState = (state: State): State => {
    return {
        currentPath: state.currentPath,
        entries: state.entries,
        loading: false,
        pathMap: keyBy(state.entries, "path"),
        selectedEntries: [],
    };
};

/**
 * The createReducer factory creates the Manager reducer.
 *
 * @param currentPath The current path.
 * @param entries The list of entries.
 * @param loading Whether the manager is loading.
 */
export default (
    currentPath: string,
    entries: Entry[],
    loading: boolean
) => useReducer((state: State, action: Actions): State => {
    switch (action.type) {
        case "setEntries":
            return updateEntries(state, action.entries);
        case "setCurrentPath":
            return {...state, currentPath: action.path};
        case "select":
            return {...state, selectedEntries: action.entries};
        case "setCurrentEntry":
            return {...state, currentEntry: !action.entry.isDir ? action.entry : undefined};
        case "clearCurrentEntry":
            return {...state, currentEntry: undefined};
    }

    return state;
}, {
    currentPath,
    entries,
    loading,
    pathMap: {},
    selectedEntries: [],
}, initState);