import React from "react";
import {List, TextField} from "ra-ui-materialui";
import {DefaultFilter} from "../DefaultFilter";
import {ArrayField, ChipField, Datagrid, SingleFieldList} from "react-admin";

export const SearchOptimizationList = (props: React.PropsWithoutRef<unknown>): React.ReactElement => (
    <List {...props}
          filters={<DefaultFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ArrayField source="queries">
                <SingleFieldList linkType={false}>
                    <ChipField source="query"
                               size="small"
                               variant="outlined"/>
                </SingleFieldList>
            </ArrayField>
            <TextField source="comment"/>
        </Datagrid>
    </List>
);
