import {useContext} from "react";
import {AppContext} from "../Context/AppContext";
import {IntrospectionSchema} from "graphql";

/**
 * The useIntrospectionSchema provides components with the introspected GraphQL schema.
 *
 * @returns IntrospectionSchema | null
 */
export function useIntrospectionSchema(): IntrospectionSchema | null {
    const context = useContext(AppContext);
    return context.schema || null;
}