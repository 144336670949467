import React, {FC, memo} from "react";
import {FieldProps, Labeled, useRecordContext} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import get from "lodash/get";

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: "monospace",
        display: "block",
        fontSize: ".8em",
    },
}));

export const CustomData: FC<FieldProps> = memo((props) => {
    const {source, resource} = props;
    const record = useRecordContext(props);
    const classes = useStyles();

    if (!source) {
        return null;
    }

    const value = get(record, source);
    if (!value) {
        return null;
    }

    return (
        <Labeled source={source} resource={resource}>
            <Typography className={classes.root}>{value}</Typography>
        </Labeled>
    );
});