import {AuthProvider as ReactAdminAuthProvider, UserIdentity} from "react-admin";
import {ok} from "../../Result";
import gql from "graphql-tag";
import {Authentication, AuthenticationType} from "../../Security/Authentication";
import {ApolloError, isApolloError} from "@apollo/client";
import {ServerError} from "apollo-link-http-common";
import {AppContextProps} from "../../Context/AppContext";
import {PasswordCredentialsAuthenticator} from "../../Security/OAuth2/PasswordCredentialsAuthenticator";
import {assertDefined} from "../../Assert";

/**
 * The AuthProvider implements the react-admin AuthProvider interface and talks to the Brewes GraphQL API.
 */
export class AuthProvider implements ReactAdminAuthProvider {
    private readonly context: AppContextProps;

    constructor(context: AppContextProps) {
        this.context = context;
    }

    async checkAuth(): Promise<void> {
        const {auth} = this.context;
        if (auth?.data && auth?.data !== "" && auth?.type === AuthenticationType.PasswordCredentials) {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }

    async checkError(err: { status: number } | ApolloError): Promise<void> {
        let statusCode: number | null = null;
        if (err !== null && isApolloError(err as ApolloError)) {
            const apolloError = err as ApolloError;
            if (apolloError.networkError) {
                statusCode = (apolloError.networkError as ServerError).statusCode;
            }
        }

        if (statusCode === null && Object.prototype.hasOwnProperty.call(err, "status")) {
            statusCode = (err as { status: number }).status;
        }

        // user is unauthorized
        if (statusCode !== null && statusCode === 401) {
            await this.logout();
            return Promise.reject();
        }

        return Promise.resolve();
    }

    async getIdentity(): Promise<UserIdentity> {
        const {client} = this.context;
        assertDefined(client);

        const cachedIdentity = await this.context.cache?.get("identity");
        if (cachedIdentity) {
            return JSON.parse(cachedIdentity);
        }

        const res = await client.query({
            query: gql`query Me {
                me {
                    roles
                    userName

                    ... on Customer{
                        id
                        name
                        reachableRoles
                    }
                }

                accessibleResources {
                    name
                    operations
                }
            }`,
            fetchPolicy: "no-cache",
        });

        const {me: {id, name, roles, userName, reachableRoles}, accessibleResources} = res.data;

        const identity = {
            accessibleResources,
            fullName: name,
            id,
            reachableRoles,
            roles,
            userName,
        };

        this.context.cache?.set("identity", JSON.stringify(identity));

        return identity;
    }

    async getPermissions(): Promise<string[]> {
        const {accessibleResources} = await this.getIdentity();
        return accessibleResources;
    }

    async login({username, password}: { username: string, password: string }): Promise<Authentication<string>> {
        assertDefined(this.context.config);

        const {
            config: {OAuth: {URI, scope, clientCredentials: {clientId, clientSecret}}},
            cache,
        } = this.context;

        assertDefined(cache);

        const authenticator = new PasswordCredentialsAuthenticator(scope);
        const result = await authenticator.authenticate(URI, {
            clientId,
            clientSecret,
            password,
            username,
        });

        if (!ok(result)) {
            return Promise.reject(result);
        }

        if (!result.data) {
            return Promise.reject("authentication result has no data field");
        }

        await cache.set("auth", JSON.stringify(result));

        this.context.auth = result;

        return result;
    }

    async logout(): Promise<void | false | string> {
        const {cache} = this.context;
        assertDefined(cache);

        this.context.auth = undefined;

        await cache.remove("auth");
        await cache.remove("identity");
        return Promise.resolve();
    }
}

