import React from "react";
import {Datagrid, Filter, List, TextField, TextInput} from "ra-ui-materialui";
import {BooleanField, EditButton, ReferenceArrayField, SingleFieldList, useTranslate} from "react-admin";
import {Resources} from "../../Resources";
import {PartnerIconField} from "../Partner/PartnerIconField";
import {EnumField} from "../Field/EnumField";

const CustomerFilter = (props: unknown) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput label={translate("general.search")} source="q" alwaysOn/>
        </Filter>
    );
};

export const CustomerList = (props: React.PropsWithoutRef<unknown>): React.ReactElement => (
    <List {...props}
          filters={<CustomerFilter/>}
    >
        <Datagrid rowClick="show">
            <TextField source="customerNumber"/>
            <TextField source="name" sortable={false}/>
            <TextField source="emailAddress"/>
            <EnumField source="condition" name="CustomerCondition" addLabel={false}/>
            <BooleanField source="admin"/>
            <ReferenceArrayField reference={Resources.Partner} source="partnerIds" sortable={false}>
                <SingleFieldList>
                    <PartnerIconField/>
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton/>
        </Datagrid>
    </List>
);