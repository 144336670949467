import {Create, EditProps, TextInput} from "ra-ui-materialui";
import {BooleanInput, required, SimpleForm} from "react-admin";
import React, {ReactElement} from "react";
import {Resources} from "../../Resources";

export const CronJobCreate = (props: EditProps): ReactElement => (
    <Create {...props}>
        <SimpleForm>
            <BooleanInput source="isActive"/>
            <TextInput source="name"
                       validate={required()}
            />
            <TextInput source="timeCode"
                       helperText={`resources.${Resources.CronJob}.helperText.timeCode`}
            />
            <TextInput source="command"
                       fullWidth={true}
                       validate={required()}
                       helperText={`resources.${Resources.CronJob}.helperText.command`}
            />
            <BooleanInput source="isPhp"
                          fullWitdh={true}
                          helperText={`resources.${Resources.CronJob}.helperText.isPhp`}
            />
            <TextInput source="output"
                       fullWidth={true}
                       helperText={`resources.${Resources.CronJob}.helperText.output`}
            />
            <TextInput source="comment" multiline={true} fullWidth={true} rows={3}/>
        </SimpleForm>
    </Create>
);
