import React from "react";
import {FieldProps} from "ra-ui-materialui";
import {PartnerIcon} from "./PartnerIcon";
import {Partner} from "../../GraphQL/Generated";
import {makeStyles} from "@material-ui/core/styles";
import {sprintf} from "sprintf-js";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignContent: "center",
    },
    text: {
        marginLeft: ".3em",
    },
    icon: {
        marginRight: ".2em",
        borderRadius: "50%",
        overflow: "hidden",
        border: sprintf("2px solid %s", theme.palette.grey.A100),
        width: "1.25em",
        height: "1.25em",
        display: "flex",
    },
}));

interface PartnerIconFieldProps extends FieldProps {
    displayName?: boolean
}

export const PartnerIconField = ({record, displayName}: PartnerIconFieldProps): React.ReactElement | null => {

    const classes = useStyles();

    if (!record) {
        return null;
    }

    if (displayName) {
        const partner = record as Partner;
        const name = partner.name;

        return (
            <div className={classes.root}>
                <div className={classes.icon}>
                    <PartnerIcon partner={record as Partner}/>
                </div>
                <Typography className={classes.text}>{name}</Typography>
            </div>
        );
    }

    return (
        <div className={classes.icon}>
            <PartnerIcon partner={record as Partner}/>
        </div>
    );
};