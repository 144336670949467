import {ArrayInput, LoadingIndicator, SimpleFormIterator, TextInput} from "ra-ui-materialui";
import React, {FC, useMemo, useState} from "react";
import {FormDataConsumer, InputProps, required, SelectInput, useQueryWithStore} from "react-admin";
import {Resources} from "../../Resources";
import {ArrayInputProps} from "ra-ui-materialui/lib/input/ArrayInput";
import omit from "lodash/omit";
import {Grid} from "@material-ui/core";
import {assertDefined} from "../../Assert";
import {makeStyles} from "@material-ui/core/styles";
import {ErrorDisplay} from "../ErrorDisplay";
import {OciFieldValue, OciMappingField} from "../../GraphQL/Generated";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}));

export const OciMappingInput: FC<InputProps> = (props) => {
    const classes = useStyles();

    const {
        loaded: mappingFieldsLoaded,
        error: mappingFieldsError,
        data: mappingFieldsData,
    } = useQueryWithStore({
        type: "getList",
        resource: Resources.OciMappingField,
        payload: {
            pagination: {page: 0, perPage: 9999},
            sort: {field: "name", order: "ASC"},
        },
    });

    const {
        loaded: mappingFieldValuesLoaded,
        error: mappingFieldValuesError,
        data: mappingFieldValuesData,
    } = useQueryWithStore({
        type: "getList",
        resource: Resources.OciFieldValue,
        payload: {
            pagination: {page: 0, perPage: 9999},
            sort: {field: "name", order: "ASC"},
        },
    });

    const mappingFieldsChoices = useMemo(() => {
        if (!mappingFieldsData) {
            return [];
        }

        return mappingFieldsData.map(({id, description}: OciMappingField) => ({
            id,
            name: description,
        }));
    }, [mappingFieldsData]);

    const mappingFieldValuesChoices = useMemo(() => {
        if (!mappingFieldValuesData) {
            return [];
        }

        return mappingFieldValuesData.map(({id, description}: OciFieldValue) => ({
            id,
            name: description,
        }));
    }, [mappingFieldValuesData]);

    if (!mappingFieldsLoaded
        || mappingFieldsData === undefined
        || !mappingFieldValuesLoaded
        || mappingFieldValuesData === undefined) {
        return <LoadingIndicator/>;
    }

    if (mappingFieldsError !== null) {
        return <ErrorDisplay message={mappingFieldsError.message} error={mappingFieldsError}/>;
    }

    if (mappingFieldValuesError !== null) {
        return <ErrorDisplay message={mappingFieldValuesError.message} error={mappingFieldValuesError}/>;
    }

    return (
        <ArrayInput label={false} {...(omit(props, "children") as ArrayInputProps)}>
            <SimpleFormIterator>
                <FormDataConsumer>
                    {({getSource, scopedFormData}) => {
                        assertDefined(getSource);
                        const [selectedValueType, setSelectedValueType] = useState<string | null>(
                            scopedFormData?.valueType || null
                        );
                        return (
                            <div className={classes.root}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={5}>
                                        <SelectInput
                                            label={`resources.${Resources.OciMappingField}.fields.id`}
                                            choices={mappingFieldsChoices}
                                            source={getSource("field")}
                                            record={scopedFormData}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <SelectInput
                                            label={`resources.${Resources.OciMappingField}.fields.valueType`}
                                            choices={mappingFieldValuesChoices}
                                            source={getSource("valueType")}
                                            onChange={(e) => setSelectedValueType(e.target.value)}
                                            record={scopedFormData}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        {
                                            selectedValueType === "string" ? (
                                                <TextInput
                                                    label={`resources.${Resources.OciMappingField}.fields.value`}
                                                    source={getSource("value")}/>
                                            ) : null
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};