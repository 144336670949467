import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Entry} from "./Entry";
import {Box, Grid, Paper, Typography} from "@material-ui/core";
import {useTranslate} from "react-admin";
import {Resources} from "../../../Resources";
import {EntryListAside} from "./EntryListAside";
import {DataGrid, GridCellEditCommitParams, GridColDef, GridSelectionModel} from "@material-ui/data-grid";
import prettyBytes from "pretty-bytes";
import {makeStyles} from "@material-ui/core/styles";
import {ManagerDispatchContext} from "./Reducer";
import classNames from "classnames";
import {EntryIcon} from "./EntryIcon";
import path from "path";
import {useManagerContext} from "./Context";
import {useKeyPress} from "../../../Io/useKeyPress";
import {pull} from "lodash";
import {useSorter} from "./useSorter";
import {useLocaleContext} from "../../../I18n/LocaleContext";

interface EntryListProps {
    entries: Entry[]
    pathMap: Record<string, Entry>
    currentEntry?: Entry
    loading?: boolean
}

const useStyles = makeStyles((theme) => ({
    directory: {
        fontWeight: "bold",
    },

    iconCell: {
        "&.MuiDataGrid-cell": {
            justifyContent: "center !important",
        },
    },

    preview: {
        boxShadow: `inset 5px 0 ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.grey[50],
    },

    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cellCheckbox .Mui-disabled": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cellCheckbox:focus-within": {
            outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            transition: "box-shadow 250ms",
        },
    },
}));

export const EntryList: FC<EntryListProps> = ({entries, pathMap, currentEntry, loading}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const dispatch = useContext(ManagerDispatchContext);
    const {move, open} = useManagerContext();
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const isCtrlPressed = useKeyPress("Control");
    const sorter = useSorter();
    const {dateFormatter} = useLocaleContext();

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                cellClassName: classes.iconCell,
                disableColumnMenu: true,
                disableReorder: true,
                field: "isDir",
                headerName: " ",
                hideSortIcons: true,
                renderCell: ({row}) => <EntryIcon entry={row as Entry}/>,
                width: 70,
            },
            {
                editable: true,
                field: "name",
                flex: 1,
                headerName: translate(`resources.${Resources.File}.fields.name`),
                sortComparator: sorter,
                type: "string",
            },
            {
                field: "size",
                headerName: translate(`resources.${Resources.File}.fields.size`),
                sortComparator: sorter,
                valueFormatter: (params) => {
                    if (params.row.isDir) {
                        return "-";
                    }

                    return typeof params.value === "number" ? prettyBytes(params.value) : "-";
                },
                width: 120,
            },
            {
                field: "lastModificationDate",
                headerName: translate(`resources.${Resources.File}.fields.modified`),
                sortComparator: sorter,
                type: "dateTime",
                valueFormatter: (params) => {
                    if (params.value instanceof Date) {
                        return dateFormatter.format(params.value);
                    }
                    return "-";
                },
                width: 250,
            },
        ];
    }, [translate, sorter, dateFormatter]);

    const onSelectionChange = useCallback((newSelectionModel: GridSelectionModel) => {
        setSelectionModel(newSelectionModel);
    }, [dispatch, pathMap]);

    const onCellEditCommit = useCallback(async ({id, value}: GridCellEditCommitParams) => {
        if (id && value) {
            const strId = id.toString();
            const basePath = path.dirname(strId);
            const to = path.join(basePath, value.toString());

            if (to !== strId) {
                await move(strId, to);
            }
        }
    }, [dispatch]);

    const getRowClassName = useCallback((params): string => {
        return classNames({
            [classes.preview]: params.id === currentEntry?.path,
            [classes.directory]: params.row.isDir,
        });
    }, [currentEntry]);

    const getRowId = useCallback((row) => row.path, []);
    const isRowSelectable = useCallback(({row}) => row.name !== "..", []);

    const onRowClick = useCallback((params) => {
        if (isCtrlPressed) {
            dispatch({type: "clearCurrentEntry"});

            if (selectionModel.includes(params.id)) {
                setSelectionModel(pull([...selectionModel], params.id));
            } else {
                setSelectionModel([...selectionModel, params.id]);
            }
        } else {
            setSelectionModel([params.id]);

            const entry: Entry = params.row;
            open(entry);
            dispatch({type: "setCurrentEntry", entry});
        }
    }, [dispatch, selectionModel, isCtrlPressed]);

    useEffect(() => {
        const entries = selectionModel.map((path) => {
            return pathMap[path];
        });

        dispatch({type: "select", entries: entries});
    }, [dispatch, selectionModel]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
                <Paper>
                    <DataGrid
                        autoHeight
                        className={classes.root}
                        columns={columns}
                        disableSelectionOnClick
                        getRowClassName={getRowClassName}
                        getRowId={getRowId}
                        hideFooter
                        hideFooterPagination
                        isRowSelectable={isRowSelectable}
                        onCellEditCommit={onCellEditCommit}
                        onRowClick={onRowClick}
                        onSelectionModelChange={onSelectionChange}
                        rows={entries}
                        selectionModel={selectionModel}
                        loading={loading}
                    />
                </Paper>

                <Box mt={2}>
                    <Typography variant="caption" color="textSecondary" component="p">
                        <code>{"<Doppelklick>"}</code>: Datei Umbenennen <br/>
                        <code>Strg+{"<Doppelklick>"}</code>: Ordner Umbenennen <br/>
                        <code>Strg+{"<Click>"}</code>: Mehrfachauswahl
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
                <EntryListAside currentEntry={currentEntry}/>
            </Grid>
        </Grid>
    );
};