import {AppContextBuilderInterface} from "./AppContextBuilderInterface";
import {AppContextProps} from "../AppContext";
import {AppContextDirectorInterface} from "./AppContextDirectorInterface";

export class AppContextDirector implements AppContextDirectorInterface {
    async constructAppContext(builder: AppContextBuilderInterface): Promise<AppContextProps> {
        builder.reset();
        await builder.buildConfig();
        await builder.buildCache();
        await builder.buildClient();
        await builder.buildAuthProvider();
        await builder.buildDataProvider();
        return builder.getContext();
    }
}