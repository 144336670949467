import {FC, useCallback, useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography,
} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import {Delete, Description} from "@material-ui/icons";
import {Resources} from "../../../Resources";
import {makeStyles} from "@material-ui/core/styles";
import {ellipsis} from "../../../Strings";
import {useManagerContext} from "./Context";
import {useResourceTranslate} from "../../../I18n/useResourceTranslate";
import {useTranslate} from "react-admin";

export interface UploadDialogProps {
    /**
     * Whether the dialog should be visible.
     */
    open: boolean

    /**
     * onUpload is called when the user has selected files to upload and clicked the upload button.
     */
    onUpload(files: File[]): void

    /**
     * onClose is called when the dialog is closed.
     */
    onClose(): void

    /**
     * The maximum a user can select at once.
     */
    maxFiles?: number
}

const useStyles = makeStyles((theme) => ({
    dragArea: {
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        },
        "alignItems": "center",
        "backgroundColor": theme.palette.grey[200],
        "cursor": "pointer",
        "display": "flex",
        "justifyContent": "center",
        "padding": "2rem",
    },
    dialogContent: {
        flexShrink: 0,
    },
}));

export const UploadDialog: FC<UploadDialogProps> = ({open, onUpload, onClose, maxFiles = 15}) => {
    const [files, setFiles] = useState<File[]>([]);
    const translate = useTranslate();
    const uploadTranslate = useResourceTranslate(Resources.File);
    const classes = useStyles();
    const {accept} = useManagerContext();

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
        accept,
        onDrop,
        maxFiles,
    });

    const removeFileAt = (i: number) => {
        const newFiles = [...files];
        newFiles.splice(i, 1);
        setFiles(newFiles);
    };

    useEffect(() => {
        if (!open) {
            setFiles([]);
        }
    }, [open]);

    useEffect(() => {
        if (fileRejections.length > 0) {
            // too many files uploaded
            if (fileRejections.length > maxFiles) {
                const files = fileRejections.reduce((acc, f) => {
                    if (f.errors.some((e) => e.code === "too-many-files")) {
                        acc.push(f.file);
                    }
                    return acc;
                }, [] as File[]);

                setFiles(files.slice(0, maxFiles));
                return;
            }
        }
    }, [fileRejections]);

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogContent className={classes.dialogContent}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        <div>
                            <Paper className={classes.dragArea}>
                                <Typography variant="body2" color="textSecondary">
                                    {
                                        isDragActive ?
                                            uploadTranslate("upload.dragEnabled") :
                                            uploadTranslate("upload.dragDisabled")
                                    }
                                </Typography>
                            </Paper>
                            <Typography variant="caption" color="secondary">
                                {uploadTranslate("upload.maxFiles", {count: maxFiles})}
                            </Typography>
                        </div>
                    }
                </div>
            </DialogContent>

            <List dense>
                {files.map((f, i) => {
                    return (
                        <ListItem key={i}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Description/>
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText secondary={f.type} title={f.name}>
                                {ellipsis(f.name, 36)}
                            </ListItemText>

                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => removeFileAt(i)}>
                                    <Delete/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>

            <DialogActions>
                <Button color="primary" onClick={() => onClose()} autoFocus>
                    {translate("actions.cancel")}
                </Button>
                <Button color="primary" onClick={() => onUpload(files)} disabled={!files.length}>
                    {uploadTranslate("actions.upload", {smart_count: Math.max(1, files.length)})}
                </Button>
            </DialogActions>
        </Dialog>
    );
};