import {
    CreateButton,
    DeleteButton,
    EditButton,
    Record,
    Show,
    ShowActionsProps,
    ShowProps,
    useShowContext,
    useShowController,
} from "react-admin";
import {cloneElement, FC, memo, ReactElement} from "react";
import classes from "./CustomShow.module.scss";
import omit from "lodash/omit";
import {Box, Toolbar} from "@material-ui/core";

type CustomShowProps = ShowProps & {
    children: ReactElement
    headline?: string | ReactElement | ((record: Record | undefined) => string | ReactElement)
};

const CustomShowActions: FC<ShowActionsProps & Partial<CustomShowProps>> = memo((props) => {
    const {basePath, data, headline} = props;
    const {hasEdit, hasCreate} = useShowContext(props);

    return (
        <Toolbar>
            <Box flexGrow="1">
                <h2 className={classes.headline}>{headline}</h2>
            </Box>
            <Box display="flex" justifySelf="end" mr={4}>
                {hasCreate ? <CreateButton basePath={basePath} record={data} className={classes.button}/> : null}
                {hasEdit ? <EditButton basePath={basePath} record={data} className={classes.button}/> : null}
                <DeleteButton basePath={basePath} record={data} className={classes.button}/>
            </Box>
        </Toolbar>
    );
});

const CustomShow = (props: CustomShowProps): ReactElement => {
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
        version, // integer used by the refresh feature
    } = useShowController(props);
    let {headline} = props;

    if (!headline) {
        headline = defaultTitle;
    }
    if (typeof headline === "function") {
        headline = headline(record);
    }

    return (
        <Show
            actions={<CustomShowActions headline={headline}/>}
            {...omit(props, ["headline", "children"])}
        >
            {cloneElement(props.children, {
                basePath,
                record,
                resource,
                version,
            })}
        </Show>
    );
};

export default CustomShow;