import {VisitorFactory} from "./VisitorFactory";
import {ASTVisitor} from "graphql/language";

export class ManyDefaultFilterInputFixVisitor implements VisitorFactory {
    private defaultFilterInputVariables: Record<string, null> = {};

    canHandle(fetchType: string): boolean {
        return fetchType === "GET_MANY";
    }

    create(): ASTVisitor {
        return {
            VariableDefinition: (node) => {
                if (
                    node.type.kind === "NamedType"
                    && node.type.name.value === "DefaultFilterInput"
                    && !(node.variable.name.value in this.defaultFilterInputVariables)
                ) {
                    this.defaultFilterInputVariables[node.variable.name.value] = null;
                }
            },
        };
    }

    modifyVariables(variables: Record<string, {ids: {id: string}[] | string[]}>): void {
        Object.keys(variables).forEach((k) => {
            if (k in this.defaultFilterInputVariables) {
                variables[k].ids = variables[k].ids.map((val: {id: string} | string) => {
                    if (typeof val !== "string") {
                        return val.id;
                    }
                    return val;
                });
            }
        });
    }
}