import {FC} from "react";
import {ChipField, BooleanField, Datagrid, List, ListProps, TextField} from "react-admin";
import {DefaultFilter} from "../DefaultFilter";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    monospace: {
        fontFamily: "monospace",
        fontSize: "0.8em",
    },
});

export const CronJobList: FC<ListProps> = (props) => {
    const classes = useStyles();
    return (
        <List
            {...props}
            filters={<DefaultFilter/>}>
            <Datagrid rowClick="show" optimized>
                <BooleanField source="isActive"/>
                <TextField source="name" sortable={false}/>
                <ChipField className={classes.monospace} source="timeCode" sortable={false}/>
                <TextField className={classes.monospace} source="command" sortable={false}/>
            </Datagrid>
        </List>
    );
};