import {Datagrid, DateField, EditButton, List, TextField} from "ra-ui-materialui";
import React, {ReactElement} from "react";
import {EnumField} from "../Field/EnumField";
import {DefaultFilter} from "../DefaultFilter";
import {PartnerIconField} from "../Partner/PartnerIconField";
import {ReferenceArrayField, SingleFieldList} from "react-admin";
import {Resources} from "../../Resources";
import {BooleanField} from "../Field/BooleanField";

export const CouponList = (props: unknown): ReactElement => (
    <List {...props}
          filters={<DefaultFilter/>}
          sort={{field: "endDate", order: "DESC"}}
    >
        <Datagrid rowClick="edit">
            <BooleanField source="active" sortable={false}/>
            <TextField source="code"/>
            <EnumField source="type" name="CouponType" addLabel={false}/>
            <TextField source="name" sortable={false}/>
            <DateField source="startDate"/>
            <DateField source="endDate"/>
            <ReferenceArrayField reference={Resources.Partner} source="partnerIds" sortable={false}>
                <SingleFieldList>
                    <PartnerIconField/>
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton/>
        </Datagrid>
    </List>
);