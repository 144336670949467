import {InjectedFieldProps, PublicFieldProps} from "ra-ui-materialui/src/field/types";
import React, {FC, memo, useCallback} from "react";
import get from "lodash/get";
import {InvalidPropsError} from "../../Error/InvalidPropsError";
import {Labeled, useTranslate} from "react-admin";
import {sprintf} from "sprintf-js";
import find from "lodash/find";
import {useIntrospectionType} from "../../Hooks/useIntrospectionType";
import {Box, Chip, Typography} from "@material-ui/core";

interface EnumFieldProps extends PublicFieldProps, InjectedFieldProps {
    /**
     * The name of the enum.
     */
    name: string
}

/**
 * EnumField shows an enum value to the user. It looks in the AppContext's  schema for a type that has the
 * same name as the provided `name` prop. The Component will then throw an exception when the provided type does not
 * exist or is not a ENUM type.
 *
 * @param props The EnumFieldProps object
 * @returns React.ReactElement
 * @throws InvalidPropsError
 */
export const EnumField: FC<EnumFieldProps> = memo((props) => {
        const {source, resource, record, name, addLabel} = props;
        const enumType = useIntrospectionType(name);
        const translate = useTranslate();
        const label = addLabel === undefined ? true : addLabel;

        const getEnumName = useCallback((value: string) => {
            if (!enumType) {
                throw new InvalidPropsError(sprintf("enum %s  is not a registered type", name));
            }

            if (enumType.kind !== "ENUM") {
                throw new InvalidPropsError(sprintf("enum %s is not a registered type", name));
            }

            const existing = find(enumType.enumValues, {name: value});
            if (!existing) {
                throw new InvalidPropsError(
                    sprintf("enum \"%s\" has no value \"%s\"", enumType.name, value)
                );
            }

            return translate(sprintf("enum.%s.%s", enumType.name, value));
        }, [enumType]);

        if (!source) {
            return null;
        }

        const value = get(record, source);
        const getEnum = () => (
            <Typography
                component="span"
                variant="body2">
                {
                    Array.isArray(value)
                        ?
                        // render multiple values
                        <Box display="flex">
                            {value.map((v) => <Box mr={1}><Chip label={getEnumName(v)}/></Box>)}
                        </Box>
                        :

                        // render single value
                        getEnumName(value)
                }
            </Typography>
        );

        if (!label) {
            return getEnum();
        }
        return <Labeled source={source} resource={resource}>{getEnum()}</Labeled>;
    }
);