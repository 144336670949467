import React from "react";
import {AutocompleteInput, Create, CreateProps, ReferenceInput} from "ra-ui-materialui";
import {required, SimpleForm, TextInput} from "react-admin";
import {Resources} from "../../Resources";

export const CategoryCreate = (props: CreateProps): React.ReactElement => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <TextInput source="url" validate={required()}/>
            <TextInput source="slug" validate={required()}/>

            <ReferenceInput
                reference={Resources.Category}
                source="parentId"
            >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);