import {BooleanInput, Create, CreateProps, TextInput} from "ra-ui-materialui";
import {FormTab, required, TabbedForm} from "react-admin";
import React, {FC} from "react";
import {Resources} from "../../Resources";
import {ServerSideValidatedForm} from "../../GraphQL/ServerSideValidatedForm";
import {EnumInput} from "../EnumInput";

export const PartnerCreate: FC<CreateProps> = (props) => {

    return (
        <Create {...props}>
            <ServerSideValidatedForm>
                <TabbedForm>
                    <FormTab label={`resources.${Resources.Partner}.tabs.general`}>
                        <BooleanInput source="isSapPartner" defaultValue={false}/>
                        <TextInput source="name" validate={required()}/>
                        <TextInput source="identifier" validate={required()}/>
                        <TextInput source="template" validate={required()}/>
                        <TextInput source="trustedShopsId"/>
                        <TextInput source="googleTagManagerId"/>
                        <TextInput source="usercentricsId"/>
                        <EnumInput name="CheckoutModuleType" source="checkoutModule" validate={required()}/>
                        <EnumInput name="CartStorageMode" source="cartStorageMode" validate={required()}/>
                    </FormTab>
                    <FormTab label={`resources.${Resources.Partner}.tabs.seo`}>
                        <TextInput source="title" validate={required()}/>
                        <TextInput source="description" validate={required()} fullWidth/>
                        <TextInput source="descriptionExpression" validate={required()} fullWidth/>
                        <TextInput source="titleExpression" validate={required()} fullWidth/>
                    </FormTab>
                    <FormTab label={`resources.${Resources.Partner}.tabs.hosts`}>
                        <TextInput source="host" validate={required()}/>
                        <TextInput source="mediaHost" validate={required()}/>
                        <TextInput source="staticHost" validate={required()}/>
                        <TextInput source="imageHost" validate={required()}/>
                    </FormTab>
                </TabbedForm>
            </ServerSideValidatedForm>
        </Create>
    );
};
