import {IntrospectionType} from "graphql";
import {useIntrospectionSchema} from "./useIntrospectionSchema";
import {useIntrospectionSchemaTypeMap} from "./useIntrospectionSchemaTypeMap";

/**
 * The useIntrospectionType provides components with a type from the GraphQL introspection schema.
 *
 * @param name The name of the type to return
 * @returns T | null Returns the type or null when the type doesn't exist in the schema.
 */
export function useIntrospectionType<T extends IntrospectionType = IntrospectionType>(name: string): T | null {
    const schema = useIntrospectionSchema();
    const map = useIntrospectionSchemaTypeMap();

    return (schema?.types[map[name]] as T) ?? null;
}

