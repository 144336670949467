import {Edit, EditProps, TextField, TextInput} from "ra-ui-materialui";
import {BooleanInput, required, SimpleForm} from "react-admin";
import React, {ReactElement} from "react";

export const SearchOptimizationReviewEdit = (props: EditProps): ReactElement => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="searchQuery"/>
            <TextField source="email"/>
            <TextField source="name"/>
            <TextField source="message" fullWidth={true}/>
            <hr />
            <TextInput source="answer"
                       multiline={true}
                       rows={5}
                       validate={required()}
                       fullWidth={true}/>
            <BooleanInput source="solved" validate={required()} defaultValue={false}/>
        </SimpleForm>
    </Edit>
);
