import {RuntimeError} from "../../Error/RuntimeError";
import {sprintf} from "sprintf-js";

/**
 * Represents an error that occurs during Authentication.
 */
export class AuthenticationError extends RuntimeError {
    /**
     * Creates an error that occurs when the authentication failed.
     *
     * @param uri           The uri that the user wanted to authenticate against
     * @param previous      The previous error
     * @returns A new AuthenticationError
     */
    static createForAuthenticationAttempt(uri: string, previous?: Error): AuthenticationError {
        let msg = sprintf("failed to authenticate with \"%s\"", uri);

        if (previous) {
            msg += sprintf(": %s", previous.message);
        }

        return new AuthenticationError(msg, previous);
    }
}