import React from "react";
import {
    BooleanInput,
    Edit,
    EditProps,
    FormTab,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextInput,
} from "react-admin";
import {Resources} from "../../Resources";
import {EnumInput} from "../EnumInput";
import {Localized} from "../Language/Localized";
import {ExpressionInput} from "../Expression/ExpressionInput";
import {ExpressionNodeType} from "../../GraphQL/Generated";
import {PartnerSelectInput} from "../Partner/PartnerSelectInput";

export const ShippingEdit = (props: EditProps): React.ReactElement => (
    <Localized {...props}>
        <Edit {...props}>
            <TabbedForm>
                <FormTab label={`resources.${Resources.Shipping}.tabs.general`}>
                    <TextInput source="name" validate={required()}/>
                    <TextInput source="model" validate={required()}/>
                    <ReferenceInput
                        reference={Resources.ShippingModule}
                        source="shippingModule.id"
                        validate={required()}
                    >
                        <SelectInput optionText="title"/>
                    </ReferenceInput>
                    <EnumInput
                        source="autoAppend"
                        name="ShippingAppendMode"
                        validate={required()}
                    />
                    <BooleanInput
                        source="selectable"
                        validate={required()}
                    />
                    <BooleanInput
                        source="combinable"
                        validate={required()}
                    />
                    <NumberInput
                        source="priority"
                        validate={required()}
                    />
                    <TextInput
                        source="comment"
                        options={{multiline: true, rows: 5}}
                        fullWidth
                    />

                    <PartnerSelectInput validate={required()} fullWidth />
                </FormTab>

                {/* Constraints / Einschränkungen */}
                <FormTab label={`resources.${Resources.Shipping}.tabs.constraints`}>
                    <ExpressionInput
                        fullWidth
                        source="constraints"
                        type={ExpressionNodeType.ShoppingCart}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    </Localized>
);