import * as React from "react";
import {FC} from "react";
import {AppBar as MuiAppBar} from "react-admin";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {AppBarProps} from "ra-ui-materialui/lib/layout/AppBar";

const useStyles = makeStyles({
    title: {
        flex: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    spacer: {
        flex: 1,
    },
    logo: {
        height: 26,
    },
});

export const AppBar: FC<AppBarProps> = (props) => {
    const classes = useStyles();
    return (
        <MuiAppBar {...props} color="primary">
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
            />
            <img src="logo.svg" className={classes.logo} />
            <span className={classes.spacer}/>
        </MuiAppBar>
    );
};