import React, {ReactElement, useCallback, useState} from "react";
import {
    ArrayInput,
    ArrayInputProps,
    FormDataConsumer,
    InputProps,
    minLength,
    NumberInput,
    required,
    SimpleFormIterator,
    useTranslate,
} from "react-admin";
import {makeStyles} from "@material-ui/styles";
import {assertDefined} from "../../Assert";
import {Resources} from "../../Resources";
import {Grid, Typography} from "@material-ui/core";
import {BooleanInput, TextInput} from "ra-ui-materialui";
import {EnumInput} from "../EnumInput";
import omit from "lodash/omit";

const useStyles = makeStyles({
    root: {
        marginBottom: "2em",
        marginTop: "1em",
    },
    requiredCell: {
        display: "flex",
        alignItems: "center",
    },
    requiredItem: {
        marginLeft: "1em",
    },
});

export const FormCustomizerFieldInputField = (props: InputProps): ReactElement => {
    const classes = useStyles();
    return (
        <ArrayInput label={false} {...(omit(props, "children") as ArrayInputProps)}
                    validate={[required(), minLength(1)]}>
            <SimpleFormIterator>
                <FormDataConsumer>
                    {({getSource, scopedFormData}) => {
                        assertDefined(getSource);
                        const translate = useTranslate();

                        const transKey = (name: string): string => {
                            return `resources.${Resources.FormCustomizer}.fields.${name}`;
                        };
                        const helpKey = (name: string): string => {
                            return `resources.${Resources.FormCustomizer}.helperText.${name}`;
                        };

                        const [selectedType, setSelectedType] = useState<string | null>(
                            scopedFormData?.type || "INPUT"
                        );
                        const type = `enum.FormCustomizerFieldType.${selectedType}`;

                        const onTypeChange = useCallback(
                            (e) => setSelectedType(e.target.value),
                            [setSelectedType]
                        );

                        return (
                            <div className={classes.root}>
                                <Typography variant="h6"
                                            gutterBottom
                                            component="div">
                                    {
                                        scopedFormData?.title
                                            ? scopedFormData.title
                                            : translate(type)
                                    }
                                </Typography>
                                <Grid container spacing={1}>

                                    <Grid item xs={12} lg={4}>
                                        <TextInput
                                            label={transKey("fieldName")}
                                            source={getSource("name")}
                                            validate={required()}/>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <EnumInput
                                            name="FormCustomizerFieldType"
                                            label={transKey("type")}
                                            onChange={onTypeChange}
                                            source={getSource("type")}
                                            defaultValue="INPUT"/>
                                    </Grid>
                                    <Grid xs={12} lg={4} className={classes.requiredCell}>
                                        <BooleanInput
                                            className={classes.requiredItem}
                                            helperText={false}
                                            defaultValue={false}
                                            label={transKey("isRequired")}
                                            source={getSource("isRequired")}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={4}>
                                        <TextInput
                                            validate={required()}
                                            source={getSource("title")}
                                            label={transKey("title")}/>

                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        <TextInput
                                            label={transKey("description")}
                                            source={getSource("description")}/>
                                    </Grid>
                                    {
                                        selectedType === "CHOICE" ? (
                                            <Grid item xs={12} lg={12}>
                                                <ArrayInput
                                                    label={transKey("options")}
                                                    source={getSource("options")}
                                                    validate={[required(), minLength(1)]}>
                                                    <SimpleFormIterator>
                                                        <TextInput
                                                            label={transKey("option")}
                                                            source="value"/>
                                                    </SimpleFormIterator>
                                                </ArrayInput>
                                            </Grid>
                                        ) : null
                                    }
                                    {
                                        selectedType === "INPUT" || selectedType === "TEXTAREA" ? (
                                            <>
                                                <Grid item xs={12} lg={4}>
                                                    <TextInput
                                                        label={transKey("priceFormula")}
                                                        source={getSource("priceFormula")}
                                                        helperText={helpKey("priceFormula")}/>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <NumberInput
                                                        label={transKey("maxLength")}
                                                        source={getSource("maxLength")}/>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <TextInput
                                                        label={transKey("regex")}
                                                        source={getSource("regex")}
                                                        helperText={helpKey("regex")}/>
                                                </Grid>
                                            </>
                                        ) : null
                                    }
                                </Grid>
                            </div>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};