import {DateInput, Edit, EditProps, FormTab, NumberInput, TabbedForm, TextInput} from "ra-ui-materialui";
import {BooleanInput, minLength, required} from "react-admin";
import {EnumInput} from "../EnumInput";
import React, {ReactElement} from "react";
import {ExpressionInput} from "../Expression/ExpressionInput";
import {ExpressionNodeType} from "../../GraphQL/Generated";
import {makeStyles} from "@material-ui/core/styles";
import {Localized} from "../Language/Localized";
import {Resources} from "../../Resources";
import {PartnerSelectInput} from "../Partner/PartnerSelectInput";
import {ServerSideValidatedForm} from "../../GraphQL/ServerSideValidatedForm";

const useStyles = makeStyles({
    constraintInput: {
        marginTop: "3rem",
    },
});

export const CouponEdit = (props: EditProps): ReactElement => {
    const classes = useStyles();

    return (
        <Localized {...props}>
            <Edit {...props}>
                <ServerSideValidatedForm>
                    <TabbedForm>
                        {/* Allgemeine Einstellungen */}
                        <FormTab label={`resources.${Resources.Coupon}.tabs.general`}>
                            <EnumInput
                                name="CouponType"
                                source="type"
                                validate={required()}
                            />

                            <TextInput
                                source="code"
                                validate={required()}
                            />
                            <TextInput
                                source="name"
                                validate={required()}
                            />
                            <NumberInput
                                source="amount"
                                min={0}
                                validate={required()}
                            />
                            <EnumInput
                                name="CouponAmountType"
                                source="amountType"
                                validate={required()}
                            />
                            <NumberInput
                                source="maxRedemptions"
                                min={0}
                                validate={required()}
                                helperText={`resources.${Resources.Coupon}.helperText.maxRedemptions`}
                            />
                            <NumberInput
                                source="maxRedemptionsPerUser"
                                min={0}
                                validate={required()}
                                helperText={`resources.${Resources.Coupon}.helperText.maxRedemptionsPerUser`}
                            />
                            <DateInput
                                source="startDate"
                                validate={required()}
                            />
                            <DateInput
                                source="endDate"
                                validate={required()}
                            />
                            <TextInput
                                source="description"
                                multiline
                                fullWidth={true}
                            />

                            <PartnerSelectInput validate={required()} fullWidth/>

                            <BooleanInput
                                source="isCombinable"
                                validate={required()}
                                defaultValue={false}
                            />
                        </FormTab>

                        {/* Constraints / Einschränkungen */}
                        <FormTab label={`resources.${Resources.Coupon}.tabs.constraints`}>
                            <ExpressionInput
                                fullWidth
                                source="constraints"
                                type={ExpressionNodeType.ShoppingCart}
                                validate={[required(), minLength(1)]}
                            />

                            <ExpressionInput
                                className={classes.constraintInput}
                                label={`resources.${Resources.Coupon}.fields.productConstraints`}
                                fullWidth
                                source="productConstraints"
                                type={ExpressionNodeType.Product}
                                validate={[required(), minLength(1)]}
                            />
                        </FormTab>
                    </TabbedForm>
                </ServerSideValidatedForm>
            </Edit>
        </Localized>
    );
};