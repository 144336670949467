import React, {FC} from "react";
import {BooleanField, Datagrid, List, ListProps, TextField} from "react-admin";
import {DefaultFilter} from "../DefaultFilter";
import {PartnerIconField} from "./PartnerIconField";
import {Resources} from "../../Resources";
import {EnumField} from "../Field/EnumField";

export const PartnerList: FC<ListProps> = (props) => (
    <List
        {...props}
        filters={<DefaultFilter/>}>
        <Datagrid rowClick="edit" optimized>
            <PartnerIconField displayName={true}
                              label={`resources.${Resources.Partner}.fields.name`}/>
            <TextField source="host"/>
            <TextField source="identifier"/>
            <BooleanField source="isSapPartner"/>
            <EnumField name="CheckoutModuleType" source="checkoutModule" addLabel={false}/>
            <EnumField name="CartStorageMode" source="cartStorageMode" addLabel={false}/>
        </Datagrid>
    </List>
);