import React, {useMemo} from "react";
import {Partner} from "../../GraphQL/Generated";
import {sprintf} from "sprintf-js";

type Props = {
    partner: Partner
}

export const PartnerIcon = ({partner: {identifier, name}}: Props): React.ReactElement | null => {
    const icon = useMemo(() => {
        return sprintf("icons/partner/%s.png", identifier);
    }, [identifier]);

    return identifier ? <img src={icon} title={name} alt={name}/> : null;
};