import {ArrayInput, LoadingIndicator, SimpleFormIterator} from "ra-ui-materialui";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {
    FormDataConsumer,
    InputProps,
    required,
    SelectInput,
    useQueryWithStore,
    useTranslate,
    ValidationErrorMessage,
} from "react-admin";
import {ErrorDisplay} from "../ErrorDisplay";
import {Resources} from "../../Resources";
import {
    CouponConstraint,
    ExpressionNode,
    ExpressionNodeType,
    ExpressionOperator,
    ExpressionValueType,
    LogicOperator,
    ShippingProductConstraint,
} from "../../GraphQL/Generated";
import {ArrayInputProps} from "ra-ui-materialui/lib/input/ArrayInput";
import omit from "lodash/omit";
import find from "lodash/find";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {assertDefined} from "../../Assert";
import {sprintf} from "sprintf-js";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import {OperatorValueInput} from "./OperatorValueInput";

interface ExpressionSelectInputProps extends InputProps {
    type: ExpressionNodeType
}

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}));

const validate = (value?: (CouponConstraint | ShippingProductConstraint)[]): ValidationErrorMessage | null => {
    if (value === undefined) {
        return null;
    }

    const uniqueConstraints = uniqBy(
        value,
        (c?: CouponConstraint | ShippingProductConstraint) => {
            if (c === undefined) {
                return c;
            }

            return sprintf("%s_%s_%s", c.expression, c.operator, c.value);
        }
    );

    if (uniqueConstraints.length !== value.length) {
        return "validation.coupon.duplicateConstraint";
    }

    return null;
};

export const ExpressionInput = (props: ExpressionSelectInputProps): ReactElement => {
    const translate = useTranslate();
    const classes = useStyles();
    const {type} = props;

    const {loaded, error, data} = useQueryWithStore({
        type: "getList",
        resource: Resources.ExpressionNode,
        payload: {
            pagination: {page: 0, perPage: Number.MAX_VALUE},
            sort: {field: "name", order: "ASC"},
            filter: {type},
        },
    });

    const expressionChoices = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map(({id, description}: ExpressionNode) => ({
            id,
            name: description,
        }));
    }, [data]);

    const logicOperatorChoices = useMemo(() => {
        return map(LogicOperator, (val) => ({
            id: val,
            name: translate(sprintf("enum.LogicOperator.%s", val)),
        }));
    }, []);

    if (!loaded || data === undefined) {
        return <LoadingIndicator/>;
    }

    if (error !== null) {
        return <ErrorDisplay message={error.message} error={error}/>;
    }

    return (
        <ArrayInput
            {...(omit(props, "children") as ArrayInputProps)}
            validate={validate}
        >
            <SimpleFormIterator>
                <FormDataConsumer>
                    {({getSource, scopedFormData}) => {
                        assertDefined(getSource);

                        const [selectedExpressionId, setSelectedExpressionId] = useState<string | null>(
                            scopedFormData?.expression || null
                        );

                        const selectedExpression = useMemo<ExpressionNode | null>(() => {
                            if (selectedExpressionId === null) {
                                return null;
                            }

                            return find(data, {id: selectedExpressionId}) || null;
                        }, [selectedExpressionId]);

                        // operatorChoices holds the supported operators of the selected expression
                        const operatorChoices = useMemo(() => {
                            if (!selectedExpression) {
                                return [];
                            }

                            return selectedExpression.operators.map((name: string) => ({
                                id: name,
                                name: translate(sprintf("enum.ExpressionOperator.%s", name)),
                            }));
                        }, [selectedExpression]);

                        useEffect(() => {
                            if (selectedExpression?.valueType === ExpressionValueType.Boolean) {
                                scopedFormData["operator"] = ExpressionOperator.Equal;
                            }
                        }, [selectedExpression]);

                        return (
                            <div className={classes.root}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={2}>
                                        <SelectInput
                                            label={`resources.${Resources.ExpressionNode}.fields.logicalOperator`}
                                            choices={logicOperatorChoices}
                                            source={getSource("logicOperator")}
                                            record={scopedFormData}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={3}>
                                        <SelectInput
                                            label={`resources.${Resources.ExpressionNode}.fields.constraint`}
                                            choices={expressionChoices}
                                            onChange={(e) => setSelectedExpressionId(e.target.value)}
                                            source={getSource("expression")}
                                            record={scopedFormData}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={2}>
                                        <SelectInput
                                            label={`resources.${Resources.ExpressionNode}.fields.operator`}
                                            choices={operatorChoices}
                                            source={getSource("operator")}
                                            record={scopedFormData}
                                            disabled={operatorChoices.length === 0
                                                || selectedExpression?.valueType === ExpressionValueType.Boolean}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={5}>
                                        {selectedExpression?.valueType &&
                                            <OperatorValueInput
                                                label={`resources.${Resources.ExpressionNode}.fields.value`}
                                                source={getSource("value")}
                                                record={scopedFormData}
                                                expressionValueType={selectedExpression.valueType}
                                                fullWidth
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};