import {LogicError} from "./LogicError";
import {sprintf} from "sprintf-js";

/**
 * Represents an error that occurs when a function is provided with invalid params.
 */
export class InvalidParamError extends LogicError {
    /**
     * Creates an error for null params.
     *
     * @param name The name of the param
     * @returns A new InvalidParamError
     */
    static nullParam(name: string): InvalidParamError {
        return new InvalidParamError(sprintf("%s is null", name));
    }

    /**
     * Creates an error for undefined params.
     *
     * @param name The name of the param
     * @returns A new InvalidParamError
     */
    static undefinedParam(name: string): InvalidParamError {
        return new InvalidParamError(sprintf("%s is undefined", name));
    }

    /**
     * Creates an error for empty arrays.
     *
     * @param name The name of the param
     * @returns A new InvalidParamError
     */
    static emptyArray(name: string): InvalidParamError {
        return new InvalidParamError(sprintf("%s is empty", name));
    }
}