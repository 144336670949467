import {useNotify} from "react-admin";
import {Resources} from "../../Resources";
import {ApolloError, ServerError} from "@apollo/client";
import {GraphQLError} from "graphql";
import {useCallback} from "react";

export type ErrorHandler = (error: unknown) => void;

export const useErrorHandler = (): ErrorHandler => {
    const notify = useNotify();

    return useCallback((error: unknown) => {
        if (!(error instanceof ApolloError)) {
            throw error;
        }

        const networkError = error.networkError as ServerError;
        const errors = networkError.result.errors;

        if (Array.isArray(errors) && errors.length > 0) {
            const firstError: GraphQLError = errors[0];
            if (firstError.extensions && firstError.extensions.category === "fs") {
                notify(`resources.${Resources.File}.errors.${firstError.extensions.code}`, "error");
                return;
            }
        }

        notify(`resources.${Resources.File}.errors.UNKNOWN`, "error");
    }, [notify]);
};