import {Resources} from "../Resources";
import {ExpressionValueType} from "../GraphQL/Generated";

export default {
    // react-admin translations
    ra: {
        action: {
            unselect: "Abwählen",
        },
        page: {
            loading: "Die Seite wird geladen",
            empty: "Kein Inhalt",
            invite: "Möchten Sie einen Inhalt hinzufügen?",
        },
        notification: {
            logged_out: "Ihre Session ist abgelaufen. Bitte melden Sie sich erneut an.",
        },
        navigation: {
            skip_nav: "Zum Inhalt springen",
        },
        auth: {
            auth_check_error: "Bitte melden Sie sich an um fortzufahren.",
        },
        message: {
            details: "Details",
        },
    },

    // Generic Translations
    general: {
        search: "Suche",
    },

    actions: {
        cancel: "Abbrechen",
        select: "Auswählen",
    },

    breadcrumb: {
        start: "Zurück zum Dashboard",
    },

    // Dashboard
    dashboard: {
        welcome: "Willkommen, %{name}!",
        orders: {
            title: "Die letzten %{count} Bestellungen",
            showAll: "Alle anzeigen",
        },
    },

    // Paymentmethods
    payment: {
        paypal: "Paypal",
        bill: "Rechnung",
        creditCard: "Kreditkarte",
        debits: "Lastschrift",
        banktransfer: "Banktransfer",
        moneyOrder: "Money Order",
        inAdvance: "Vorkasse",
        unknown: "Unbekannt",
    },


    resources: {
        [Resources.Address]: {
            fields: {
                name: "Name",
                faxNumber: "Faxnummer",
                telephoneNumber: "Telefonnummer",
                default: "Standard",
            },
        },

        [Resources.Customer]: {
            name: "Benutzer",
            tabs: {
                general: "Allgemein",
                attributes: "Attribute",
                addresses: "Adresse |||| Adressen (%{smart_count})",
                orders: "Bestellungen (%{smart_count})",
                roles: "Rollen",
            },
            fields: {
                customerNumber: "Kundennummer",
                lastLogin: "Letzte Anmeldung",
                name: "Name",
                emailAddress: "E-Mail",
                roles: "Rollen",
                active: "Aktiv",
                numberOfLogins: "Anzahl Anmeldungen",
                group: "Gruppe",
                vatId: "Steuernummer",
                lastModificationDate: "Zuletzt bearbeitet",
                creationDate: "Erstellt am",
                address: "Adresse",
                condition: "Rabattklasse",
            },
        },

        [Resources.Order]: {
            cancel: "Bestellung Stornieren",
            cancelHint: "Geben Sie einen Hinweis zur Stornierung ein.",
            close: "Schließen",
            name: "Bestellung |||| Bestellungen",
            tabs: {
                general: "Allgemein",
                history: "Verlauf",
                xml: "Order Xml",
            },
            download: "Herunterladen",
            mail: {
                admin: "E-Mail an Kundenberater senden",
                customer: "E-Mail an Kunde senden",
            },
            fields: {
                "id": "Id",
                "orderNumber": "Bestellnummer",
                "totalValue": "Bestellwert",
                "shippingMethod": "Versandmethode",
                "lastModificationDate": "Zuletzt bearbeitet",
                "creationDate": "Erstellt am",
                "paymentMethod": "Bezahlmethode",
                "orderDate": "Datum",
                "status": "Status",
                "history": "Verlauf",
                "name": "Name",
                "comment": "Notiz",
                "billingAddress": "Rechnungsadresse",
                "deliveryAddress": "Lieferadresse",
                "customerName": "Kunde",
                "quantity": "Menge",
                "price": "Einzelpreis",
                "totalPrice": "Gesamtpreis",
                "products": "Produkte",
                "customer": "Kunde",
                "orderTotal": "Zusammenfassung",
                "partner": "Partner",
                "partner.id": "Partner",
                "dateTime": "Datum",
                "type": "Typ",
                "customData": "Wunschtext",
                "orderXml": "Order XML",
                "catalogOrdered": "Katalogbestellung",
                "orderTotals": {
                    title: "Name",
                    value: "Wert",
                },
                "sendMail": "E-Mail senden",
            },
        },

        [Resources.Product]: {
            name: "Produkt |||| Produkte",
            tabs: {
                general: "Allgemein",
                description: "Beschreibung",
                attributes: "Attribute",
                partners: "Partner",
                categories: "Kategorien",
            },
            filter: {
                includeInactive: "Gelöschte anzeigen",
            },
            fields: {
                "image": "Bild",
                "model": "Artikelnummer",
                "name": "Bezeichnung",
                "longText": "Beschreibung",
                "group": "Gruppe",
                "active": "Aktiv",
                "lastModificationDate": "Zuletzt bearbeitet",
                "creationDate": "Erstellt am",
                "parent.id": "Vaterartikel",
                "weight": "Gewicht",
                "rebateClass": "Rabattklasse",
                "replenishmentTime": "Wiederbeschaffungszeit",
                "stock": "Auf Lager",
                "type": "Produkttyp",
                "catalogPage": "Katalogseite",
                "isSale": "Abverkauf",
            },
        },

        [Resources.Partner]: {
            name: "Partner",
            tabs: {
                general: "Allgemein",
                seo: "SEO",
                contact: "Kontaktdaten",
                ociMapping: "OCI-Mapping",
                hosts: "Hostnamen",
            },
            fields: {
                name: "Name",
                host: "Partner Host",
                isSapPartner: "SAP Partner",
                identifier: "Identifier",
                mediaHost: "Media Daten Host",
                staticHost: "Static Host",
                imageHost: "Bilder Host",
                checkoutModule: "Checkout Modul",
                cartStorageMode: "Warenkorb Speicher",
                trustedShopsId: "Trusted Shop ID",
                googleTagManagerId: "Google Tag Manager ID",
                usercentricsId: "Usercentrics ID",
                title: "Titel",
                description: "Beschreibung",
                descriptionExpression: "Beschreibung (SEO Name Generator)",
                titleExpression: "Titel (SEO Name Generator)",
                contactName: "Name",
                contactMail: "E-Mail",
                contactPhone: "Telefon",
            },
        },

        [Resources.OciMappingField]: {
            fields: {
                id: "Feldname",
                valueType: "Wertetyp",
                value: "Wert",
            },
        },

        [Resources.FormCustomizer]: {
            name: "Konfigurator |||| Konfiguratoren",
            tabs: {
                general: "Allgemein",
                fields: "Felder",
            },
            fields: {
                name: "Name",
                fieldName: "Feldname",
                notes: "Anmerkungen",
                description: "Beschreibung",
                isRequired: "Benötigt",
                type: "Feldtyp",
                maxLength: "Maximale Länge",
                option: "Wert",
                options: "Auswahl Werte",
                priceFormula: "Preisberechnungsformel",
                productModel: "Artikelnummer",
                products: "Produkte",
                productsIds: "Produkte",
                regex: "Regulärer Ausdruck",
                title: "Titel",
            },
            helperText: {
                options: "Kommaseparierte Liste mit Werten, welche vom Kunden gewählt werden können.",
                priceFormula: "Verfügbare Variablen\n" +
                    "p = Basispreis bestehend aus Produkten und ggf. weiteren Berechnungen\n" +
                    "l = Länge des Textes",
                regex: "Regulärer Ausdruck für die Validierung der Eingabe.",
            },
        },

        [Resources.Category]: {
            name: "Kategorie |||| Kategorien",
            tabs: {
                general: "Allgemein",
                images: "Bilder",
                pinnedProductGroups: "Angeheftete Produktgruppen",
                pinnedProducts: "Angeheftete Produkte",
                relatedCategories: "Verwandte Kategorien",
            },
            fields: {
                containsIndividualProducts: "Enthält individuelle Produkte",
                containsIndividualProductsHelp: "Wenn eine Kategorie individuelle Produkte enthält, " +
                    "wird dem Kunden eine \"Brewes Individuell\" Kachel in der Produktliste angezeigt.",
                name: "Name",
                parent: "Eltern-Kategorie",
                pinnedProductGroups: "Angeheftete Produktgruppen",
                pinnedProducts: "Angeheftete Produkte",
                productModel: "Artikelnummer",
                relatedCategories: "Verwandte Kategorien",
                relatedCategory: "Kategorie",
                slug: "Slug",
                url: "URL",
            },
        },

        [Resources.FaqQuestion]: {
            name: "Frage & Antwort |||| Fragen & Antworten",
            searchPlaceholder: "Kunde / Artikelnummer",
            tabs: {
                general: "Allgemein",
                tabPinnedProducts: "Angeheftete Produkte",
            },
            fields: {
                "answer": "Antwort",
                "author": "Author",
                "creationDate": "Erstellt am",
                "customer": "Kunde",
                "customer.id": "Kunde",
                "lastModificationDate": "Zuletzt bearbeitet",
                "moderator": "Moderator",
                "moderator.id": "Moderator",
                "product.id": "Produkt",
                "shareWithSiblings": "In Produktgruppe teilen",
                "status": "Status",
                "text": "Frage",
            },
        },

        [Resources.ExpressionNode]: {
            name: "Bedingung |||| Bedingungen",
            fields: {
                constraint: "Bedingung",
                operator: "Operator",
                logicalOperator: "Logischer Operator",
                value: "Wert",
            },
            valueHelperText: {
                [ExpressionValueType.Array]: "Kommaseparierte Liste. Bsp.: a,b,c",
            },
        },

        [Resources.Coupon]: {
            name: "Gutschein |||| Gutscheine",
            tabs: {
                general: "Allgemein",
                constraints: "Bedingungen",
            },
            fields: {
                active: "Aktiv",
                startDate: "Start",
                endDate: "Ende",
                code: "Code",
                amountType: "Wert in",
                constraints: "Gutschein-Bedingungen",
                productConstraints: "Produkt-Bedingungen",
                type: "Typ",
                description: "Beschreibung",
                amount: "Wert",
                isCombinable: "Mit anderen Gutscheinen Kombinierbar",
                maxRedemptions: "Max. Einlösungen",
                maxRedemptionsPerUser: "Max. Einlösungen pro Benutzer",
            },
            helperText: {
                maxRedemptions: "Gibt an wie oft der Gutschein insgesamt über alle Benutzer hinweg eingelöst werden" +
                    " kann.",
                maxRedemptionsPerUser: "Gibt an wie oft ein Benutzer einen Gutschein einlösen kann.",
            },
        },

        [Resources.Shipping]: {
            name: "Versandmethode |||| Versandmethoden",
            tabs: {
                general: "Allgemein",
                constraints: "Bedingungen",
            },
            fields: {
                "combinable": "Kombinierbar mit anderen Versandmethoden",
                "selectable": "Von Kunden auswählbar",
                "priority": "Priorität",
                "autoAppend": "Automatisch Hinzufügen",
                "constraints": "Bedingungen",
                "shippingModule.id": "Versandmodul",
                "comment": "Bemerkungen",
                "model": "Artikelnummer",
            },
        },

        [Resources.Language]: {
            name: "Sprache |||| Sprachen",
        },

        [Resources.SearchOptimization]: {
            name: "Suchoptimierung |||| Suchoptimierungen",
            fields: {
                items: "Optimierungen",
                comment: "Bemerkungen",
                query: "Suchterm",
                type: "Art der Optimierung",
                term: "Suchterm",
            },
            tabs: {
                query: "Suchbegriffe",
                optimization: "Optimierungen",
            },
        },
        [Resources.SearchOptimizationReview]: {
            name: "Suchvorschlag |||| Suchvorschläge",
            fields: {
                solved: "Gelöst",
                searchQuery: "Suchbegriff",
                query: "Suchterm",
                message: "Mitteilung",
                answer: "Anwort",
            },
        },

        [Resources.Setting]: {
            name: "Einstellung |||| Einstellungen",
            fields: {},
        },
        [Resources.Redirect]: {
            name: "Weiterleitung |||| Weiterleitungen",
            fields: {
                path: "Pfad",
                target: "Ziel",
                statusCode: "Status Code",
            },
            helperText: {
                path: "Pfad ohne Host (www.brewes.de) welcher aufgerufen wird. Der Pfad muss mit '/' beginnen.",
                target: "Zielpfad ohne Host (www.brewes.de) auf welchen weiter geleitet werden soll.",
            },
        },
        [Resources.CronJob]: {
            name: "Aufgabe |||| Aufgaben",
            fields: {
                isActive: "Aktiv",
                name: "Name",
                timeCode: "Zeitcode",
                isPhp: "PHP Script",
                command: "Befehl",
                comment: "Bemerkungen",
            },
            helperText: {
                timeCode: "Zeitcode welcher definiert, wann der Job ausgeführt werden soll. https://crontab.guru/ .",
                isPhp: "Es handelt sich um ein PHP - Script, welches über einen PHP Interpreter ausgeführt wird.",
                command: "Befehl welcher ausgeführt werden soll.",
                output: "Datei in welche die Ausgabe des Befehls geschrieben werden soll.",
            },
        },
        [Resources.File]: {
            actions: {
                delete: "Datei löschen |||| %{smart_count} Dateien löschen",
                download: "Herunterladen",
                edit: "Metadaten bearbeiten",
                select: "Datei auswählen",
                upload: "Datei hochladen |||| %{smart_count} Dateien hochladen",
            },
            aside: {
                placeholder: "Keine Datei ausgewählt",
            },
            details: {
                title: "Datei",
            },
            directory: {
                name: "Verzeichnis |||| Verzeichnisse",
                actions: {
                    delete: "Verzeichnis löschen |||| %{smart_count} Verzeichnisse löschen",
                    create: "Verzeichnis erstellen",
                },
                createDialog: {
                    error: "Der Name darf nicht mehr als 255 Zeichen enthalten oder ungültige Zeichen enthalten.",
                    name: "Name des Verzeichnisses",
                    subTitle: "Geben Sie hier den Namen des neuen Verzeichnisses ein. " +
                        "Das Verzeichnis wird unter \"%{path}\" erstellt.",
                    title: "Verzeichnis erstellen",
                },
            },
            errors: {
                EEXIST: "Die Datei existiert bereits.",
                ENOENT: "Die Datei existiert nicht.",
                EPERM: "Sie haben nicht die Berechtigungen um auf die Datei zuzugreifen.",
                TOO_MANY_FILES: "Sie können maximal %{count} Dateien hochladen.",
                UNKNOWN: "Ein unbekannter Fehler ist aufgetreten.",
            },
            fields: {
                created: "Erstellt",
                imageMetadata: {
                    alt: "Alternativtext",
                    title: "Titel",
                },
                modified: "Zuletzt modifiziert",
                name: "Datei",
                path: "Pfad",
                permissionMetadata: {
                    readRoles: "Rollen mit Lesezugriff",
                    writeRoles: "Rollen mit Schreibzugriff",
                },
                size: "Größe",
                url: "URL",
            },

            name: "Datei |||| Dateien",

            tabs: {
                general: "Allgemein",
                permissions: "Berechtigungen",
            },

            upload: {
                dragEnabled: "Klicken Sie hier oder ziehen Sie eine Datei in dieses Feld um Dateien auszuwählen.",
                dragDisabled: "Klicken Sie hier um Dateien auszuwählen.",
                maxFiles: "Maximal %{count} Dateien",
            },
        },
    },

    // Clipboard
    clipboard: {
        copySuccess: "Der Wert wurde in die Zwischenablage kopiert.",
        copyTitle: "Wert in die Zwischenablage kopieren",
    },

    // Validation
    validation: {
        coupon: {
            duplicateConstraint: "Eine der Bedingungen existiert bereits.",
        },
    },

    // Enum Translations
    enum: {
        CustomerCondition: {
            CONDITION_0: "Rabattklasse 0",
            CONDITION_1: "Rabattklasse 1",
            CONDITION_2: "Rabattklasse 2",
            CONDITION_3: "Rabattklasse 3",
            CONDITION_4: "Rabattklasse 4",
            CONDITION_5: "Rabattklasse 5",
            CONDITION_6: "Rabattklasse 6",
        },
        FaqModerationStatus: {
            SUBMITTED: "Eingegangen",
            DECLINED: "Abgelehnt",
            APPROVED: "Freigegeben",
        },
        CouponType: {
            FIXED: "Warenkorb",
            PRODUCT: "Produkt",
        },
        CouponAmountType: {
            ABSOLUT: "EUR",
            RELATIV: "%",
        },
        LogicOperator: {
            LOGIC_AND: "Und",
            LOGIC_OR: "Oder",
        },
        ExpressionOperator: {
            EQUAL: "Gleich",
            NOT_EQUAL: "Ungleich",
            GRATER_THEN: "Größer als",
            LESS_THEN: "Kleiner als",
            GRATER_OR_EQUAL_THEN: "Größer oder Gleich",
            LESS_OR_EQUAL_THEN: "Kleiner oder Gleich",
            BETWEEN: "Zwischen",
            IN: "In",
            NOT_IN: "Nicht in",
            MATCH: "Stimmt überein",
        },
        ShippingAppendMode: {
            NONE: "Niemals",
            IF_EMPTY: "Wenn kein Versand gewählt und Bedingungen erfüllt",
            ALWAYS: "Immer wenn Bedingungen erfüllt",
        },
        SearchOptimizationItemType: {
            BOOST_UP: "Aufwerten",
            BOOST_DOWN: "Abwerten",
            ADD: "Hinzufügen",
            REMOVE: "Entfernen",
        },
        RedirectStatusCode: {
            MOVED_PERMANENTLY: "[301] dauerhaft verschoben",
            FOUND_MOVED_TEMPORARILY: "[302] vorübergehend verschoben",
            SEE_OTHER: "[303] Andere",
            TEMPORARY_REDIRECT: "[307] vorübergehende weiterleitung",
            PERMANENT_REDIRECT: "[308] dauerhafte weiterleitung",
            NOT_FOUND: "[404] nicht gefunden",
            GONE: "[410] gelöscht",
        },
        CheckoutModuleType: {
            OCI: "OCI - Punchout",
            CXML: "cXml - Punchout",
            SHOP: "Shop",
        },
        CartStorageMode: {
            SESSION: "Session",
            CUSTOMER: "Kunde",
        },
        Role: {
            USER: "Benutzer",
            ADMIN: "Administrator",
            MAINTAINER: "Super-Administrator",
            PM: "Produktmanagement",
            MARKETING: "Marketing",
            PURCHASING: "Einkauf",
            SALES: "Verkauf",
            HUMAN_RESOURCES: "Personalbereich",
            API: "API-Zugriff",
        },
        CustomerGroup: {
            GUEST: "Gast",
            USER: "Kunde",
            ADMIN: "Administrator",
        },
        OrderProductType: {
            PRODUCT: "Product",
            COUPON: "Gutschein",
            SHIPPING: "Versand",
            FLATRATE: "Pauschale",
        },
        FormCustomizerFieldType: {
            INPUT: "Eingabe",
            TEXTAREA: "Mehrzeilige Eingabe",
            CHOICE: "Auswahl",
            FILE: "Upload",
        },
    },

    attributeList: {
        th: {
            key: "Attribut",
            value: "Wert",
        },
    },
};