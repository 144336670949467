import polyglotI18nProvider from "ra-i18n-polyglot";
import merge from "lodash/merge";
import messages from "./Messages";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const translations = require("ra-language-german");

const i18nProvider = polyglotI18nProvider(
    () => merge({}, translations, messages),
    "de"
);

export default i18nProvider;