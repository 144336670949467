/**
 * TokenResponse.ts represents a typed result.
 * {@see https://ctidd.com/2018/typescript-result-type}
 */
export type Result<T, T2 = Error> = T | T2

/**
 * Checks if a result is ok (e.g. result is not an instanceof Error).
 *
 * @param r The result to check
 * @returns Returns true if the result is an error.
 */
export const ok = <T>(r: Result<T>): r is T => !(r instanceof Error);

/**
 * Checks whether the result is a specific error.
 *
 * @param r     The result object
 * @param err   The error class
 * @returns Returns true when the result is an instance of err
 */
export const is = <T>(r: Result<T>, err: new(message: string) => Error): boolean => r instanceof err;