import {FC} from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import {Link, linkToRecord, ListButton, Record, useGetList, useTranslate} from "react-admin";
import {Resources} from "../../Resources";
import {useLocaleContext} from "../../I18n/LocaleContext";

export const Orders: FC = () => {
    const orderCount = 5;
    const translate = useTranslate();
    const {data} = useGetList<Record>(
        Resources.Order,
        {page: 1, perPage: orderCount},
        {order: "DESC", field: "creationDate"}
    );
    const {currencyFormat, dateFormatter} = useLocaleContext();

    return (
        <TableContainer component={Paper}>
            <Box p={2}>
                <Typography variant="h6" color="textSecondary">
                    {translate("dashboard.orders.title", {count: orderCount})}
                </Typography>
            </Box>

            <Table aria-label={translate("dashboard.orders.title", {count: orderCount})} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {translate(`resources.${Resources.Order}.fields.orderNumber`)}
                        </TableCell>
                        <TableCell align="right">
                            {translate(`resources.${Resources.Order}.fields.totalValue`)}
                        </TableCell>
                        <TableCell align="right">
                            {translate(`resources.${Resources.Order}.fields.creationDate`)}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(data).reverse().map((order) => (
                        <TableRow key={order.id}>
                            <TableCell component="th" scope="row">
                                <Link to={linkToRecord(Resources.Order, order.id, "show")}>
                                    {order.orderNumber}
                                </Link>
                            </TableCell>
                            <TableCell align="right">
                                {currencyFormat.format(order.totalValue)}
                            </TableCell>
                            <TableCell align="right">
                                {dateFormatter.format(new Date(order.creationDate))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box p={1}>
                <ListButton basePath={`/${Resources.Order}`} label={"dashboard.orders.showAll"}/>
            </Box>
        </TableContainer>
    );
};