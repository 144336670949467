/**
 * The deepFreeze function makes an object immutable by calling Object.freeze for the object itself
 * and for all its properties recursively. Scalar values are also configured to be immutable.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 * @param obj The object to freeze
 * @returns The frozen object
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function deepFreeze<T extends object>(obj: T): DeepReadonly<T> {
    const propNames = Object.getOwnPropertyNames(obj);

    for (const name of propNames) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = obj[name];

        if (value) {
            if (typeof value === "object") {
                deepFreeze(value);
            } else {
                Object.defineProperty(obj, name, {
                    writable: false,
                    configurable: false,
                    value,
                });
            }
        }
    }

    return Object.freeze(obj) as DeepReadonly<T>;
}