import {AutocompleteInput, Edit, EditProps, ReferenceInput, TextInput} from "ra-ui-materialui";
import {ArrayInput, FormTab, SimpleFormIterator, TabbedForm} from "react-admin";
import React, {ReactElement, useCallback} from "react";
import {Resources} from "../../Resources";
import omit from "lodash/omit";
import {FormCustomizerFieldInputField} from "../FormCustomizerField/FormCustomizerFieldInputField";
import {FormCustomizerFieldInput} from "../../GraphQL/Generated";
import {Localized} from "../Language/Localized";

export const FormCustomizerEdit = (props: EditProps): ReactElement => {
    const transform = useCallback(({fields, ...rest}) => {
        fields = fields.map((item: FormCustomizerFieldInput) => omit(item, "optionsIds"));
        return {
            ...rest,
            fields: fields,
        };
    }, []);
    return (
        <Localized {...props}>
            <Edit {...props} transform={transform}>
                <TabbedForm>
                    <FormTab label={`resources.${Resources.FormCustomizer}.tabs.general`}>
                        <TextInput source="name"/>
                        <TextInput source="notes" rows={3} fullWidth multiline={true}/>
                        <ArrayInput source="products">
                            <SimpleFormIterator>
                                <ReferenceInput
                                    source="id"
                                    reference={Resources.Product}
                                    label={`resources.${Resources.FormCustomizer}.fields.productModel`}
                                >
                                    <AutocompleteInput source="model" optionText="model"/>
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                    <FormTab label={`resources.${Resources.FormCustomizer}.tabs.fields`}>
                        <FormCustomizerFieldInputField source="fields" fullWidth/>
                    </FormTab>
                </TabbedForm>
            </Edit>
        </Localized>
    );
};
