import React from "react";
import {
    BooleanField,
    Datagrid,
    DateField, FunctionField,
    ReferenceArrayField,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    useShowController,
    useTranslate,
} from "react-admin";
import CustomShow from "../Show/CustomShow";
import {Resources} from "../../Resources";
import {AttributeList} from "../Attribute/AttributeList";
import {AddressField} from "../Field/AddressField";
import {PhoneNumberField} from "../Field/PhoneNumberField";
import {MoneyField} from "../Field/MoneyField";
import {EnumField} from "../Field/EnumField";
import {Box} from "@material-ui/core";

export const CustomerShow = (props: ShowProps): React.ReactElement => {
        const translate = useTranslate();
        const {record} = useShowController(props);

        return (
            <CustomShow {...props}>
                <TabbedShowLayout>
                    <Tab label={translate(`resources.${Resources.Customer}.tabs.general`)}>
                        {record?.customerNumber ? <TextField source="customerNumber"/> : null}
                        <TextField source="name"/>
                        <TextField source="emailAddress"/>
                        <EnumField name="Role" source="roles"/>
                        <EnumField name="CustomerCondition" source="condition"/>
                    </Tab>
                    <Tab label={translate(`resources.${Resources.Customer}.tabs.attributes`)}>
                        <AttributeList attributes={[
                            {name: "group", render: (group, value) => translate(`enum.CustomerGroup.${value}`)},
                            "vatId",
                            {name: "lastLogin", type: "date"},
                            {name: "creationDate", type: "date"},
                            {name: "lastModificationDate", type: "date"},
                            "numberOfLogins",
                        ]}/>
                    </Tab>
                    <Tab label={translate(
                        `resources.${Resources.Customer}.tabs.addresses`,
                        {smart_count: record?.addressIds?.length}
                    )}>
                        <ReferenceArrayField
                            reference={Resources.Address}
                            source="addressIds"
                            sort={{field: "default", order: "DESC"}}
                        >
                            <Datagrid optimized>
                                <BooleanField source="default" sortable={false}/>
                                <FunctionField label={`resources.${Resources.Address}.fields.name`} render={() => (
                                    <Box display="flex" gridGap={4}>
                                        <TextField source="title"/>
                                        <TextField source="firstName"/>
                                        <TextField source="lastName"/>
                                    </Box>
                                )}/>
                                <AddressField label={translate(`resources.${Resources.Customer}.fields.address`)}/>
                                <PhoneNumberField source="telephoneNumber" sortable={false}/>
                                <PhoneNumberField source="faxNumber" sortable={false}/>
                            </Datagrid>
                        </ReferenceArrayField>
                    </Tab>
                    <Tab label={translate(
                        `resources.${Resources.Customer}.tabs.orders`,
                        {smart_count: record?.orderIds?.length}
                    )}>
                        <ReferenceArrayField
                            reference={Resources.Order}
                            source="orderIds"
                            sort={{field: "orderNumber", order: "ASC"}}
                        >
                            <Datagrid rowClick="show">
                                <TextField source="orderNumber"/>
                                <MoneyField source="totalValue"/>
                                <DateField source="creationDate"/>
                            </Datagrid>
                        </ReferenceArrayField>
                    </Tab>
                </TabbedShowLayout>
            </CustomShow>
        );
    }
;