import React, {FC, useEffect} from "react";
import {choices, composeValidators, InputProps} from "react-admin";
import {SelectableListInput} from "../Input/SelectableListInput";
import {useEnumChoices} from "../../Enum/Enum";
import {Role} from "../../GraphQL/Generated";

/**
 * Displays a list of selectable user roles.
 *
 * @param props The RoleSelectInput props.
 */
export const RoleSelectInput: FC<InputProps> = (props) => {
    const {validate, ...rest} = props;
    const values = useEnumChoices("Role");

    useEffect(() => {
        values.forEach((v) => {
            if (v.id === Role.User) {
                v.disabled = true;
            }
        });
    }, [values]);

    return (
        <SelectableListInput
            {...rest}
            validate={composeValidators(validate, choices(Object.values(Role)))}
            choices={values}
        />
    );
};