import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useShowContext, useTranslate} from "react-admin";
import {AttributeName} from "./AttributeName";
import {AttributeValue} from "./AttributeValue";
import {Attribute} from "./Attribute";
import classes from "./AttributeList.module.scss";

type AttributeListProps = React.PropsWithoutRef<{
    attributes: (string | Attribute)[]
    hideHeader?: boolean
}>;

export const AttributeList = (props: AttributeListProps): React.ReactElement | null => {
    const {record} = useShowContext();
    const translate = useTranslate();
    if (!record) {
        return null;
    }

    const getAttributeKey = (attr: Attribute | string) => {
        return typeof attr === "string" ? attr : attr.name;
    };

    return (
        <Table stickyHeader={true} size="small">
            {
                !props.hideHeader ?
                    <TableHead>
                        <TableRow>
                            <TableCell width="30%"><strong>{translate("attributeList.th.key")}</strong></TableCell>
                            <TableCell><strong>{translate("attributeList.th.value")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    :
                    null
            }
            <TableBody>
                {props.attributes.map((attribute) => (
                    <TableRow key={getAttributeKey(attribute)} className={classes.row}>
                        <TableCell><AttributeName attribute={attribute}/></TableCell>
                        <TableCell><AttributeValue attribute={attribute}/></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};