import {AppContextProps} from "../../../../Context/AppContext";
import {assertDefined} from "../../../../Assert";
import {VisitorFactory} from "./VisitorFactory";
import {ASTVisitor} from "graphql/language";
import has from "lodash/has";

export class LanguageVisitor implements VisitorFactory {
    private readonly context: AppContextProps;

    constructor(context: AppContextProps) {
        this.context = context;
    }

    canHandle(fetchType: string, resource: string): boolean {
        assertDefined(this.context.store);

        const {language: {resources}} = this.context.store.getState();
        return ["GET_ONE", "UPDATE", "CREATE"].includes(fetchType) && has(resources, resource);
    }

    create(): ASTVisitor {
        return {
            // This adds a language param to the query variables
            Field: {
                enter: (node) => {
                    if (node.alias?.value === "data") {
                        return {
                            ...node,
                            arguments: [
                                ...(node.arguments || []),
                                {
                                    kind: "Argument",
                                    name: {kind: "Name", value: "language"},
                                    value: {kind: "Variable", name: {kind: "Name", value: "language"}},
                                },
                            ],
                        };
                    }
                },
            },

            OperationDefinition: {
                enter: (node) => {
                    return {
                        ...node,
                        variableDefinitions: [
                            ...(node.variableDefinitions || []),
                            {
                                kind: "VariableDefinition",
                                type: {
                                    kind: "NonNullType",
                                    type: {
                                        kind: "Name",
                                        value: "LanguageRefInput",
                                    },
                                },
                                variable: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "language",
                                    },
                                },
                            },
                        ],
                    };
                },
            },
        };
    }

    modifyVariables(variables: Record<string, unknown>, resource: string): void {
        assertDefined(this.context.store);
        const {language: {resources}} = this.context.store.getState();
        const language = resources[resource];
        variables.language = {
            code: language,
        };
    }
}