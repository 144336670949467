import {ConfigLoaderInterface} from "./ConfigLoaderInterface";
import {Config} from "./Config";
import {sprintf} from "sprintf-js";
import {InvalidParamError} from "../Error/InvalidParamError";
import {Result} from "../Result";

type ProcessEnv = {
    [key: string]: string | undefined
}

/**
 * Loads the config from the environment
 */
export class ObjectConfigLoader implements ConfigLoaderInterface {
    private readonly env: ProcessEnv;

    constructor(env: ProcessEnv = process.env) {
        this.env = env;
    }

    async load(): Promise<Result<Config>> {
        try {
            return {
                GraphQL: {
                    URI: this.getenv("GRAPHQL_URI"),
                },
                OAuth: {
                    URI: this.getenv("OAUTH_URI"),
                    clientCredentials: {
                        clientId: this.getenv("OAUTH_CLIENT_ID"),
                        clientSecret: this.getenv("OAUTH_CLIENT_SECRET"),
                    },
                    scope: this.getenv("OAUTH_SCOPE")?.replace(/,/g, " "),
                },
            };
        } catch (e) {
            if (e instanceof Error) {
                return e;
            }
            throw e;
        }
    }

    private getenv(name: string): string {
        const fullName = sprintf("REACT_APP_%s", name);
        if (this.env[fullName] !== undefined) {
            return this.env[fullName] as string;
        }
        throw new InvalidParamError(sprintf("environment %s variable not set", fullName));
    }
}