import React from "react";
import {
    BooleanField,
    Datagrid,
    ReferenceArrayField,
    ReferenceField,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    useTranslate,
} from "react-admin";
import {Resources} from "../../Resources";
import {PartnerIconField} from "../Partner/PartnerIconField";
import {AttributeList} from "../Attribute/AttributeList";
import classes from "./ProductShow.module.scss";
import CustomShow from "../Show/CustomShow";
import {Circle} from "../Container/Circle";

export const ProductShow = (props: ShowProps): React.ReactElement | null => {
    const translate = useTranslate();
    return (
        <CustomShow {...props} headline={(record) => (
            <>
                <Circle width="2em" height="2em" style={{marginRight: ".5em"}}>
                    <img src={record?.image}/>
                </Circle>
                <span>{record?.name}</span><br/>
            </>
        )}>
            <TabbedShowLayout>
                <Tab label={translate(`resources.${Resources.Product}.tabs.general`)}>
                    <TextField source="id"/>
                    <TextField source="model"/>
                    <ReferenceField reference={Resources.ProductGroup} source="parent.id" link="show">
                        <TextField source="model"/>
                    </ReferenceField>

                    <BooleanField source="active"/>
                    <ReferenceField source="formCustomizer.id" reference="FormCustomizer" link="edit">
                        <TextField source="name"/>
                    </ReferenceField>
                </Tab>
                <Tab label={translate(`resources.${Resources.Product}.tabs.description`)}>
                    <TextField source="longText" component="pre"/>
                </Tab>
                <Tab label={translate(`resources.${Resources.Product}.tabs.attributes`)}>
                    <AttributeList attributes={[
                        {name: "weight", type: "weight"},
                        "rebateClass",
                        {name: "replenishmentTime", render: (attr, value) => value + " Tage"},
                        {name: "type", copyable: true},
                        "stock",
                        "catalogPage",
                        {name: "creationDate", type: "date"},
                        {name: "lastModificationDate", type: "date"},
                        {name: "isSale", type: "boolean"},
                    ]}/>
                </Tab>
                <Tab label={translate(`resources.${Resources.Product}.tabs.partners`)}>
                    <ReferenceArrayField reference={Resources.Partner} source="partnerIds">
                        <Datagrid>
                            <PartnerIconField cellClassName={classes.partnerCell}/>
                            <ReferenceField
                                reference={Resources.Partner}
                                source="id"
                                label={`resources.${Resources.Partner}.fields.name`}
                            >
                                <TextField source="name"/>
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceArrayField>
                </Tab>
                <Tab label={translate(`resources.${Resources.Product}.tabs.categories`)}>
                    <ReferenceArrayField reference={Resources.Category} source="categoryIds">
                        <Datagrid>
                            <ReferenceField
                                reference={Resources.Category}
                                source="id"
                                label={`resources.${Resources.Category}.fields.slug`}
                            >
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="slug"/>
                            <TextField source="url"/>
                        </Datagrid>
                    </ReferenceArrayField>
                </Tab>
            </TabbedShowLayout>
        </CustomShow>
    );
};