import {InitialState as LanguageInitialState, LanguageState, reduce as languageReducer} from "./Language/Reducers";
import {InitialState as BreadcrumbsInitialState, BreadcrumbsState, reduce as breadcrumbsReducer} from "./Breadcrumbs";
import {createSelectorHook} from "react-redux";

export interface AdminState {
    language: LanguageState
    breadcrumbs: BreadcrumbsState
}

export const CustomReducer = {
    language: languageReducer,
    breadcrumbs: breadcrumbsReducer,
};

export const AdminInitialState: AdminState = {
    language: LanguageInitialState,
    breadcrumbs: BreadcrumbsInitialState,
};

/**
 * A selector that selects the admin root state.
 */
export const selectAdminState = createSelectorHook<AdminState>();