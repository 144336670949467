import {IntrospectionObjectType} from "graphql/utilities";
import {useIntrospectionSchema} from "./useIntrospectionSchema";
import {useIntrospectionType} from "./useIntrospectionType";

/**
 * The useQueryType hook returns the query field by name.
 *
 * @param name The query field.
 */
export function useQueryType(name: string): IntrospectionObjectType | null {
    const schema = useIntrospectionSchema();
    if (!schema) {
        return null;
    }

    const type = useIntrospectionType<IntrospectionObjectType>(schema.queryType.name);
    const result = type?.fields.find((t) => t.name === name) as IntrospectionObjectType | undefined;
    return result ?? null;
}