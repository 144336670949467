import {AutocompleteInput, Create, EditProps, ReferenceInput, TextInput} from "ra-ui-materialui";
import {ArrayInput, FormTab, minLength, required, SimpleFormIterator, TabbedForm} from "react-admin";
import React, {ReactElement, useCallback} from "react";
import {Resources} from "../../Resources";
import omit from "lodash/omit";
import {FormCustomizerFieldInputField} from "../FormCustomizerField/FormCustomizerFieldInputField";
import {FormCustomizerFieldInput} from "../../GraphQL/Generated";

export const FormCustomizerCreate = (props: EditProps): ReactElement => {
    const transform = useCallback(({fields, ...rest}) => {
        fields = fields.map((item: FormCustomizerFieldInput) => omit(item, "optionsIds"));
        return {
            ...rest,
            fields: fields,
        };
    }, []);
    return (
        <Create {...props} transform={transform}>
            <TabbedForm>
                <FormTab label={`resources.${Resources.FormCustomizer}.tabs.general`}>
                    <TextInput source="name" validate={required()}/>
                    <TextInput source="notes" rows={3} fullWidth multiline={true}/>
                    <ArrayInput source="products"
                                validate={[required(), minLength(1)]}>
                        <SimpleFormIterator>
                            <ReferenceInput
                                source="id"
                                reference={Resources.Product}
                                label={`resources.${Resources.FormCustomizer}.fields.productModel`}
                            >
                                <AutocompleteInput source="model" optionText="model"/>
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={`resources.${Resources.FormCustomizer}.tabs.fields`}>
                    <FormCustomizerFieldInputField source="fields" fullWidth/>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};
