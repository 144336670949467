import React, {useContext} from "react";
import {DateTimeFormatterInterface} from "./Date/DateTimeFormatterInterface";
import {LuxonDateTimeFormatter} from "./Date/LuxonDateTimeFormatter";

export const DEFAULT_LANGUAGE = "de";
export const DEFAULT_CURRENCY = "EUR";

/**
 * The LanguageContextInterface holds information about a selected language.
 */
export interface LocaleContextInterface {
    code: string
    dateFormatter: DateTimeFormatterInterface
    currencyFormat: Intl.NumberFormat
    numberFormat: Intl.NumberFormat
}

export const LocaleContext = React.createContext<LocaleContextInterface>({
    code: DEFAULT_LANGUAGE,
    currencyFormat: new Intl.NumberFormat(DEFAULT_LANGUAGE, {
        style: "currency",
        currency: DEFAULT_CURRENCY,
    }),
    dateFormatter: new LuxonDateTimeFormatter(DEFAULT_LANGUAGE),
    numberFormat: new Intl.NumberFormat(DEFAULT_LANGUAGE, {
        style: "decimal",
    }),
});

/**
 * The useLanguageContext hook returns the selected language of the current Context.
 *
 * @returns LocaleContextInterface
 */
export const useLocaleContext: () => LocaleContextInterface = () => useContext(LocaleContext);
