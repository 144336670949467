import {FC, memo} from "react";
import {Card, CardContent, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {LoadingIndicator, useGetList, useResourceContext, useTranslate} from "react-admin";
import {useLocaleContext} from "../../I18n/LocaleContext";

const useStyles = makeStyles(() => ({
    loadingIndicator: {
        position: "absolute",
    },
}));

export const DashboardTile: FC<{ resource?: string }> = memo((props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const {numberFormat} = useLocaleContext();
    const resource = useResourceContext(props);
    const {total, loading} = useGetList(resource, {perPage: 0, page: 1});
    const label = `resources.${resource}.name`;

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" color="textSecondary">
                    {translate(label, {smart_count: total ?? 1})}
                </Typography>
                <Typography variant="h4" color="textPrimary">
                    {loading ? <LoadingIndicator className={classes.loadingIndicator}/> : null}
                    {!loading && total !== undefined ? numberFormat.format(total) : <span>&nbsp;</span>}
                </Typography>
            </CardContent>
        </Card>
    );
});