import React from "react";
import {useShowContext, useTranslate} from "react-admin";
import {Attribute} from "./Attribute";

interface AttributeNameProps {
    attribute: Attribute | string
}

export const AttributeName = ({attribute}: AttributeNameProps): React.ReactElement | null => {
    const {record, resource} = useShowContext();
    const translate = useTranslate();

    if (!record || !attribute) {
        return null;
    }

    const renderName = (attr = attribute): string => {
        if (typeof attr === "string") {
            return translate(`resources.${resource}.fields.${attr}`);
        }

        return renderName(attr.name);
    };

    return <>{renderName()}</>;
};