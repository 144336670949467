import React from "react";
import {UNIT_KG} from "../../Units";
import {useShowContext} from "react-admin";
import {useLocaleContext} from "../../I18n/LocaleContext";
import {CopyToClipboard} from "../Clipboard/CopyToClipboard";
import {Attribute} from "./Attribute";
import {Check, Close} from "@material-ui/icons";
import {Typography} from "@material-ui/core";

interface AttributeValueProps {
    attribute: Attribute | string
}

export const AttributeValue = ({attribute}: AttributeValueProps): React.ReactElement | null => {
    const {code: languageCode, dateFormatter} = useLocaleContext();
    const {record} = useShowContext();

    if (!record || !attribute) {
        return null;
    }

    const isCopyable = (attr = attribute): boolean => {
        return typeof attr === "string" ? false : attr.copyable === true;
    };

    const notAvailable = () => <Typography color="textSecondary" variant="body2">n/a</Typography>;

    const renderAttr = (attr = attribute): string | React.ReactElement => {

        if (typeof attr === "string") {
            if (!record[attr]) {
                return notAvailable();
            }

            return record[attr];
        }

        if (attr.render !== undefined) {
            return attr.render(attr, record[attr.name]);
        }

        switch (attr.type) {
            case "weight":
                return Intl.NumberFormat(languageCode).format(record[attr.name]) + UNIT_KG;

            case "currency":
                return Intl.NumberFormat(languageCode, {style: "currency"}).format(record[attr.name]);

            case "date": {
                const date = new Date(record[attr.name]);
                if (!isNaN(date.getTime())) {
                    return dateFormatter.format(date);
                }
                return notAvailable();
            }

            case "boolean":
                return !record[attr.name] ?  <Close/> : <Check/>;

            default:
            case "string":
                return renderAttr(attr.name);
        }
    };

    const content = renderAttr(attribute);
    if (isCopyable()) {
        if (typeof content === "string") {
            return <CopyToClipboard text={content}/>;
        }
    }
    return <>{content}</>;
};