import React, {FC} from "react";
import {Datagrid, DateField, List, ListProps, ReferenceField, TextField} from "react-admin";
import {MoneyField} from "../Field/MoneyField";
import {DefaultFilter} from "../DefaultFilter";
import {PaymentField} from "../Field/PaymentField";
import {PartnerIconField} from "../Partner/PartnerIconField";
import {Resources} from "../../Resources";

export const OrderList: FC<ListProps> = (props) => (
    <List
        {...props}
        filters={<DefaultFilter/>}
        sort={{field: "creationDate", order: "DESC"}}
    >
        <Datagrid rowClick="show" optimized>
            <ReferenceField reference={Resources.Partner}
                            source="partner.id"
                            link={false}
                            sortable={false}>
                <PartnerIconField displayName={true}/>
            </ReferenceField>
            <TextField source="orderNumber"/>
            <PaymentField source="paymentMethod"/>
            <DateField source="creationDate" showTime={true}/>
            <MoneyField source="totalValue" sortable={false}/>
            <TextField source="status" sortable={false}/>
        </Datagrid>
    </List>
);