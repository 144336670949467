import {Resources} from "../Resources";
import {Translate} from "ra-core";
import {useTranslate} from "react-admin";
import {useCallback} from "react";

/**
 * Returns a translation namespace for resource.
 *
 * @param resource The resource to generate the namespace of.
 */
export const useResourceTranslate = (resource: Resources): Translate => {
    const translate = useTranslate();
    return useCallback((key: string, ...rest: unknown[]) => {
        return translate(`resources.${resource}.${key}`, ...rest);
    }, [translate]);
};