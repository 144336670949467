import {DEFAULT_CURRENCY, LocaleContext} from "../../I18n/LocaleContext";
import React, {FC, useEffect, useState} from "react";
import {LanguageSelect} from "./LanguageSelect";
import {Card, CardContent} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {setResourceLanguage} from "../../Redux/Language/Actions";
import {LuxonDateTimeFormatter} from "../../I18n/Date/LuxonDateTimeFormatter";
import {CreateProps, EditProps} from "react-admin";

/**
 * Localized is a context that is translated. The user can choose a language that will be set in the context and all
 * children. Also it handles saving the selected language to the redux store where it will be used to fetch the data
 * from the dataProvider in the selected language.
 *
 * @param props The Element props
 * @class
 */
export const Localized: FC<EditProps | CreateProps> = (props) => {
    const dispatch = useDispatch();
    const [language, setLanguage] = useState<string>("de");

    useEffect(() => {
        if (props.resource && language) {
            dispatch(setResourceLanguage(props.resource, language));
            dispatch({
                type: "RA/REFRESH_VIEW",
            });
        }
    }, [props.resource, language]);

    return (
        <>
            <Card>
                <CardContent>
                    <LanguageSelect onChange={setLanguage}/>
                </CardContent>
            </Card>
            <LocaleContext.Provider value={{
                code: language,
                dateFormatter: new LuxonDateTimeFormatter(language),
                currencyFormat: new Intl.NumberFormat(language, {style: "currency", currency: DEFAULT_CURRENCY}),
                numberFormat: new Intl.NumberFormat(language, {style: "decimal"}),
            }}>
                {props.children}
            </LocaleContext.Provider>
        </>
    );
};