import {ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject} from "@apollo/client";
import {AppContextProps} from "../../Context/AppContext";
import {AppContextLink} from "./AppContextLink";
import {AuthorizationLink} from "./AuthorizationLink";
import {isDevEnvironment} from "../../Environment";
import {createUploadLink} from "apollo-upload-client";

/**
 * ApolloClientFactory creates new (optionally authenticated) instances of the ApolloClient.
 */
export class ApolloClientFactory {
    private readonly context: AppContextProps;
    private readonly isDev: boolean;

    constructor(context: AppContextProps, env?: string) {
        this.context = context;
        this.isDev = isDevEnvironment(env);
    }

    /**
     * `create` instantiates a new ApolloClient. When an authentication is provided the client will send the
     * authentication information with every request.
     *
     * When the Authentication.type property is set to Bearer, the factory will create a client that sends along
     * a Authorization header with a Bearer token.
     *
     * @param uri               The uri to authenticate against
     * @param connectToDevTools Should the client connect to the dev tools (only when env is set to "development")
     * @returns A new (optionally authenticated) ApolloClient
     */
    public async create(
        uri: string,
        connectToDevTools = false
    ): Promise<ApolloClient<NormalizedCacheObject>> {
        const cache = new InMemoryCache({});

        const links: ApolloLink[] = [
            new AppContextLink(this.context),
            new AuthorizationLink(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            createUploadLink({uri, credentials: "include"}),
        ];

        if (this.isDev) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const logger = await import("apollo-link-logger");
            links.unshift(logger.default);
        }

        return new ApolloClient({
            link: ApolloLink.from(links),
            queryDeduplication: true,
            cache: cache.restore({}),
            connectToDevTools: this.isDev && connectToDevTools,
        });
    }
}