import {Entry} from "./Entry";
import {createContext, useContext} from "react";
import {AcceptProp} from "../FilePicker";

export interface Context {
    open: (entry: Entry) => void
    delete: (entries: Entry[]) => Promise<void>
    upload: (files: File[]) => Promise<void>
    move: (from: string, to: string) => Promise<void>
    createDirectory: (path: string) => Promise<void>
    editFile: (entry: Entry) => Promise<void>
    accept?: AcceptProp
}

export const ManagerContext = createContext<Context>({
    createDirectory(): Promise<void> {
        throw new Error("not implemented");
    },
    delete(): Promise<void> {
        throw new Error("not implemented");
    },
    move(): Promise<void> {
        throw new Error("not implemented");
    },
    open(): void {
        throw new Error("not implemented");
    },
    upload(): Promise<void> {
        throw new Error("not implemented");
    },
    editFile(): Promise<void> {
        throw new Error("not implemented");
    },
});

export const useManagerContext = (): Context => useContext(ManagerContext);