import {ErrorRounded} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect} from "react";

interface ErrorProps {
    /**
     * The message to display
     */
    message: string;

    /**
     * Holds the error that occurred if any
     */
    error?: Error;

    /**
     * If true logs the error to the error console. (Default: true)
     */
    logError?: boolean;
}

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDiration: "row",
        alignItems: "center",
    },
    icon: {
        marginRight: ".25em",
    },
    message: {
        color: "red",
        fontSize: ".75em",
        whitespace: "no-wrap",
    },
});

/**
 * Displays an error to the user and provides some extra context for debugging.
 *
 * @param props The react props
 * @class
 */
export const ErrorDisplay = (props: ErrorProps): React.ReactElement => {
    const {message, error, logError = true} = props;
    const styles = useStyles();

    useEffect(() => {
        if (error && logError) {
            console.error(error);
        }
    }, [error, logError]);

    return (
        <span className={styles.container}>
            <ErrorRounded color="error" className={styles.icon}/>
            <span className={styles.message}>{message}</span>
        </span>
    );
};
