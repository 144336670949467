import {FC, memo} from "react";
import {FieldProps, useRecordContext} from "react-admin";
import get from "lodash/get";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    _true: {
        alignItems: "center",
        backgroundColor: theme.palette.success.main,
        borderRadius: "50%",
        display: "flex",
        height: "1em",
        opacity: .65,
        width: "1em",
    },
    _false: {
        alignItems: "center",
        backgroundColor: theme.palette.error.main,
        borderRadius: "50%",
        display: "flex",
        height: "1em",
        opacity: .65,
        width: "1em",
    },
}));

/**
 * Displays a colored indicator which a boolean can be recognized at a glance
 *
 * @param props The Boolean Field props.
 * @class
 */
export const BooleanField: FC<FieldProps> = memo((props) => {
    const {source} = props;
    const record = useRecordContext(props);
    const classes = useStyles();

    if (!source) {
        return null;
    }

    const value = get(record, source);
    if (!value) {
        return <span className={classes._false}/>;
    }
    return <span className={classes._true}/>;
});