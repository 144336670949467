import {LogicError} from "./LogicError";
import {sprintf} from "sprintf-js";

/**
 * InvalidTypeError represents an error that occurs when an invalid type is encountered.
 */
export class InvalidTypeError extends LogicError {
    /**
     * Creates a new InvalidTypeError.
     *
     * @param value     The actual value
     * @param expected  The expected type
     * @returns A new InvalidTypeError
     */
    public static create(value: unknown, expected: string): InvalidTypeError {
        const msg = sprintf("invalid type = %s, expected = %s", typeof value, expected);
        return new InvalidTypeError(msg);
    }
}