import * as React from "react";
import {FC, memo, useEffect, useMemo, useState} from "react";
import {DashboardMenuItem, MenuItemLink, useAuthProvider, useTranslate} from "react-admin";
import {Resources} from "../../Resources";
import {MenuItem} from "./MenuItem";
import {useSelector} from "react-redux";
import {ReduxState} from "ra-core";
import {ApiResource} from "../../GraphQL/Generated";
import {Box} from "@material-ui/core";
import {Folder} from "@material-ui/icons";
import {Authorized} from "../Authorized";

export const Menu: FC = memo(() => {
    const authProvider = useAuthProvider();
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const [accessibleResources, setAccessibleResources] = useState<{ [key: string]: null }>({});
    const translate = useTranslate();

    useEffect(() => {
        if (!authProvider.getIdentity) {
            return;
        }

        authProvider.getIdentity().then(({accessibleResources}) => {
            setAccessibleResources(accessibleResources.reduce((acc: { [key: string]: null }, resource: ApiResource) => {
                acc[resource.name] = null;
                return acc;
            }, {}));
        });
    }, [authProvider.getIdentity]);

    const resources = useMemo(() => {
        return Object.keys(Resources).filter((resource) => resource in accessibleResources);
    }, [accessibleResources]);

    return (
        <Box mt={2}>
            <DashboardMenuItem sidebarIsOpen={open}/>

            {resources.map((resource: string) => <MenuItem key={resource} resource={resource}/>)}

            <Authorized resource={Resources.File}>
                <MenuItemLink
                    to={`/${Resources.File}`}
                    primaryText={translate(`resources.${Resources.File}.name`, {smart_count: 2})}
                    sidebarIsOpen={open}
                    leftIcon={<Folder/>}
                />
            </Authorized>
        </Box>
    );
});