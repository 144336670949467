import * as React from "react";
import {FC} from "react";
import {ChoicesInputProps, ChoicesProps, useChoices} from "ra-core";
import {
    Checkbox,
    createStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FieldArray} from "react-final-form-arrays";
import {InputProps, Labeled, useInput, useTranslate} from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        errorLabel: {
            color: theme.palette.error.main,
        },
    }),
);

type SelectableListInputProps = ChoicesInputProps & ChoicesProps & InputProps;

/**
 * SelectableListInput displays a list of values with checkboxes next to them. The user can check/uncheck
 * these boxes to select/unselect a value.
 *
 * @param props The SelectableListInput props.
 */
export const SelectableListInput: FC<SelectableListInputProps> = (props) => {
    const {source, resource} = props;
    const {getChoiceText, getChoiceValue, getDisableValue} = useChoices(props);

    const classes = useStyles();
    const {
        meta: {error},
        isRequired,
    } = useInput(props);
    const translate = useTranslate();

    if (!source) {
        return null;
    }

    return (
        <Labeled
            {...props}
            source={source}
            resource={resource}
            isRequired={isRequired}
            classes={{label: error ? classes.errorLabel : ""}}
        >
            <>
                <List className={classes.root} dense>
                    <FieldArray name={props.source}>
                        {({fields}) => {
                            return props.choices.map((choice) => {
                                const text = getChoiceText(choice);
                                const value = getChoiceValue(choice);
                                const disabled = getDisableValue(choice);
                                const labelId = `checkbox-list-label-${name}`;
                                const index = fields.value.indexOf(value);

                                const isChecked = () => {
                                    return fields.value[index] !== undefined;
                                };
                                const handleCheck = () => {
                                    return isChecked() ? fields.remove(index) : fields.push(value);
                                };

                                return (
                                    <ListItem
                                        role={undefined}
                                        onClick={handleCheck}
                                        disabled={disabled}
                                        button
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{"aria-labelledby": labelId}}
                                                checked={isChecked()}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={text}/>
                                    </ListItem>
                                );
                            });
                        }}
                    </FieldArray>
                </List>

                <Typography variant="body2" color="error">
                    {error ? translate(error.message, error.args) : " "}
                </Typography>
            </>
        </Labeled>
    );
};