import React from "react";
import {DefaultFilter} from "../DefaultFilter";
import {Datagrid, List, ReferenceArrayField, ReferenceField, SingleFieldList, TextField} from "react-admin";
import {Resources} from "../../Resources";
import {PartnerIconField} from "../Partner/PartnerIconField";

export const ShippingList = (props: unknown): React.ReactElement => (
    <List {...props}
          filters={<DefaultFilter />}
          sort={{field: "model", order: "DESC"}}
    >
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="model" />
            <TextField source="priority" />
            <ReferenceField reference={Resources.ShippingModule} source="shippingModule.id" link={false}>
                <TextField source="title"/>
            </ReferenceField>
            <ReferenceArrayField  reference={Resources.Partner} source="partnerIds">
                <SingleFieldList>
                    <PartnerIconField />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);