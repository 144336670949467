import React, {PropsWithChildren, useCallback} from "react";
import {sprintf} from "sprintf-js";
import {
    MutationMode,
    SimpleForm,
    TabbedForm,
    useCreateContext,
    useEditContext,
    useMutation,
    useNotify,
    useRedirect,
    useRefresh,
} from "react-admin";
import {ApolloError} from "@apollo/client";
import {ValidationExtension} from "../Error/GraphQLError";
import omit from "lodash/omit";

/**
 * The ServerSideValidationForm wraps a SimpleForm or TabbedForm and binds to its save props. When the mutation response
 * contains an apollo error it will evaluate the error response and set field errors accordingly.
 *
 * @param props ServerSideValidatedFormProps
 */
export function ServerSideValidatedForm(
    props: PropsWithChildren<Partial<{ resource: string }>>
): React.ReactElement | null {
    const {children} = props;
    if (!React.isValidElement(children)) {
        throw new Error(sprintf("%s is not a valid React element", children));
    }

    const child = React.Children.only(children);
    if (child.type !== TabbedForm && child.type !== SimpleForm) {
        throw new Error("child is not an instanceof TabbedForm or SimpleForm");
    }

    const createContext = useCreateContext();
    const editContext = useEditContext();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const [mutate] = useMutation();

    const type = createContext.loaded ? "create" : "update";
    const context = type === "create" ? createContext : editContext;
    const mutationMode: MutationMode = "pessimistic";
    const {resource, redirect: redirectTarget, basePath} = context;

    const mapErrors = (error: ApolloError): Record<string, string> => {
        return error.graphQLErrors.reduce((acc, e) => {
            if (e.extensions?.category === "validation") {
                const extensions = e.extensions as unknown as ValidationExtension;
                extensions.validation.forEach((v) => {
                    acc[v.field[0].toLocaleLowerCase() + v.field.slice(1)] = v.message;
                });
            }
            return acc;
        }, {} as Record<string, string>);
    };

    const save = useCallback(
        async (values): Promise<Record<string, string> | string | undefined> => {
            try {
                const {data} = await mutate({
                    type,
                    resource,
                    payload: {data: values},
                }, {returnPromise: true, mutationMode});

                if (type === "create" && redirectTarget !== undefined && data !== undefined) {
                    notify("ra.notification.created", "info", {smart_count: 1});
                    redirect("edit", basePath, data.id, data);
                } else {
                    notify("ra.notification.updated", "info", {smart_count: 1}, false);
                    redirect("list", basePath, data?.id, data);
                }
            } catch (e) {
                if (e instanceof ApolloError && e.graphQLErrors.length > 0) {
                    return mapErrors(e);
                }

                if (e instanceof Error) {
                    const msg = typeof e === "string" ? e : e.message || "ra.notification.http_error";
                    notify(msg, "warning");
                    if (type === "update") {
                        refresh();
                    }
                    return e.message;
                }

                throw e;
            }
        },
        [mutate, redirect, refresh, notify, resource, basePath, type, mutationMode],
    );

    return React.cloneElement(
        child,
        {
            ...omit(props, "children"),
            mutationMode,
            save,
        }
    );
}