import React from "react";
import {Datagrid, DateField, EditButton, FunctionField, List, ReferenceField, TextField} from "ra-ui-materialui";
import {EnumField} from "../Field/EnumField";
import {DefaultFilter} from "../DefaultFilter";
import {Record} from "react-admin";
import {ellipsis} from "../../Strings";
import {Resources} from "../../Resources";

export const FaqQuestionList = (props: React.PropsWithoutRef<unknown>): React.ReactElement => (
    <List {...props}
          sort={{field: "creationDate", order: "DESC"}}
          filters={<DefaultFilter searchPlaceholder={`resources.${Resources.FaqQuestion}.searchPlaceholder`} />}
    >
        <Datagrid>
            <EnumField source="status" name="FaqModerationStatus"/>

            <FunctionField
                label={`resources.${Resources.FaqQuestion}.fields.text`}
                render={(record?: Record) => record ? ellipsis(record.text, 32) : null}
            />

            <ReferenceField source="product.id" reference="Product" sortable={false} link="show">
                <TextField source="model"/>
            </ReferenceField>

            <ReferenceField source="customer.id" reference="Customer" sortable={false} link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source="moderator.id" reference="Customer" sortable={false} link="show">
                <TextField source="name"/>
            </ReferenceField>

            <DateField source="creationDate" showTime={true}/>
            <DateField source="lastModificationDate" showTime={true}/>

            <EditButton/>
        </Datagrid>
    </List>
);