import {Create, CreateProps, DateInput, FormTab, NumberInput, TabbedForm, TextInput} from "ra-ui-materialui";
import {BooleanInput, FormDataConsumer, required} from "react-admin";
import {EnumInput} from "../EnumInput";
import React, {ReactElement, useRef} from "react";
import {ExpressionInput} from "../Expression/ExpressionInput";
import {ExpressionNodeType} from "../../GraphQL/Generated";
import {makeStyles} from "@material-ui/core/styles";
import {Resources} from "../../Resources";
import {PartnerSelectInput} from "../Partner/PartnerSelectInput";
import {ServerSideValidatedForm} from "../../GraphQL/ServerSideValidatedForm";

const useStyles = makeStyles({
    constraintInput: {
        marginTop: "3rem",
    },
});

export const CouponCreate = (props: CreateProps): ReactElement => {
    const classes = useStyles();
    const emptyArray = useRef([]);

    return (
        <Create {...props}>
            <ServerSideValidatedForm>
                <TabbedForm>
                    {/* Allgemeine Einstellungen */}
                    <FormTab label={`resources.${Resources.Coupon}.tabs.general`}>
                        <TextInput
                            source="name"
                            validate={required()}
                        />
                        <TextInput
                            source="description"
                            multiline
                            validate={required()}
                        />
                        <EnumInput
                            name="CouponType"
                            source="type"
                            validate={required()}
                        />
                        <TextInput
                            source="code"
                            validate={required()}
                        />
                        <NumberInput
                            source="amount"
                            min={0}
                            prefix={"test"}
                            validate={required()}
                        />
                        <EnumInput
                            name="CouponAmountType"
                            source="amountType"
                            validate={required()}
                        />
                        <NumberInput
                            source="maxRedemptions"
                            min={0}
                            validate={required()}
                            helperText={`resources.${Resources.Coupon}.helperText.maxRedemptions`}
                        />
                        <NumberInput
                            source="maxRedemptionsPerUser"
                            min={0}
                            validate={required()}
                            helperText={`resources.${Resources.Coupon}.helperText.maxRedemptionsPerUser`}
                        />
                        <DateInput
                            source="startDate"
                            validate={required()}
                        />
                        <DateInput
                            source="endDate"
                            validate={required()}
                        />

                        <PartnerSelectInput validate={required()} fullWidth/>

                        <BooleanInput
                            source="isCombinable"
                            validate={required()}
                            defaultValue={false}
                        />
                    </FormTab>

                    {/* Constraints / Einschränkungen */}
                    <FormTab label={`resources.${Resources.Coupon}.tabs.constraints`}>
                        <FormDataConsumer>
                            {() => (
                                <>
                                    <ExpressionInput
                                        fullWidth
                                        source="constraints"
                                        type={ExpressionNodeType.ShoppingCart}
                                        defaultValue={emptyArray.current}
                                    />

                                    <ExpressionInput
                                        className={classes.constraintInput}
                                        fullWidth
                                        source="productConstraints"
                                        type={ExpressionNodeType.Product}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </FormTab>
                </TabbedForm>
            </ServerSideValidatedForm>
        </Create>
    );
};