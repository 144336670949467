import React from "react";
import {Admin as ReactAdmin, Resource} from "react-admin";
import i18nProvider from "./I18n/I18nProvider";
import {AdminInitialState, CustomReducer} from "./Redux/CustomReducer";
import theme from "./Style/Theming/Theme";
import {Resources} from "./Resources";
import {CustomerList} from "./Components/Customer/CustomerList";
import {
    AlarmOn,
    Category,
    FormatPaintOutlined,
    LocalGroceryStore,
    LocalShipping,
    MoneyOff,
    People,
    QuestionAnswer,
    Search,
    Settings,
    ShoppingBasketOutlined,
    Store,
    SwapHoriz,
    YoutubeSearchedFor,
} from "@material-ui/icons";
import {ProductList} from "./Components/Product/ProductList";
import {CouponList} from "./Components/Coupon/CouponList";
import {CouponEdit} from "./Components/Coupon/CouponEdit";
import {CategoryList} from "./Components/Category/CategoryList";
import {CategoryEdit} from "./Components/Category/CategoryEdit";
import {CategoryCreate} from "./Components/Category/CategoryCreate";
import {FaqQuestionList} from "./Components/FAQ/FaqQuestionList";
import {FaqQuestionEdit} from "./Components/FAQ/FaqQuestionEdit";
import {AppContext, AppContextProps} from "./Context/AppContext";
import {assertDefined} from "./Assert";
import {StoreConsumer} from "./Redux/StoreConsumer";
import {CouponCreate} from "./Components/Coupon/CouponCreate";
import {ShippingList} from "./Components/Shipping/ShippingList";
import {ShippingEdit} from "./Components/Shipping/ShippingEdit";
import {ShippingCreate} from "./Components/Shipping/ShippingCreate";
import {CustomerShow} from "./Components/Customer/CustomerShow";
import {SearchOptimizationList} from "./Components/SearchOptimization/SearchOptimizationList";
import {SearchOptimizationEdit} from "./Components/SearchOptimization/SearchOptimizationEdit";
import {SearchOptimizationCreate} from "./Components/SearchOptimization/SearchOptimizationCreate";
import {SearchOptimizationReviewList} from "./Components/SearchOptimizationReview/SearchOptimizationReviewList";
import {SearchOptimizationReviewEdit} from "./Components/SearchOptimizationReview/SearchOptimizationReviewEdit";
import {RedirectList} from "./Components/Redirect/RedirectList";
import {RedirectEdit} from "./Components/Redirect/RedirectEdit";
import {RedirectCreate} from "./Components/Redirect/RedirectCreate";
import {ProductShow} from "./Components/Product/ProductShow";
import {OrderList} from "./Components/Order/OrderList";
import {OrderShow} from "./Components/Order/OrderShow";
import {CustomerEdit} from "./Components/Customer/CustomerEdit";
import {Layout} from "./Style/Layout/Layout";
import {Dashboard} from "./Components/Dashboard/Dashboard";
import {CronJobList} from "./Components/CronJob/CronJobList";
import {CronJobEdit} from "./Components/CronJob/CronJobEdit";
import {CronJobCreate} from "./Components/CronJob/CronJobCreate";
import CustomRoutes from "./Routing/CustomRoutes";
import {FileEdit} from "./Components/File/FileEdit";
import {PartnerList} from "./Components/Partner/PartnerList";
import {PartnerEdit} from "./Components/Partner/PartnerEdit";
import {PartnerCreate} from "./Components/Partner/PartnerCreate";
import {FormCustomizerList} from "./Components/FormCustomizer/FormCustomizerList";
import {FormCustomizerEdit} from "./Components/FormCustomizer/FormCustomizerEdit";
import {FormCustomizerCreate} from "./Components/FormCustomizer/FormCustomizerCreate";
import {SettingList} from "./Components/Setting/SettingList";

/**
 * App is the react entry point.
 *
 * @param props The Apps props
 * @param props.context The initialized App Context
 * @returns React.ReactElement
 */
function App({context}: { context: AppContextProps }): React.ReactElement {
    return (
        <AppContext.Provider value={context}>
            <AppContext.Consumer>
                {(context) => {
                    const {dataProvider, authProvider, themeMode} = context;
                    assertDefined(dataProvider);
                    assertDefined(authProvider);

                    return (
                        <ReactAdmin
                            dataProvider={dataProvider}
                            authProvider={authProvider}
                            i18nProvider={i18nProvider}
                            customReducers={CustomReducer}
                            initialState={AdminInitialState}
                            theme={theme(themeMode)}
                            layout={Layout}
                            dashboard={Dashboard}
                            customRoutes={CustomRoutes}
                            disableTelemetry
                        >
                            {/* Customer */}
                            <Resource name={Resources.Customer}
                                      list={CustomerList}
                                      show={CustomerShow}
                                      edit={CustomerEdit}
                                      icon={People}
                            />

                            {/* Address */}
                            <Resource name={Resources.Address}/>

                            {/* Country */}
                            <Resource name={Resources.Country}/>

                            {/* Products */}
                            <Resource name={Resources.Product}
                                      list={ProductList}
                                      show={ProductShow}
                                      icon={LocalGroceryStore}
                            />
                            <Resource name={Resources.ProductGroup}
                                      show={ProductShow}/>
                            <Resource name={Resources.ExpressionNode}/>

                            {/* Orders */}
                            <Resource name={Resources.Order}
                                      list={OrderList}
                                      show={OrderShow}
                                      icon={ShoppingBasketOutlined}
                            />

                            {/* Coupons */}
                            <Resource name={Resources.Coupon}
                                      list={CouponList}
                                      edit={CouponEdit}
                                      create={CouponCreate}
                                      icon={MoneyOff}
                            />

                            {/* Versand */}
                            <Resource name={Resources.Shipping}
                                      list={ShippingList}
                                      edit={ShippingEdit}
                                      create={ShippingCreate}
                                      icon={LocalShipping}
                            />
                            <Resource name={Resources.ShippingModule}/>

                            {/* Category */}
                            <Resource name={Resources.Category}
                                      list={CategoryList}
                                      edit={CategoryEdit}
                                      create={CategoryCreate}
                                      icon={Category}
                            />

                            {/* Language */}
                            <Resource name={Resources.Language}/>

                            {/* FAQ */}
                            <Resource name={Resources.Faq}/>
                            <Resource name={Resources.FaqAnswer}/>
                            <Resource name={Resources.FaqQuestion}
                                      list={FaqQuestionList}
                                      edit={FaqQuestionEdit}
                                      icon={QuestionAnswer}
                            />

                            {/* Search Optimization */}
                            <Resource name={Resources.SearchOptimization}
                                      list={SearchOptimizationList}
                                      edit={SearchOptimizationEdit}
                                      create={SearchOptimizationCreate}
                                      icon={Search}
                            />

                            {/* Search Optimization Reviews */}
                            <Resource name={Resources.SearchOptimizationReview}
                                      list={SearchOptimizationReviewList}
                                      edit={SearchOptimizationReviewEdit}
                                      icon={YoutubeSearchedFor}
                            />

                            {/* Redirects */}
                            <Resource name={Resources.Redirect}
                                      list={RedirectList}
                                      edit={RedirectEdit}
                                      create={RedirectCreate}
                                      icon={SwapHoriz}
                            />

                            {/* CronJobs */}
                            <Resource name={Resources.CronJob}
                                      list={CronJobList}
                                      edit={CronJobEdit}
                                      create={CronJobCreate}
                                      icon={AlarmOn}
                            />

                            {/* Form Customizer */}
                            <Resource name={Resources.FormCustomizer}
                                      list={FormCustomizerList}
                                      edit={FormCustomizerEdit}
                                      create={FormCustomizerCreate}
                                      icon={FormatPaintOutlined}
                            />

                            {/* Partner */}
                            <Resource name={Resources.Partner}
                                      list={PartnerList}
                                      edit={PartnerEdit}
                                      create={PartnerCreate}
                                      icon={Store}
                            />

                            {/* OCI */}
                            <Resource name={Resources.OciMappingField}/>
                            <Resource name={Resources.OciFieldValue}/>

                            {/* Files */}
                            <Resource name={Resources.File}/>
                            <Resource name={Resources.FileMeta}
                                      edit={FileEdit}
                            />

                            {/* Settings */}
                            <Resource name={Resources.Setting}
                                      list={SettingList}
                                      icon={Settings}
                            />

                            <StoreConsumer>{
                                (store) => {
                                    context.store = store;
                                }
                            }</StoreConsumer>
                        </ReactAdmin>
                    );
                }}
            </AppContext.Consumer>
        </AppContext.Provider>
    );
}

export default App;
