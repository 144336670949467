import {ApiResource} from "../../GraphQL/Generated";

type PermissionMapInner = Record<string, Record<string, null>>;

export class AccessibleResourceMap {
    private readonly accessibleResourceMap: PermissionMapInner;

    constructor(accessibleResources: ApiResource[] = []) {
        this.accessibleResourceMap = this.mapAccessibleResource(accessibleResources);
    }

    canAccess(resource: string, operation: string): boolean {
        if (!this.accessibleResourceMap[resource]) {
            return false;
        }

        return this.accessibleResourceMap[resource][operation] !== undefined;
    }

    private mapAccessibleResource(accessibleResources: ApiResource[]) {
        return accessibleResources.reduce<PermissionMapInner>((acc, r) => {
            if (r.operations.length > 0) {
                if (!acc[r.name]) {
                    acc[r.name] = {};
                }

                r.operations.forEach((op) => {
                    acc[r.name][op] = null;
                });
            }

            return acc;
        }, {});
    }
}