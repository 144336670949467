import React from "react";
import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    minLength,
    ReferenceField,
    required,
    SimpleForm,
    useEditController,
    useTranslate,
} from "react-admin";
import {EditProps, TextField} from "ra-ui-materialui";
import {EnumInput} from "../EnumInput";
import {Resources} from "../../Resources";
import RichTextInput from "ra-input-rich-text";

export const FaqQuestionEdit = (props: EditProps): React.ReactElement => {
    const translate = useTranslate();
    const {record} = useEditController(props);

    return (
        <Edit {...props} title={translate("resource.faqQuestion")}>
            <SimpleForm>
                <ReferenceField source="customer.id" reference={Resources.Customer}>
                    <TextField source="name"/>
                </ReferenceField>

                <ReferenceField source="product.id" reference={Resources.Product}>
                    <TextField source="model"/>
                </ReferenceField>

                {record && record["moderator"] !== undefined && <ReferenceField
                    source="moderator.id"
                    reference={Resources.Customer}
                >
                    <TextField source="name"/>
                </ReferenceField>}

                <TextField source="text"/>

                <EnumInput name="FaqModerationStatus" source="status"/>

                <FormDataConsumer>{({formData}) => formData.status === "APPROVED" &&
                    <RichTextInput
                        source="answer"
                        style={{width: "50%"}}
                        validate={[required(), minLength(3)]}
                    />
                }</FormDataConsumer>
                <BooleanInput source="shareWithSiblings"/>
            </SimpleForm>
        </Edit>
    );
};