import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import {AppContextBuilder} from "./Context/Builder/AppContextBuilder";
import {AppContextDirector} from "./Context/Builder/AppContextDirector";
import App from "./App";

const builder = new AppContextBuilder();
const director = new AppContextDirector();

director
    .constructAppContext(builder)
    .then((c) => {
        ReactDOM.render(
            // <React.StrictMode>
            //     <App context={c}/>,
            // </React.StrictMode>,
            <App context={c}/>,
            document.getElementById("root")
        );
    });
