import {PasswordCredentials} from "./PasswordCredentials";
import {Authentication, AuthenticationType} from "../Authentication";
import {ok, Result} from "../../Result";
import {InvalidTypeError} from "../../Error/InvalidTypeError";
import {HttpAuthenticator} from "./HttpAuthenticator";
import {Authenticator} from "../Authenticator";

/**
 * Authenticates against a oauth2 endpoint using the client_credentials grant type.
 */
export class PasswordCredentialsAuthenticator<T extends string>
    extends HttpAuthenticator<T> implements Authenticator<T> {
    private readonly scope: string;

    constructor(scope: string) {
        super();
        this.scope = scope;
    }

    async authenticate(uri: string, credentials: PasswordCredentials): Promise<Result<Authentication<T>>> {
        if (!credentials.password) {
            throw InvalidTypeError.create(credentials.password, "string");
        }
        if (!credentials.username) {
            throw InvalidTypeError.create(credentials.username, "string");
        }

        const result = await super.authenticateRequest(uri, credentials);
        if (!ok(result)) {
            return result;
        }

        return {
            ...result,
            type: AuthenticationType.PasswordCredentials,
        };
    }

    protected fetch(uri: string, credentials: PasswordCredentials): Promise<Response> {
        return fetch(uri, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
                client_id: credentials.clientId,
                client_secret: credentials.clientSecret,
                grant_type: "password",
                password: credentials.password,
                scope: this.scope,
                username: credentials.username,
            }),
        });
    }
}