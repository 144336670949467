import {FC, memo} from "react";
import {FieldProps, FunctionField, useRecordContext} from "react-admin";
import {useLocaleContext} from "../../I18n/LocaleContext";
import get from "lodash/get";

/**
 * Formats the field value as currency. Uses the current locale to determine the format.
 *
 * @param props The MoneyField props.
 * @class
 */
export const MoneyField: FC<FieldProps> = memo((props) => {
    const {currencyFormat} = useLocaleContext();
    const {source} = props;
    const record = useRecordContext(props);

    if (!source) {
        return null;
    }

    const value = get(record, source);

    return <FunctionField render={() => currencyFormat.format(value)}/>;
});

MoneyField.defaultProps = {
    addLabel: true,
};