import {ClientCredentials} from "./ClientCredentials";
import {Authenticator} from "../Authenticator";
import {HttpAuthenticator} from "./HttpAuthenticator";
import {ok, Result} from "../../Result";
import {Authentication, AuthenticationType} from "../Authentication";

/**
 * Authenticates against a oauth2 endpoint using the client_credentials grant type.
 */
export class ClientCredentialsAuthenticator<T extends string> extends HttpAuthenticator<T> implements Authenticator<T> {
    private readonly scope: string;

    constructor(scope: string) {
        super();
        this.scope = scope;
    }

    async authenticate(uri: string, credentials: ClientCredentials): Promise<Result<Authentication<T>>> {
        const result = await super.authenticateRequest(uri, credentials);

        if (!ok(result)) {
            return result;
        }

        return {
            ...result,
            type: AuthenticationType.ClientCredentials,
        };
    }

    protected fetch(uri: string, credentials: ClientCredentials): Promise<Response> {
        return fetch(uri, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
                client_id: credentials.clientId,
                client_secret: credentials.clientSecret,
                grant_type: "client_credentials",
                scope: this.scope,
            }),
        });
    }
}