import {FC, useEffect, useMemo} from "react";
import {Entry} from "./Entry";
import {ManagerActions} from "./ManagerActions";
import {EntryList} from "./EntryList";
import createReducer, {ManagerDispatchContext} from "./Reducer";
import {Context, ManagerContext} from "./Context";
import {AcceptProp} from "../FilePicker";

interface FileManagerProps {
    /**
     * entries contains a list of file system entries. Entries can be files or folders.
     */
    entries: Entry[]

    /**
     * onOpen is called when the user clicks on an entry.
     */
    onOpen: (entry: Entry) => void

    /**
     * onDelete is called with a list of entries to delete.
     */
    onDelete: (entries: Entry[]) => Promise<void>

    /**
     * onUpload is called when a file is uploaded.
     */
    onUpload: (file: File) => Promise<void>

    /**
     * onMove is called when entry is moved.
     */
    onMove: (from: string, to: string) => Promise<void>

    /**
     * onCreateDirectory is called when a directory needs to be created.
     */
    onCreateDirectory: (path: string) => Promise<void>

    /**
     * onEditEntry is called when the user wants to edit a file.
     *
     * @param entry
     */
    onEditEntry: (entry: Entry) => Promise<void>

    /**
     * The currently opened path
     */
    currentPath: string

    /**
     * The currently previewed entry
     */
    previewEntry?: Entry

    /**
     * Indicates that the entries are being loaded.
     */
    loading: boolean

    accept?: AcceptProp
}

export const Manager: FC<FileManagerProps> = function Manager(props) {
    const {
        accept,
        currentPath,
        entries,
        onDelete,
        onUpload,
        onOpen,
        onMove,
        onCreateDirectory,
        onEditEntry,
        loading,
    } = props;

    const [state, dispatch] = createReducer(currentPath, entries, loading);

    useEffect(() => {
        dispatch({entries, type: "setEntries"});
    }, [entries]);

    useEffect(() => {
        dispatch({path: currentPath, type: "setCurrentPath"});
    }, [currentPath]);


    useEffect(() => {
        dispatch({type: "setLoading", loading});
    }, [loading]);

    const context = useMemo<Context>(() => {
        return {
            accept,
            createDirectory: onCreateDirectory,
            delete: onDelete,
            editFile: onEditEntry,
            move: onMove,
            open: onOpen,
            upload: (files) => {
                return Promise.all(
                    files.map((f) => onUpload(f))
                ).then(() => {
                    return;
                });
            },
        };
    }, [accept, onCreateDirectory, onDelete, onMove, onOpen, onUpload]);

    return (
        <ManagerDispatchContext.Provider value={dispatch}>
            <ManagerContext.Provider value={context}>
                <ManagerActions
                    selectedEntries={state.selectedEntries}
                    currentPath={state.currentPath}
                />

                <EntryList
                    entries={state.entries}
                    pathMap={state.pathMap}
                    currentEntry={state.currentEntry}
                    loading={loading}
                />
            </ManagerContext.Provider>
        </ManagerDispatchContext.Provider>
    );
};