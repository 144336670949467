import {ChangeEvent, FC, FocusEvent, useCallback, useEffect, useState} from "react";
import {useMutation, useNotify, useRecordContext, useTranslate} from "react-admin";
import {Setting, Type} from "../../GraphQL/Generated";
import {Resources} from "../../Resources";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";

export const SettingField: FC = () => {
        const notify = useNotify();
        const translate = useTranslate();
        const {description, name, type, value} = useRecordContext<Setting>();
        const [newValue, setNewValue] = useState(value);

        const [save, {error, loading, loaded}] = useMutation({
            type: "update",
            resource: Resources.Setting,
            payload: {data: {id: name, value: newValue}},
        });

        const handleBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
            setNewValue(e.currentTarget.value);
        }, []);

        const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
            setNewValue(e.currentTarget.checked ? "true" : "false");
        }, []);

        useEffect(() => {
            if (newValue !== value) {
                save();
            }
        }, [save, newValue, value]);

        useEffect(() => {
            if (error) {
                notify(translate("ra.message.error"), "error");
            }
        }, [error, name]);

        useEffect(() => {
            if (loaded) {
                notify(translate("ra.notification.updated", {smart_count: 1}), "success", false);
            }
        }, [loading, loaded]);

        switch (type) {
            case Type.Bool:
                return (
                    <FormControlLabel control={(
                        <Checkbox
                            defaultChecked={value === "true"}
                            onChange={handleCheckboxChange}
                        />
                    )} label={description}/>
                );

            default:
                return (
                    <TextField
                        defaultValue={value}
                        onBlur={handleBlur}
                        label={description}
                    />
                );
        }
    }
;