import {Error} from "./Error";

/**
 * A RuntimeError represents an error that occurs during runtime. E.g. connection to a resource failed, network dropped
 * etc.
 */
export class RuntimeError extends Error {
    private context?: unknown;

    constructor(message?: string, previous?: Error, context?: unknown) {
        super(message, previous);
        this.context = context;
    }
}