import {useIntrospectionType} from "../Hooks/useIntrospectionType";
import {useMemo} from "react";
import {InvalidPropsError} from "../Error/InvalidPropsError";
import {sprintf} from "sprintf-js";
import {useTranslate} from "react-admin";

interface Choice {
    id: string
    name: string
    disabled?: boolean
}

/**
 * The useEnumValues returns a choices list of all values of the enum.
 *
 * @param enumName The name of the enum to get the values for.
 */
export const useEnumChoices = (enumName: string): Choice[] => {
    const enumType = useIntrospectionType(enumName);
    const translate = useTranslate();

    if (!enumType) {
        throw new InvalidPropsError(`${enumName} is not a registered type`);
    }

    if (enumType.kind !== "ENUM") {
        throw new InvalidPropsError(`${enumName} is not a ENUM type`);
    }

    return useMemo(() => {
        return enumType.enumValues.map((value) => {
            const name = sprintf("enum.%s.%s", enumName, value.name);
            return {id: value.name, name: translate(name)};
        });
    }, [enumType]);
};