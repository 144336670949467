import {FC} from "react";
import {Box, Paper, Typography} from "@material-ui/core";
import {EntryDetails} from "./EntryDetails";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslate} from "react-admin";
import {Resources} from "../../../Resources";
import {Entry} from "./Entry";

interface EntryListAsideProps {
    currentEntry?: Entry
}

const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: 80,
    },
    placeholder: {
        alignItems: "center",
        display: "flex",
        height: 400,
        justifyContent: "center",
    },
});

export const EntryListAside: FC<EntryListAsideProps> = ({currentEntry}) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Paper className={classes.root}>
            <Box p={3} py={2}>
                {currentEntry !== undefined ?
                    <EntryDetails entry={currentEntry}/>
                    :
                    <div className={classes.placeholder}>
                        <Typography component="h3" variant="body2" color="textSecondary">
                            {translate(`resources.${Resources.File}.aside.placeholder`)}
                        </Typography>
                    </div>
                }
            </Box>
        </Paper>
    );
};