import {DateTimeFormatterInterface, DateTimeFormatType} from "./DateTimeFormatterInterface";
import {DateTime} from "luxon";

export class LuxonDateTimeFormatter implements DateTimeFormatterInterface {
    private readonly locale: string;

    constructor(locale: string) {
        this.locale = locale;
    }

    format(date: Date, type?: DateTimeFormatType): string {
        if (!date) {
            return date;
        }

        const dt = DateTime
            .fromJSDate(date)
            .setLocale(this.locale);

        if (!type) {
            type = "short";
        }

        if (type === "long") {
            return dt.toLocaleString({timeStyle: "long", dateStyle: "medium"});
        } else {
            return dt.toLocaleString({timeStyle: "short", dateStyle: "short"});
        }
    }
}