import {useCallback} from "react";
import {GridCellValue, GridComparatorFn} from "@material-ui/data-grid";

/**
 * The sorter always sorts ".." paths to the top. Other types are sorted automatically.
 */
export const useSorter = (): GridComparatorFn => {
    return useCallback((a: GridCellValue, b: GridCellValue,): number => {
        if (typeof a === "string" && typeof b === "string") {
            if (b === "..") {
                return 0;
            }

            return a.localeCompare(b);
        }
        if (typeof a === "number" && typeof b === "number") {
            return a - b;
        }
        if (a instanceof Date && b instanceof Date) {
            return a.getTime() - b.getTime();
        }
        return 0;
    }, []);
};