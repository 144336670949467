import React, {FC} from "react";
import {Datagrid, FunctionField, ImageField, List, TextField} from "ra-ui-materialui";
import {DefaultFilter, DefaultFilterProps} from "../DefaultFilter";
import classes from "./ProductList.module.scss";
import {BooleanField, BooleanInput, Record} from "react-admin";
import {ellipsis} from "../../Strings";
import {Resources} from "../../Resources";

const ProductFilter: FC<DefaultFilterProps> = (props) => {
    return (
        <DefaultFilter {...props}>
            <BooleanInput
                label={`resources.${Resources.Product}.filter.includeInactive`}
                source="includeInactive"
            />
        </DefaultFilter>
    );
};

export const ProductList = (props: React.PropsWithoutRef<unknown>): React.ReactElement => (
    <List {...props}
          filters={<ProductFilter/>}
          sort={{field: "model", order: "ASC"}}
    >
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <ImageField source="image" classes={{image: classes.image}} sortable={false}/>
            <TextField source="model"/>
            <FunctionField
                label={`resources.${Resources.Product}.fields.name`}
                render={(record?: Record) => ellipsis(record?.name, 64)}
            />
            <BooleanField source="active" sortable={false}/>
        </Datagrid>
    </List>
);