import {AppContextProps, DefaultAppContext} from "../AppContext";
import {AppContextBuilderInterface} from "./AppContextBuilderInterface";
import {AuthProvider} from "../../ReactAdmin/Auth/AuthProvider";
import {ClientCredentials} from "../../Security/OAuth2/ClientCredentials";
import {ClientCredentialsAuthenticator} from "../../Security/OAuth2/ClientCredentialsAuthenticator";
import {ok} from "../../Result";
import {assertDefined} from "../../Assert";
import has from "lodash/has";
import {ObjectConfigLoader} from "../../Config/ObjectConfigLoader";
import {SchemaFetcher} from "../../GraphQL/SchemaFetcher";
import {Resources} from "../../Resources";
import buildGraphQLProvider from "../../ReactAdmin/buildGraphQLProvider";
import {LocalStorageCache} from "../../Cache/LocalStorageCache";
import {ApolloClientFactory} from "../../GraphQL/Apollo/ApolloClientFactory";
import {AuthenticationType, expired, parseAuthentication} from "../../Security/Authentication";
import {deepFreeze} from "../../Object";
import {Config} from "../../Config/Config";
import {isDevEnvironment} from "../../Environment";
import {sprintf} from "sprintf-js";

/**
 * The PersistedAppContextBuilder creates an app context and saves authentication information in the local storage. The
 * next time the builder is used it will restore some authentication data from the local storage cache.
 */
export class AppContextBuilder implements AppContextBuilderInterface {
    private context: AppContextProps;

    constructor() {
        this.context = {
            ...DefaultAppContext,
        };
    }

    async buildAuthProvider(): Promise<void> {
        const {cache} = this.context;
        assertDefined(cache);

        this.context.authProvider = new AuthProvider(this.context);
    }

    async buildClient(): Promise<void> {
        const {config, cache} = this.context;

        assertDefined(config);
        assertDefined(cache);

        // restore auth from cache
        if (await cache.has("auth")) {
            const cachedAuth = await cache.get("auth");

            if (cachedAuth !== null) {
                try {
                    const auth = parseAuthentication<string>(cachedAuth);
                    if (!expired(auth)) {
                        this.context.auth = auth;
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            if (!this.context.auth) {
                await cache.remove("auth");
            }
        }

        if (!this.context.auth) {
            const {clientCredentials: {clientId, clientSecret}, scope, URI} = config.OAuth;
            const credentials: ClientCredentials = {type: AuthenticationType.ClientCredentials, clientId, clientSecret};

            const clientCredentialsAuthenticator = new ClientCredentialsAuthenticator(scope);
            const result = await clientCredentialsAuthenticator.authenticate(URI, credentials);

            if (!ok(result)) {
                throw result;
            }

            this.context.auth = result;
        }

        const clientFactory = new ApolloClientFactory(this.context);

        let uri = config.GraphQL.URI;

        if (isDevEnvironment() && document.cookie.indexOf("XDEBUG_") > -1) {
            const sessionType = /XDEBUG_(SESSION|PROFILE|TRACE)=([\w\d_]+)/.exec(document.cookie);
            if (sessionType !== null) {
                uri += sprintf("?XDEBUG_%s=%s", sessionType[1], sessionType[2]);
            }
        }

        this.context.client = await clientFactory.create(uri, true);
    }

    async buildConfig(): Promise<void> {
        const globalEnvEnabled = has(window, "__ENV");
        const env = globalEnvEnabled ? (window as never)["__ENV"] : process.env;
        const loader = new ObjectConfigLoader(env);
        const cfg = await loader.load();
        if (!ok(cfg)) {
            throw cfg;
        }

        this.context.config = deepFreeze<Config>(cfg);
    }

    async buildDataProvider(): Promise<void> {
        const {client} = this.context;
        assertDefined(client);

        const schemaFetcher = new SchemaFetcher(client);
        const scm = await schemaFetcher.fetch();

        if (!ok(scm)) {
            throw scm;
        }

        const introspection = {
            schema: scm,
            include: Object.values(Resources),
        };

        this.context.dataProvider = await buildGraphQLProvider({
            client,
            introspection,
        }, this.context);

        this.context.schema = scm;
    }

    async buildCache(): Promise<void> {
        this.context.cache = new LocalStorageCache();
    }

    reset(): void {
        this.context = {
            ...DefaultAppContext,
        };
    }

    getContext(): AppContextProps {
        return this.context;
    }
}