import React from "react";
import {IntrospectionSchema} from "graphql";
import {Config} from "../Config/Config";
import {ApolloClient} from "@apollo/client";
import {Store} from "redux";
import {CacheInterface} from "../Cache/CacheInterface";
import {NormalizedCacheObject} from "apollo-cache-inmemory";
import {AuthProvider, DataProvider} from "react-admin";
import {Authentication} from "../Security/Authentication";

export interface AppContextProps {
    /**
     * The GraphQL schema that was fetched from the API via introspection. It will be undefined as long as the schema
     * hast not been injected yet.
     */
    schema?: IntrospectionSchema

    /**
     * The dark/light mode switch
     */
    themeMode: "light" | "dark"

    /**
     * The config property holds the current global config. It i
     */
    config?: DeepReadonlyObject<Config>

    dataProvider?: DataProvider;

    authProvider?: AuthProvider;

    client?: ApolloClient<NormalizedCacheObject>

    store?: Store,

    cache?: CacheInterface<string, string>

    auth?: Authentication<string>
}

export const DefaultAppContext: AppContextProps = {
    themeMode: "light",
};

/**
 * AppContext is the global context of the app. You can inject it in your component using the useAppContext hook.
 */
export const AppContext = React.createContext<AppContextProps>(DefaultAppContext);