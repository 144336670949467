import {CopyToClipboard as LibCopyToClipboard} from "react-copy-to-clipboard";
import React from "react";
import {Assignment} from "@material-ui/icons";
import styles from "./CopyToClipboard.module.scss";
import {Button} from "@material-ui/core";
import {useNotify, useTranslate} from "react-admin";

interface CopyToClipboardProps {
    text: string
}

export const CopyToClipboard = ({text}: CopyToClipboardProps): React.ReactElement => {
    const notify = useNotify();
    const translate = useTranslate();

    return (
        <LibCopyToClipboard
            text={text}
            onCopy={() => notify("clipboard.copySuccess", "success")}
        >
            <Button size="small" className={styles.button} title={translate("clipboard.copyTitle")}>
                <span>{text}</span>
                <Assignment className={styles.icon}/>
            </Button>
        </LibCopyToClipboard>
    );
};