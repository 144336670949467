import React, {FC, memo} from "react";
import {Breadcrumbs, BreadcrumbsProps} from "@material-ui/core";
import {RouterState} from "connected-react-router";
import {CombinedState} from "redux";
import {LocationState} from "history";
import {linkToRecord, useGetResourceLabel, useTranslate} from "react-admin";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Home} from "@material-ui/icons";
import {selectAdminState} from "../../Redux/CustomReducer";
import {useSelector} from "react-redux";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    homeIcon: {
        color: theme.palette.text.secondary,
        marginTop: 5,
        fontSize: "1.25em",
    },
    link: {
        "&$linkActive": {
            fontWeight: 600,
        },
        "color": theme.palette.text.secondary,
        "display": "block",
        "padding": 15,
        "paddingLeft": 8,
        "paddingRight": 8,
        "textDecoration": "none",
    },
    linkActive: {},
    separator: {
        color: theme.palette.divider,
    },
}));


/**
 * The Breadcrumb component shows the path to the current resource.
 *
 * @param props The Breadcrumb props
 */
export const Breadcrumb: FC<BreadcrumbsProps> = memo((props) => {
    const state = useSelector((state: CombinedState<{
        router: RouterState<LocationState>
    }>) => state.router);
    const classes = useStyles();
    const translate = useTranslate();
    const getLabel = useGetResourceLabel();
    const overrides = selectAdminState((s) => s.breadcrumbs.overrides);

    const urlSegments = state.location.pathname.split("/").slice(1);
    const resource = urlSegments[0];

    const path = [
        <Link to="/" title={translate("breadcrumb.start")}><Home classes={{root: classes.homeIcon}}/></Link>,
    ];

    if (resource) {
        path.push(<Link to={`/${resource}`}>{getLabel(resource, 2)}</Link>);

        if (!(resource in overrides)) {
            const id = urlSegments[1];
            const linkType = urlSegments[2] ?? "edit";
            const linkToResource = linkToRecord(`/${resource}`, id, linkType);

            if (id) {
                path.push(<Link to={linkToResource}>{id}</Link>);
            }
        } else {
            overrides[resource].forEach(({label, ...props}) => {
                path.push(<Link {...props}>{label}</Link>);
            });
        }
    }

    return (
        <Breadcrumbs aria-label="breadcrumbs" {...props} classes={{separator: classes.separator}}>
            {path.map((el, i) => React.cloneElement(el, {
                className: classes.link + " " + classNames({
                    [classes.linkActive]: i === path.length - 1,
                }),
                key: i,
            }))}
        </Breadcrumbs>
    );
});