/**
 * The ellipsis method returns a text, truncates it when it exceeds a threshold and appends a ellipsis
 * string when needed.
 *
 * @param text The text to truncate.
 * @param maxLength The maximum length of text.
 * @param ellipsisStr The string that will be appended when the text length is greater than maxLength.
 */
export function ellipsis(text: string, maxLength: number, ellipsisStr = "…"): string {
    if (!text) {
        return text;
    }

    if (maxLength < 0) {
        throw new Error("maxLength must be greater than 0");
    }

    text = text.trim();
    if (text.length <= maxLength) {
        return text;
    }

    return text.slice(0, maxLength) + ellipsisStr;
}