import {FC, forwardRef, ReactElement, Ref, useCallback, useState} from "react";
import {AppBar, Button, Dialog, DialogContent, IconButton, Slide, Theme, Toolbar, Typography} from "@material-ui/core";
import {FileList, FileListMode} from "./FileList";
import {useTranslate} from "react-admin";
import {Entry} from "./Manager/Entry";
import {TransitionProps} from "@material-ui/core/transitions";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/styles";
import {Resources} from "../../Resources";

/**
 * AcceptProp is a list of mime-type expressions that are accepted when uploading files.
 *
 * Example:
 * image/jpg
 * image/*
 * \*\/* (ignore backslashes)
 */
export type AcceptProp = string[];

export interface FileSelectorProps {
    /**
     * Whether the dialog is open
     */
    open: boolean

    /**
     * onClose is called when the has dismissed the dialog.
     */
    onClose?: () => void

    /**
     * onSelect is called when the user has selected a file.
     *
     * @param entry
     */
    onSelect?: (entry: Entry) => void

    /**
     * The path the file picker will open in
     */
    initialPath?: string

    accept?: AcceptProp
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        content: {
            paddingTop: 25,
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);

export const FilePicker: FC<FileSelectorProps> = ({accept, open, onClose, onSelect, initialPath}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [selectedEntry, setSelectedEntry] = useState<Entry>();

    const handlePick = useCallback((entry) => {
        setSelectedEntry(entry);
    }, []);
    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);
    const handleClickSelect = useCallback(() => {
        if (onSelect && selectedEntry) {
            onSelect(selectedEntry);
        }
    }, [onSelect, selectedEntry]);

    return (
        <Dialog open={open} fullScreen TransitionComponent={Transition} onEscapeKeyDown={handleClose}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" autoFocus>
                        <Close/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {translate(`resources.${Resources.File}.actions.select`)}
                    </Typography>
                    <Button color="inherit" onClick={handleClickSelect} disabled={!selectedEntry}>
                        {translate("actions.select")}
                    </Button>
                </Toolbar>
            </AppBar>

            <DialogContent className={classes.content}>
                <FileList
                    mode={FileListMode.Picker}
                    onPick={handlePick}
                    initialPath={initialPath}
                    accept={accept}
                />
            </DialogContent>
        </Dialog>
    );
};