import {BooleanInput, Edit, EditProps, TextInput} from "ra-ui-materialui";
import {FormTab, TabbedForm, useEditController} from "react-admin";
import React, {FC, useState} from "react";
import {Resources} from "../../Resources";
import {ServerSideValidatedForm} from "../../GraphQL/ServerSideValidatedForm";
import {Partner} from "../../GraphQL/Generated";
import {OciMappingInput} from "../OciMapping/OciMappingInput";
import {Localized} from "../Language/Localized";
import {EnumInput} from "../EnumInput";

export const PartnerEdit: FC<EditProps> = (props) => {
    const {record, loaded} = useEditController(props);

    const [checkoutModule, setCheckoutModule] = useState<string | null>(
        record?.checkoutModule || null
    );

    if (!loaded) {
        return null;
    }

    return (
        <Localized {...props}>
            <Edit {...props}>
                <ServerSideValidatedForm>
                    <TabbedForm>
                        <FormTab label={`resources.${Resources.Partner}.tabs.general`}>
                            <BooleanInput source="isSapPartner"/>
                            <TextInput source="name"/>
                            <TextInput source="identifier" disabled={true}/>
                            <TextInput source="template"/>
                            <TextInput source="trustedShopsId"/>
                            <TextInput source="googleTagManagerId"/>
                            <TextInput source="usercentricsId"/>
                            <EnumInput name="CheckoutModuleType"
                                       onChange={(e) => setCheckoutModule(e.target.value)}
                                       source="checkoutModule"/>
                            <EnumInput name="CartStorageMode" source="cartStorageMode"/>
                        </FormTab>
                        <FormTab label={`resources.${Resources.Partner}.tabs.seo`}>
                            <TextInput source="title"/>
                            <TextInput source="description" fullWidth/>
                            <TextInput source="descriptionExpression" fullWidth/>
                            <TextInput source="titleExpression" fullWidth/>
                        </FormTab>
                        <FormTab label={`resources.${Resources.Partner}.tabs.contact`}>
                            <TextInput source="contactName"/>
                            <TextInput source="contactMail"/>
                            <TextInput source="contactPhone"/>
                        </FormTab>
                        <FormTab label={`resources.${Resources.Partner}.tabs.hosts`}>
                            <TextInput source="host"/>
                            <TextInput source="mediaHost"/>
                            <TextInput source="staticHost"/>
                            <TextInput source="imageHost"/>
                        </FormTab>
                        {
                            checkoutModule === "OCI" ? (
                                < FormTab label={`resources.${Resources.Partner}.tabs.ociMapping`}>
                                    <OciMappingInput source="ociMappings" fullWidth/>
                                </FormTab>
                            ) : null
                        }
                    </TabbedForm>
                </ServerSideValidatedForm>
            </Edit>
        </Localized>
    );
};
