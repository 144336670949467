import React, {FC} from "react";
import {SelectArrayInput, SelectArrayInputProps, SelectInput, SelectInputProps} from "react-admin";
import {useEnumChoices} from "../Enum/Enum";

type EnumInputProps = SelectInputProps & SelectArrayInputProps & {
    /**
     * The name of the enum type. The enum type must be present in the AppContext's IntrospectionSchema and must be
     * a "ENUM" type.
     */
    name: string

    /**
     * When multiple is true, the user cans elect multiple enum values.
     */
    multiple?: boolean
}

/**
 * EnumInput gives the user a choice to select an enum value. The component tries to get the available choices from
 * the AppContext's schema. In the schema it looks for a type that has the same name as the provided `name` prop.
 * The Component will throw an exception when the provided type does not exist or is not a ENUM type.
 *
 * @param props The Props object
 * @returns React.ReactElement
 * @throws InvalidPropsError
 */
export const EnumInput: FC<EnumInputProps> = (props) => {
    const {multiple, name, source, resource, ...rest} = props;
    const choices = useEnumChoices(name);

    if (multiple) {
        return <SelectArrayInput
            {...rest}
            source={source}
            resource={resource}
            choices={choices}
            translateChoice={false}/>;
    } else {
        return <SelectInput
            {...rest}
            source={source}
            resource={resource}
            choices={choices}
            translateChoice={false}/>;
    }
};