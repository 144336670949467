import {useQuery, useTranslate} from "react-admin";
import {Resources} from "../../Resources";
import {LoadingIndicator} from "ra-ui-materialui/lib/layout";
import {MenuItem, Select} from "@material-ui/core";
import React, {useState} from "react";
import {Labeled} from "ra-ui-materialui";
import {FlagIcon, FlagIconCode} from "react-flag-kit";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
    onChange?: (code: string) => void
}

const FLAG_ALIASES: { [key: string]: FlagIconCode } = {
    en: "GB",
};

const useStyles = makeStyles({
    menuItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    languageIcon: {
        marginRight: ".35em",
    },
    selectRoot: {
        "& img": {
            marginTop: ".45em",
            marginLeft: ".45em",
            width: 15,
            height: 15,
        },
    },
});

/**
 * Displays a language select to the user. The languages are fetched from the GraphQL Language resource.
 *
 * @param props The react props
 * @returns React.ReactElement
 */
export const LanguageSelect = (props: Props): React.ReactElement => {
    const translate = useTranslate();
    const [language, setLanguage] = useState("de");
    const classes = useStyles();

    const {data, loading} = useQuery({
        type: "getList",
        resource: Resources.Language,
        payload: {},
    });

    const getIconCode = (code: string): FlagIconCode => {
        return (code in FLAG_ALIASES ? FLAG_ALIASES[code] : code.toUpperCase()) as FlagIconCode;
    };

    const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const code = e.target.value as string;
        setLanguage(code);

        if (props.onChange) {
            props.onChange(code);
        }
    };

    if (loading) {
        return <LoadingIndicator/>;
    }

    return (
        <Labeled label={translate(`resources.${Resources.Language}.name`, {smart_count: 1})}>
            <Select value={language}
                    classes={{root: classes.selectRoot}}
                    {...props}
                    onChange={(e) => onChange(e)}
            >
                {
                    data.map(({code, name, id}: { code: string, name: string, id: string }) => (
                        <MenuItem value={code} key={id} className={classes.menuItem}>
                            <FlagIcon code={getIconCode(code)} className={classes.languageIcon} size={16}/>
                            {name}
                        </MenuItem>
                    ))
                }
            </Select>
        </Labeled>
    );
};