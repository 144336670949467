import {FC, memo} from "react";
import {FieldProps, useRecordContext, useTranslate} from "react-admin";
import get from "lodash/get";
import {Typography} from "@material-ui/core";
import {
    FaExchangeAlt,
    FaMoneyBillAlt,
    FaMoneyCheck,
    FaMoneyCheckAlt,
    FaPaypal,
    FaPiggyBank,
    FaQuestion,
    FaRegCreditCard,
} from "react-icons/fa";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    font: {
        marginRight: ".5em",
    },
}));

/**
 * Formats the field value as Payment.
 *
 * @param props The Payment props.
 * @class
 */
export const PaymentField: FC<FieldProps> = memo((props) => {
    const {source} = props;
    const record = useRecordContext(props);
    const translate = useTranslate();
    const classes = useStyles();

    if (!source) {
        return null;
    }

    const getContent = () => {
        const value = get(record, source);

        switch (value) {
            case ".payment_bill":
            case "bill_payment":
            case "invoice.invoice":
                return (
                    <div className={classes.root}>
                        <FaMoneyBillAlt className={classes.font} size={21}/>
                        <Typography display="inline">{translate("payment.bill")}</Typography>
                    </div>
                );
            case "cash.cash":
            case "paypal_payment":
            case "paypal.paypal":
            case ".payment_paypal":
                return (
                    <div className={classes.root}>
                        <FaPaypal className={classes.font} size={21}/>
                        <Typography display="inline">{translate("payment.paypal")}</Typography>
                    </div>
                );
            case ".payment_creditcard":
            case "credit_card_payment":
                return (
                    <div className={classes.root}>
                        <FaRegCreditCard className={classes.font} size={21}/>
                        <Typography display="inline">{translate("payment.creditCard")}</Typography>
                    </div>
                );
            case ".payment_debits":
            case "debits_payment":
                return (
                    <div className={classes.root}>
                        <FaMoneyCheckAlt className={classes.font} size={21}/>
                        <Typography display="inline">{translate("payment.creditCard")}</Typography>
                    </div>
                );
            case "banktransfer.banktransfer":
                return (
                    <div className={classes.root}>
                        <FaExchangeAlt className={classes.font} size={21}/>
                        <Typography display="inline">{translate("payment.banktransfer")}</Typography>
                    </div>
                );
            case "moneyorder.moneyorder":
            case "moneyorder_paypal.moneyorder_pay":
                return (
                    <div className={classes.root}>
                        <FaPiggyBank className={classes.font} size={21}/>
                        <Typography display="inline">{translate("payment.moneyOrder")}</Typography>
                    </div>
                );
            case "in_advance_payment":
            case ".payment_in_advance":
                return (
                    <div className={classes.root}>
                        <FaMoneyCheck className={classes.font} size={21}/>
                        <Typography display="inline">{translate("payment.inAdvance")}</Typography>
                    </div>
                );
        }
        return (
            <div className={classes.root}>
                <FaQuestion className={classes.font} size={21}/>
                <Typography display="inline">{translate("payment.unknown")}</Typography>
            </div>
        );
    };

    return <>{getContent()}</>;
});