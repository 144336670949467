import {SearchInput, useTranslate} from "react-admin";
import {Filter} from "ra-ui-materialui";
import React, {FC} from "react";

export type DefaultFilterProps = {
    searchPlaceholder?: string
}

export const DefaultFilter: FC<DefaultFilterProps> = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SearchInput
                placeholder={translate(props.searchPlaceholder ?? "general.search")}
                source="q"
                alwaysOn
            />
            {props.children}
        </Filter>
    );
};

export const DefaultFiltersArray = [
    <SearchInput label="general.search" source="q" alwaysOn/>,
];