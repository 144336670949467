import {LinkProps} from "react-router-dom";

export type BreadcrumbsActions =
    { type: "OVERRIDE_PATH", resource: string, crumbs: Crumb[] };

export type Crumb = Pick<LinkProps, "title" | "to"> & { label: string };

export interface BreadcrumbsState {
    /**
     * A map of overridden resources mapped to their crumbs. When a resource crumb is overriden
     * the crumbs of the override are displayed instead of the default crumbs.
     */
    overrides: Record<string, Crumb[]>
}

export const InitialState: BreadcrumbsState = {
    overrides: {},
};

/**
 * The reduce function is the BreadcrumbsState redux reducer.
 *
 * @param state The previous state
 * @param action The action
 * @returns The new state
 */
export function reduce(state: BreadcrumbsState = InitialState, action: BreadcrumbsActions): BreadcrumbsState {
    switch (action.type) {
        case "OVERRIDE_PATH": {
            return {...state, overrides: {...state.overrides, ...{[action.resource]: [...action.crumbs]}}};
        }
    }
    return state;
}