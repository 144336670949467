import {SchemaFetcherInterface} from "./SchemaFetcherInterface";
import {getIntrospectionQuery, IntrospectionSchema} from "graphql";
import {ApolloClient} from "@apollo/client";
import gql from "graphql-tag";
import {Result} from "../Result";
import {RuntimeError} from "../Error/RuntimeError";
import {sprintf} from "sprintf-js";
import {NormalizedCacheObject} from "apollo-cache-inmemory";

export class SchemaFetcher implements SchemaFetcherInterface {
    private client: ApolloClient<NormalizedCacheObject>;

    constructor(client: ApolloClient<NormalizedCacheObject>) {
        this.client = client;
    }

    async fetch(): Promise<Result<IntrospectionSchema, RuntimeError>> {
        try {
            const {data: {__schema}} = await this.client.query({
                fetchPolicy: "network-only",
                query: gql`
                    ${getIntrospectionQuery()}
                `,
            });

            return __schema;
        }
        catch (e) {
            if (e instanceof Error) {
                const msg = sprintf("failed to fetch schema: %s", e.message);
                return new RuntimeError(msg, e);
            }
            throw e;
        }
    }
}