import React from "react";
import {useDispatch, useStore} from "react-redux";
import {AnyAction, Dispatch, Store} from "redux";

type StoreConsumerFn = (store: Store, dispatch: Dispatch<AnyAction>) => React.ReactElement | void;

type Props = { children: StoreConsumerFn };

/**
 * The StoreConsumer component provides the caller with the store and dispatch function from redux.
 *
 * @param props The react props
 * @class
 */
export const StoreConsumer = (props: Props): React.ReactElement => {
    const store = useStore();
    const dispatch = useDispatch();

    return (
        <>
            {
                props.children(store, dispatch)
            }
        </>
    );
};