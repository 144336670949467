import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {AcceptProp, FilePicker} from "./FilePicker";
import {InputProps, useGetOne, useInput, useTranslate} from "react-admin";
import {Box, Button, IconButton, Typography} from "@material-ui/core";
import {Resources} from "../../Resources";
import {Entry} from "./Manager/Entry";
import {AttachFile, Delete} from "@material-ui/icons";
import {ellipsis} from "../../Strings";
import path from "path";
import {omit} from "lodash";

export interface FilePickerInputProps extends InputProps {
    accept?: AcceptProp
}

export const FilePickerInput: FC<FilePickerInputProps> = ({accept, ...props}) => {
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const [entry, setEntry] = useState<Entry>();
    const {input: {onChange, value}} = useInput(props);
    const {data} = useGetOne(Resources.File, value);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleSelect = useCallback((entry: Entry) => {
        setEntry(entry);
        setOpen(false);

        onChange(entry.path);
    }, [onChange]);

    const initialPath = useMemo(() => {
        const dirname = path.dirname(value);
        if (dirname === ".") {
            return "";
        }

        return dirname;
    }, [value]);


    useEffect(() => {
        if (data) {
            setEntry(omit(data, "id") as Entry);
        }
    }, [data]);

    return (
        <Box display="flex" gridGap={10} alignItems="center">
            <Button onClick={() => setOpen(true)} variant="contained" color="primary" size="small">
                <AttachFile/>&nbsp;
                {translate(`resources.${Resources.File}.actions.select`)}
            </Button>

            {entry ? <Typography variant="body2" title={entry.name}>{ellipsis(entry.path, 32)}</Typography> : null}

            {entry ? <IconButton onClick={() => setEntry(undefined)}><Delete/></IconButton> : null}

            <FilePicker
                accept={accept}
                open={open}
                onClose={handleClose}
                onSelect={handleSelect}
                initialPath={initialPath}
            />
        </Box>
    );
};