import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    EditProps,
    FormTab,
    ReferenceInput,
    SimpleFormIterator,
    TabbedForm,
    TextField,
} from "ra-ui-materialui";
import {ReferenceField, useEditController} from "react-admin";
import {Resources} from "../../Resources";
import {Localized} from "../Language/Localized";
import {If} from "../If";
import React from "react";
import {FilePickerInput} from "../File/FilePickerInput";
import {useResourceTranslate} from "../../I18n/useResourceTranslate";
import {Box, Typography} from "@material-ui/core";

export const CategoryEdit = (props: EditProps): React.ReactElement => {
    const {record} = useEditController(props);
    const translate = useResourceTranslate(Resources.Category);

    return <Localized {...props}>
        <Edit {...props}>
            <TabbedForm>
                <FormTab label={`resources.${Resources.Category}.tabs.general`}>
                    <TextField source="name"/>
                    <TextField source="url"/>
                    <TextField source="slug"/>
                    <BooleanInput source="containsIndividualProducts"
                                  helperText={translate("fields.containsIndividualProductsHelp")}
                                  fullWidth/>

                    <If expr={record?.parent?.id}>
                        <Box display="flex" flexDirection="column" mt={3}>
                            <Typography variant="body1" color="textSecondary">
                                {translate("fields.parent")}
                            </Typography>
                            <ReferenceField
                                reference={Resources.Category}
                                source="parent.id"
                            >
                                <TextField source="name"/>
                            </ReferenceField>
                        </Box>
                    </If>
                </FormTab>
                <FormTab label={`resources.${Resources.Category}.tabs.relatedCategories`}>
                    <ArrayInput source="relatedCategories">
                        <SimpleFormIterator>
                            <ReferenceInput
                                source="id"
                                reference={Resources.Category}
                                label={`resources.${Resources.Category}.fields.relatedCategory`}
                            >
                                <AutocompleteInput optionText="slug"/>
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={`resources.${Resources.Category}.tabs.pinnedProducts`}>
                    <ArrayInput source="pinnedProducts">
                        <SimpleFormIterator>
                            <ReferenceInput
                                source="id"
                                reference={Resources.Product}
                                filter={{category: props.id, isChild: false}}
                                label={`resources.${Resources.Category}.fields.productModel`}
                            >
                                <AutocompleteInput source="model" optionText="model"/>
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={`resources.${Resources.Category}.tabs.pinnedProductGroups`}>
                    <ArrayInput source="pinnedProductGroups">
                        <SimpleFormIterator>
                            <ReferenceInput
                                source="id"
                                reference={Resources.ProductGroup}
                                filter={{category: props.id}}
                                label={`resources.${Resources.Category}.fields.productModel`}
                            >
                                <AutocompleteInput source="model" optionText="model"/>
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={`resources.${Resources.Category}.tabs.images`}>
                    <ArrayInput source="imageIds">
                        <SimpleFormIterator>
                            <FilePickerInput accept={["image/*"]} source=""/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    </Localized>;
};