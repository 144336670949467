import {LanguageActionTypes, SET_RESOURCE_LANGUAGE} from "./Types";
import {Resources} from "../../Resources";
import {DEFAULT_LANGUAGE} from "../../I18n/LocaleContext";

export interface LanguageState {
    resources: Record<string, string>
}

export const InitialState: LanguageState = {
    resources: Object.keys(Resources).reduce((acc: Record<string, string>, resource) => {
        acc[resource] = DEFAULT_LANGUAGE;
        return acc;
    }, {}),
};

/**
 * The reduce function is the LanguageState redux reducer.
 *
 * @param state The previous state
 * @param action The action
 * @returns The new state
 */
export function reduce(state: LanguageState = InitialState, action: LanguageActionTypes): LanguageState {
    switch (action.type) {
        case SET_RESOURCE_LANGUAGE:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    [action.payload.resource]: action.payload.language,
                },
            };
    }
    return state;
}