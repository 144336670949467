import React from "react";
import {
    BooleanInput,
    Create,
    FormTab,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextInput,
} from "react-admin";
import {Resources} from "../../Resources";
import {EnumInput} from "../EnumInput";
import {ExpressionInput} from "../Expression/ExpressionInput";
import {ExpressionNodeType} from "../../GraphQL/Generated";
import {CreateProps} from "ra-ui-materialui";
import {PartnerSelectInput} from "../Partner/PartnerSelectInput";

export const ShippingCreate = (props: CreateProps): React.ReactElement => (
    <Create {...props}>
        <TabbedForm>
            {/* General Settings */}
            <FormTab label={`resources.${Resources.Shipping}.tabs.general`}>
                <TextInput
                    source="name"
                    validate={required()}
                />
                <TextInput
                    source="model"
                    validate={required()}
                />
                <ReferenceInput
                    reference={Resources.ShippingModule}
                    source="shippingModule.id"
                    validate={required()}
                >
                    <SelectInput optionText="title"/>
                </ReferenceInput>
                <EnumInput
                    source="autoAppend"
                    name="ShippingAppendMode"
                    validate={required()}
                />
                <BooleanInput
                    source="selectable"
                    defaultValue={false}
                />
                <BooleanInput
                    source="combinable"
                    defaultValue={false}
                />
                <TextInput
                    source="comment"
                    options={{multiline: true, rows: 5}}
                    fullWidth
                    defaultValue={""}
                />

                <PartnerSelectInput validate={required()} fullWidth />
            </FormTab>

            {/* Constraints / Einschränkungen */}
            <FormTab label={`resources.${Resources.Shipping}.tabs.constraints`}>
                <ExpressionInput
                    fullWidth
                    source="constraints"
                    type={ExpressionNodeType.ShoppingCart}
                    defaultValue={[]}
                />
            </FormTab>
        </TabbedForm>
    </Create>
);