import {FC} from "react";
import {FieldProps, useRecordContext} from "react-admin";
import get from "lodash/get";
import {Link} from "@material-ui/core";

/**
 * Displays a value as a phone number.
 *
 * @param props The PhoneNumberField props.
 */
export const PhoneNumberField: FC<FieldProps> = (props) => {
    const {source, ...rest} = props;
    const record = useRecordContext(props);

    if (!source) {
        return null;
    }

    const value = get(record, source);
    return <Link {...rest} href={`tel:${value}`}>{value}</Link>;
};