import * as React from "react";
import {FC} from "react";
import {Layout as RaLayout, LayoutProps} from "react-admin";
import omit from "lodash/omit";
import {Breadcrumb} from "../../Components/Breadcrumb/Breadcrumb";
import {Menu} from "../../Components/Menu/Menu";
import {AppBar} from "./AppBar";

export const Layout: FC<LayoutProps> = (props) => {
    return (
        <RaLayout
            {...omit(props, ["children"])}
            menu={Menu}
            appBar={AppBar}
        >
            <Breadcrumb/>

            {props.children}
        </RaLayout>
    );
};