import React, {FC} from "react";
import {Datagrid, List, TextField} from "ra-ui-materialui";
import {DefaultFilter} from "../DefaultFilter";
import {ChipField, LayoutProps, ReferenceArrayField, SingleFieldList} from "react-admin";
import {Resources} from "../../Resources";

export const FormCustomizerList: FC<LayoutProps> = (props: React.PropsWithoutRef<unknown>): React.ReactElement => (
    <List {...props}
          filters={<DefaultFilter/>}
    >
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <ReferenceArrayField reference={Resources.Product} source="productsIds" sortable={false}>
                <SingleFieldList linkType={false}>
                    <ChipField source="model"
                               size="small"
                               variant="outlined"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="notes"/>
        </Datagrid>
    </List>
);