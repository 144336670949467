import {TokenType} from "./TokenType";
import assert from "assert";
import {sprintf} from "sprintf-js";
import {isBefore, parseJSON} from "date-fns";
import {assertDefined} from "../Assert";

export enum AuthenticationType {
    ClientCredentials,
    PasswordCredentials
}

/**
 * An authentication is the result of the authentication process. The authentication can be used throughout the
 * application to make authenticated requests.
 */
export interface Authentication<T> {
    /**
     * The OAuth grant type
     */
    type: AuthenticationType
    /**
     * The type of the token
     */
    tokenType: TokenType
    /**
     * The result from the authentication process
     */
    data?: T
    /**
     * The expiration date
     */
    expires?: Date
}

/**
 * The parseAuthentication function parses a json encoded authentication. It ensures that the authentication data is
 * valid but doesn't check if the authentication is expired.
 *
 * @param json The raw authentication as a json string
 */
export function parseAuthentication<T>(json: string): Authentication<T> {
    const raw = JSON.parse(json);
    assertDefined(raw["type"], "type");
    assertDefined(raw["expires"], "expires");
    assertDefined(raw["data"], "data");
    assert(
        Object.values(AuthenticationType).includes(raw["type"]),
        sprintf("\"%s\" is not a supported AuthenticationType", raw["type"])
    );
    assert(
        Object.values(TokenType).includes(raw["tokenType"]),
        sprintf("\"%s\" is not a supported TokenType", raw["tokenType"])
    );

    return {
        data: raw["data"],
        expires: parseJSON(raw["expires"]),
        tokenType: raw["tokenType"],
        type: raw["type"],
    };
}

/**
 * The expired function checks and returns true when the Authentication is expired.
 *
 * @param auth The Authentication
 */
export function expired(auth: Authentication<unknown>) : boolean {
    assertDefined(auth.expires, "expires");
    return isBefore(auth.expires, new Date());
}