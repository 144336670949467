import {Result} from "../../Result";
import {Authentication} from "../Authentication";
import {sprintf} from "sprintf-js";
import {AuthenticationError} from "../Error/AuthenticationError";
import {TokenType} from "../TokenType";
import {Credentials} from "../Credentials";
import {addSeconds} from "date-fns";

export abstract class HttpAuthenticator<T> {
    async authenticateRequest(uri: string, credentials: Credentials)
  : Promise<Result<Omit<Authentication<T>, "type">>> {
        try {
            const res = await this.fetch(uri, credentials);

            if (res.status < 200 || res.status >= 300) {
                const msg = sprintf("status = %d; expected a status between 200 and 300", res.status);
                return new AuthenticationError(msg);
            }

            const json = await res.json();

            if (!json["access_token"]) {
                return new AuthenticationError("response does not contain a access_token field");
            }

            if (!json["expires_in"]) {
                return new AuthenticationError("response does not contain a expires_in field");
            }

            const accessToken = json["access_token"];
            const expires = addSeconds(new Date(), parseInt(json["expires_in"]));
            return {
                tokenType: TokenType.BearerToken,
                data: accessToken,
                expires,
            };
        } catch (e) {
            if (e instanceof Error) {
                return AuthenticationError.createForAuthenticationAttempt(uri, e);
            }
            throw e as Error;
        }
    }

    protected abstract fetch(uri: string, credentials: Credentials): Promise<Response>;
}