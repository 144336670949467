import {makeStyles} from "@material-ui/core/styles";
import {sprintf} from "sprintf-js";
import React, {CSSProperties} from "react";
import {Rectangle} from "../../Geometry/Rectangle";

type CircleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & Partial<Rectangle>;

const useStyles = makeStyles((theme) => ({
    root: ({width = "1em", height = "1em"}: CSSProperties) => (
        {
            border: sprintf(".1em solid %s", theme.palette.grey.A100),
            borderRadius: "50%",
            display: "flex",
            height,
            overflow: "hidden",
            width,
        }
    ),
}));

/**
 * Circle its children in a round container.
 *
 * @param props The CircleProps
 * @class
 */
export const Circle = (props: CircleProps): React.ReactElement | null => {
    const classes = useStyles({width: props.width, height: props.height});

    return (
        <div className={classes.root} {...props}>
            {props.children}
        </div>
    );
};