import React, {FC} from "react";
import {Datagrid, List, ListProps} from "react-admin";
import {DefaultFilter} from "../DefaultFilter";
import {SettingField} from "./SettingField";

export const SettingList: FC<ListProps> = (props) => (
    <List
        {...props}
        filters={<DefaultFilter/>}
        bulkActionButtons={false}
        pagination={false}
        exporter={false}
    >
        <Datagrid >
            <SettingField/>
        </Datagrid>
    </List>
);