import {Crumb} from "../../../Redux/Breadcrumbs";
import {Resources} from "../../../Resources";
import {useBreadcrumb} from "../../Breadcrumb/Hook";
import {useCallback} from "react";

export const useFilePathBreadcrumb = (): (routePath: string) => void => {
    const {setPath} = useBreadcrumb();

    return useCallback((routePath: string) => {
        const segments = routePath.split("/");
        const crumbs: Crumb[] = segments.map((seg, i) => ({
            to: `/${Resources.File}/` + segments.slice(0, i).join("/"),
            title: seg,
            label: seg,
        }));

        setPath(Resources.File, crumbs);
    }, [setPath]);
};