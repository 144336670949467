import {ChangeEvent, FC, FormEvent, useCallback, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import {useTranslate} from "react-admin";
import {Resources} from "../../../Resources";

export interface CreateDirectoryDialogProps {
    /**
     * Whether the dialog should be visible.
     */
    open: boolean

    /**
     * The current path. The directory will be created under this path.
     */
    currentPath: string

    /**
     * onCreate is called when the user has submitted the form dialog and
     * wants to create a folder.
     */
    onCreate(name: string): void

    /**
     * onClose is called when the dialog is closed.
     */
    onClose(): void
}

const validatePath = (path: string) => {
    return path.length > 0 && path.length <= 255 && !/[\^/>|~:&]|\.{2,}/.test(path);
};

export const CreateDirectoryDialog: FC<CreateDirectoryDialogProps> = ({currentPath, open, onCreate, onClose}) => {
    const translate = useTranslate();
    const [name, setName] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const onSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
        if (name && !error) {
            onCreate(name);
        }
    }, [name, error, onCreate]);

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;

        if (!validatePath(value)) {
            setError(translate(`resources.${Resources.File}.directory.createDialog.error`, {
                name: value,
            }));
            return;
        }

        setError(null);
        setName(event.target.value);
    }, [onCreate]);

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>{translate(`resources.${Resources.File}.directory.createDialog.title`)}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {translate(`resources.${Resources.File}.directory.createDialog.subTitle`, {
                        path: currentPath,
                    })}
                </DialogContentText>
                <form onSubmit={onSubmit} noValidate>
                    <TextField
                        autoFocus
                        error={error !== null}
                        fullWidth
                        helperText={error}
                        id="name"
                        label={translate(`resources.${Resources.File}.directory.createDialog.name`)}
                        margin="dense"
                        onChange={onChange}
                        type="text"
                        variant="outlined"
                    />
                </form>
            </DialogContent>

            <DialogActions>
                <Button color="primary" onClick={() => onClose()}>
                    {translate("actions.cancel")}
                </Button>
                <Button color="primary" onClick={onSubmit} disabled={!name || error !== null}>
                    {translate(`resources.${Resources.File}.directory.actions.create`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};