import {LanguageActionTypes, SET_RESOURCE_LANGUAGE} from "./Types";

/**
 * The setResourceLanguage action creator creates a SET_RESOURCE_LANGUAGE action.
 *
 * @param resource The resource that the language was set for
 * @param language The language to set
 * @returns LanguageActionTypes
 */
export function setResourceLanguage(resource: string, language: string): LanguageActionTypes {
    return {
        type: SET_RESOURCE_LANGUAGE,
        payload: {
            resource,
            language,
        },
    };
}
