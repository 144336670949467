import buildGraphQLProvider, {buildQuery} from "ra-data-graphql-simple";
import {visit} from "graphql";
import {LanguageVisitor} from "../GraphQL/Schema/AST/Visitors/LanguageVisitor";
import {ManyDefaultFilterInputFixVisitor} from "../GraphQL/Schema/AST/Visitors/ManyDefaultFilterInputFixVisitor";
import {Resources} from "../Resources";
import gql from "graphql-tag";
import getResponseParser from "ra-data-graphql-simple/lib/getResponseParser";

/* eslint-disable */

/**
 * The `factory` creates a GraphQLProvider.
 * {@see https://github.com/marmelab/react-admin/tree/master/packages/ra-data-graphql-simple#overriding-a-specific-query}
 *
 * @param options The options passed to ra-data-graphql-simple `buildGraphQLProvider` function
 * @param {AppContextProps} context The options passed to ra-data-graphql-simple `buildGraphQLProvider` function
 * @returns
 */
const factory = (options, context) => {
    /**
     * @type VisitorFactory[]
     */
    const visitors = [
        new LanguageVisitor(context),
        new ManyDefaultFilterInputFixVisitor(),
    ]

    const myBuildQuery = (introspection) => (fetchType, resource, params) => {
        let builtQuery
        if (fetchType === "CREATE" && resource === Resources.File && !params.data?.isDirectory) {
            const data = params.data;
            if (!data) {
                throw new Error("payload must contain a `data` property containing the payload data");
            }

            if (!(data.file instanceof File)) {
                throw new Error("file is not a File")
            }

            if (typeof data.path !== "string") {
                throw new Error("path is not a string")
            }

            builtQuery = {
                query: gql`mutation createFile($file: Upload!, $path: String!) {
                    data: createFile(file: $file, path: $path) {
                        id
                        mimeType
                        name
                        path
                        url
                        size
                        url
                        creationDate
                        lastModificationDate
                        __typename
                    }
                }`,
                variables: {
                    file: data.file,
                    path: data.path
                },
                parseResponse: getResponseParser(introspection)(fetchType)
            }
        } else {
            builtQuery = buildQuery(introspection)(fetchType, resource, params);

            const activeVisitors = visitors.filter((v) => v.canHandle(fetchType, resource, params));
            if (activeVisitors.length > 0) {
                builtQuery.query = activeVisitors.reduce((acc, v) => {
                    return visit(acc, v.create())
                }, builtQuery.query);

                activeVisitors.forEach((v) => v.modifyVariables(builtQuery.variables, resource, params));
            }
        }

        return builtQuery;
    };

    return buildGraphQLProvider({...options, buildQuery: myBuildQuery});
};

export default factory;