import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useDeleteMany, useGetList, useMutation} from "react-admin";
import {Resources} from "../../Resources";
import {Manager} from "./Manager/Manager";
import {Entry} from "./Manager/Entry";
import {useHistory, useParams} from "react-router";
import path from "path";
import {useErrorHandler} from "./ErrorHandler";
import {AcceptProp} from "./FilePicker";
import {useFilePathBreadcrumb} from "./Hooks/useFilePathBreadcrumb";

export enum FileListMode {
    Manager,
    Picker
}

export interface FileListProps {
    mode?: FileListMode
    onPick?: (entry: Entry) => void
    initialPath?: string
    accept?: AcceptProp
}

export const FileList: FC<FileListProps> = (props) => {
    const {accept, mode = FileListMode.Manager, onPick, initialPath = ""} = props;
    const history = useHistory();
    const {id: pathFromRoute} = useParams<{ id: string }>();
    const [pathFromState, setPathFromState] = useState<string>(initialPath);
    const handleError = useErrorHandler();
    const setPath = useFilePathBreadcrumb();

    const routePath = useMemo(() => {
        const p = mode === FileListMode.Manager ? pathFromRoute : pathFromState;
        return p ?? "";
    }, [mode, pathFromState, pathFromRoute]);

    const {data, loading, refetch} = useGetList(
        Resources.File,
        undefined,
        undefined,
        {accept, path: routePath}
    );
    const [deleteMany] = useDeleteMany(undefined, undefined, {
        returnPromise: true,
    });
    const [mutate] = useMutation(undefined, {
        returnPromise: true,
    });

    useEffect(() => setPath(routePath), [setPath, routePath]);

    const files = useMemo(function filesMemo() {
        if (!data) {
            return [];
        }

        return Object.values(data)
            .map((record) => {
                const {name, path, isDir, size, creationDate, lastModificationDate, url, mimeType} = record;

                return {
                    creationDate: creationDate ? new Date(creationDate) : null,
                    isDir,
                    lastModificationDate: creationDate ? new Date(lastModificationDate) : null,
                    mimeType,
                    name,
                    path,
                    size,
                    url: url ?? null,
                } as Entry;
            })
            .sort((a) => a.isDir ? -1 : 1);
    }, [data]);

    const onOpen = useCallback(function onOpen(entry: Entry) {
        if (entry.isDir) {
            const newPath = `/File/${entry.path}`;
            if (mode === FileListMode.Manager) {
                history.push(newPath);
            } else {
                setPathFromState(entry.path);
            }
        } else if (onPick) {
            onPick(entry);
        }
    }, []);

    const onDelete = useCallback(async function onDelete(entries: Entry[]) {
        try {
            await deleteMany(Resources.File, entries.map((e) => e.path), {
                returnPromise: true,
            });
        } catch (e) {
            handleError(e);
        } finally {
            refetch();
        }
    }, [deleteMany, refetch, handleError]);

    const onUpload = useCallback(async function onUpload(file: File) {
        try {
            await mutate({
                type: "create",
                resource: Resources.File,
                payload: {data: {file, path: routePath, isDirectory: false}},
            }, {
                returnPromise: true,
                mutationMode: "pessimistic",
            });
        } catch (e) {
            handleError(e);
        } finally {
            refetch();
        }
    }, [routePath, refetch, mutate, handleError]);

    const onCreateDirectory = useCallback(async function onCreateDirectory(name: string) {
        try {
            const dirPath = path.join(routePath, name);
            await mutate({
                type: "create",
                resource: Resources.File,
                payload: {data: {path: dirPath, isDirectory: true}},
            }, {
                returnPromise: true,
            });
        } catch (e) {
            handleError(e);
        } finally {
            refetch();
        }
    }, [refetch, routePath, mutate, handleError]);

    const onMove = useCallback(async function onMove(from: string, to: string) {
        try {
            await mutate({
                type: "update",
                resource: Resources.File,
                payload: {data: {id: from, path: to}},
            }, {
                returnPromise: true,
            });
        } catch (e) {
            handleError(e);
        } finally {
            refetch();
        }
    }, [refetch, mutate]);

    const onEdit = useCallback(async (entry: Entry) => {
        history.push(`/${Resources.File}/${entry.path}/edit`);
    }, []);

    return (
        <Manager
            accept={accept}
            currentPath={routePath ?? "/"}
            entries={files}
            loading={loading}
            onCreateDirectory={onCreateDirectory}
            onDelete={onDelete}
            onEditEntry={onEdit}
            onMove={onMove}
            onOpen={onOpen}
            onUpload={onUpload}
        />
    );
};