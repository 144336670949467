import {AssertionError} from "assert";

/**
 * Asserts that value is not undefined and throws an AssertionError when it is undefined. An error message will
 * be generated using the name of the variable.
 *
 * @param val The value to check
 * @param name An optional name of the variable to use for the error message
 * @throws AssertionError
 */
export function assertDefined<T>(val: T, name?: string): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        throw new AssertionError({
            message: `Expected '${name || "val"}' to be defined, but received ${val}`,
            actual: typeof val,
        });
    }
}