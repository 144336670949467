import {FC} from "react";
import {Entry} from "./Entry";
import {useTranslate} from "react-admin";
import {Resources} from "../../../Resources";
import {Box, Link, Paper, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import prettyBytes from "pretty-bytes";
import {useLocaleContext} from "../../../I18n/LocaleContext";
import {OpenInNew} from "@material-ui/icons";

interface EntryDetailsProps {
    entry: Entry
}

const useStyles = makeStyles(() => ({
    image: {
        maxHeight: 300,
        maxWidth: "100%",
        justifySelf: "center",
    },
    imageContainer: {
        marginTop: 14,
        marginBottom: 14,
        overflow: "hidden",
    },
    link: {
        display: "flex",
        alignItems: "center",
    },
    mimeType: {
        marginBottom: 14,
    },
    title: {
        fontSize: 14,
    },
}));

export const EntryDetails: FC<EntryDetailsProps> = ({entry}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const {dateFormatter} = useLocaleContext();

    if (entry.isDir) {
        return null;
    }

    let preview = null;
    if (entry.mimeType !== undefined) {
        const category = entry.mimeType.slice(0, entry.mimeType.indexOf("/"));
        switch (category) {
            case "image":
                preview = (
                    <Paper variant="outlined" className={classes.imageContainer}>
                        <Box display="flex" justifyContent="center" p={2}>
                            <img src={entry.url} className={classes.image}/>
                        </Box>
                    </Paper>
                );
                break;
        }
    }

    return (
        <>
            <Typography color="textSecondary" gutterBottom className={classes.title}>
                {translate(`resources.${Resources.File}.details.title`)}
            </Typography>
            <Typography variant="h5" component="h2">
                {entry.name}
            </Typography>
            <Typography color="textSecondary" className={classes.mimeType}>
                {entry.mimeType}
            </Typography>

            {preview}

            <Table size="small">
                <TableBody>
                    {
                        entry.size ?
                            <TableRow>
                                <TableCell>
                                    {translate(`resources.${Resources.File}.fields.size`)}
                                </TableCell>
                                <TableCell>
                                    {prettyBytes(entry.size)}
                                </TableCell>
                            </TableRow>
                            :
                            null
                    }

                    {
                        entry.lastModificationDate ?
                            <TableRow>
                                <TableCell>
                                    {translate(`resources.${Resources.File}.fields.modified`)}
                                </TableCell>
                                <TableCell>
                                    {dateFormatter.format(entry.lastModificationDate)}
                                </TableCell>
                            </TableRow>
                            :
                            null
                    }

                    {
                        entry.creationDate ?
                            <TableRow>
                                <TableCell>
                                    {translate(`resources.${Resources.File}.fields.created`)}
                                </TableCell>
                                <TableCell>
                                    {dateFormatter.format(entry.creationDate)}
                                </TableCell>
                            </TableRow>
                            :
                            null
                    }

                    {
                        entry.path ?
                            <TableRow>
                                <TableCell>
                                    {translate(`resources.${Resources.File}.fields.path`)}
                                </TableCell>
                                <TableCell>
                                    {entry.path}
                                </TableCell>
                            </TableRow>
                            :
                            null
                    }

                    {
                        entry.url ?
                            <TableRow>
                                <TableCell>
                                    {translate(`resources.${Resources.File}.fields.url`)}
                                </TableCell>
                                <TableCell>
                                    <div>
                                        <Link href={entry.url} target="_blank" className={classes.link}>
                                            {translate(`resources.${Resources.File}.actions.download`)}
                                            &nbsp;
                                            <OpenInNew spacing={2} fontSize="inherit" />
                                        </Link>
                                    </div>
                                </TableCell>
                            </TableRow>
                            :
                            null
                    }
                </TableBody>
            </Table>
        </>
    );
};