import React from "react";
import {Datagrid, List, TextField} from "ra-ui-materialui";
import {DefaultFilter} from "../DefaultFilter";
import {Resources} from "../../Resources";
import {ReferenceArrayField, SingleFieldList} from "react-admin";
import {PartnerIconField} from "../Partner/PartnerIconField";
import {EnumField} from "../Field/EnumField";

export const RedirectList = (props: React.PropsWithoutRef<unknown>): React.ReactElement => (
    <List {...props}
          filters={<DefaultFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="path"/>
            <EnumField source="statusCode" name="RedirectStatusCode" addLabel={false}/>
            <TextField source="target"/>
            <ReferenceArrayField reference={Resources.Partner} source="partnerIds">
                <SingleFieldList>
                    <PartnerIconField/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);