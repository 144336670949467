import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {BreadcrumbsActions, Crumb} from "../../Redux/Breadcrumbs";
import {Dispatch} from "redux";

export interface BreadcrumbsHook {
    setPath: (resource: string, path: Crumb[]) => void
}

export const useBreadcrumb = (): BreadcrumbsHook => {
    const dispatch: Dispatch<BreadcrumbsActions> = useDispatch();

    return {
        setPath: useCallback((resource, crumbs) => {
            dispatch({type: "OVERRIDE_PATH", resource, crumbs});
        }, [dispatch]),
    };
};