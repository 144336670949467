import * as React from "react";
import {FC, memo, useMemo} from "react";
import {FieldProps, useRecordContext, FunctionField} from "react-admin";
import compact from "lodash/compact";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Address} from "../../GraphQL/Generated";

const useStyles = makeStyles({
    root: {
        margin: 0,
    },
});

/**
 * The AddressField displays an address field as a formatted string.
 *
 * @param props The AddressField props
 */
export const AddressField: FC<FieldProps> = memo<FieldProps>((props) => {
    const {source} = props;
    const record = useRecordContext(props);
    const classes = useStyles();

    if (!record) {
        return null;
    }

    const rawAddress: Address = (source ? record[source] : record) as Address;

    const address: string = useMemo(() => {
        const {
            streetAddress,
            houseNumber,
            postCode,
            city,
            company1,
            company2,
            companyAddition,
        } = rawAddress;

        const lines = [[
            streetAddress,
            houseNumber,
            postCode,
            city,
        ], [
            company1,
            company2,
            companyAddition,
        ]];

        return lines.map((l) => {
            return compact(l).join(", ");
            }).join("\n");
    }, [rawAddress]);

    return (
        <FunctionField render={() => {
            return <pre className={classes.root}><Typography variant="body2">{address}</Typography></pre>;
        }}/>
    );
});

AddressField.defaultProps = {
    addLabel: true,
};