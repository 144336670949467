import {ArrayInput, Create, CreateProps, SimpleFormIterator, TextInput} from "ra-ui-materialui";
import {FormDataConsumer, FormTab, TabbedForm} from "react-admin";
import React, {ReactElement} from "react";
import {EnumInput} from "../EnumInput";
import {Grid} from "@material-ui/core";
import {Resources} from "../../Resources";
import {assertDefined} from "../../Assert";

export const SearchOptimizationCreate = (props: CreateProps): ReactElement => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label={`resources.${Resources.SearchOptimization}.tabs.query`}>
                <TextInput source="comment" multiline={true} rows={3} fullWidth={true}/>
                <ArrayInput source="queries">
                    <SimpleFormIterator>
                        <TextInput source="query" fullWidth={true} required={true}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label={`resources.${Resources.SearchOptimization}.tabs.optimization`}>
                <ArrayInput source="items">
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({getSource, scopedFormData}) => {
                                assertDefined(getSource);
                                return (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={2}>
                                            <EnumInput
                                                label={`resources.${Resources.SearchOptimization}.fields.type`}
                                                source={getSource("type")}
                                                name="SearchOptimizationItemType"/>
                                        </Grid>
                                        <Grid item xs={12} lg={10}>
                                            <TextInput
                                                label={`resources.${Resources.SearchOptimization}.fields.term`}
                                                source={getSource("term")}/>
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
);