import {useIntrospectionSchema} from "./useIntrospectionSchema";
import {useMemo} from "react";

/**
 * The useIntrospectionSchemaTypeMap hook returns a map of type names mapped to their index in the
 * introspection schema returned by {@link useIntrospectionSchema}.
 */
export function useIntrospectionSchemaTypeMap(): Record<string, number> {
    const schema = useIntrospectionSchema();
    return (
        useMemo(() => {
            if (!schema?.types) {
                return {};
            }

            return schema?.types.reduce((acc, type, i) => {
                acc[type.name] = i;
                return acc;
            }, {} as Record<string, number>);
        }, [schema])
    );
}