export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date */
  Date: any;
  /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
  DateTime: any;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
};

export type Address = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly default: Scalars['Boolean'];
  readonly fullName: Scalars['String'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly streetAddress?: Maybe<Scalars['String']>;
  readonly houseNumber?: Maybe<Scalars['String']>;
  readonly postCode?: Maybe<Scalars['String']>;
  readonly company1?: Maybe<Scalars['String']>;
  readonly company2?: Maybe<Scalars['String']>;
  readonly companyAddition?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly telephoneNumber?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Scalars['String']>;
  readonly customer: Customer;
  readonly lastModificationDate: Scalars['DateTime'];
  readonly creationDate: Scalars['DateTime'];
};

export type ApiResource = {
  /** The name of the resource. */
  readonly name: Scalars['String'];
  /** A list of fields of this resource. */
  readonly operations: ReadonlyArray<Scalars['String']>;
};

export type CancelOrder = {
  readonly orderId: OrderStatus;
  readonly hint: Scalars['String'];
};

export enum CartStorageMode {
  Session = 'SESSION',
  Customer = 'CUSTOMER'
}

export type Category = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly slug: Scalars['String'];
  readonly pinnedProducts: ReadonlyArray<Product>;
  readonly pinnedProductGroups: ReadonlyArray<ProductGroup>;
  readonly products: ReadonlyArray<Product>;
  readonly parent?: Maybe<Category>;
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
  readonly imageIds: ReadonlyArray<Scalars['String']>;
};

export enum CheckoutModuleType {
  Cxml = 'CXML',
  Oci = 'OCI',
  Shop = 'SHOP'
}

export type Country = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly iso2Code: Scalars['String'];
  readonly iso3Code: Scalars['String'];
};

export type Coupon = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly type: CouponType;
  readonly constraints: ReadonlyArray<CouponConstraint>;
  readonly productConstraints: ReadonlyArray<CouponConstraint>;
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly amountType: CouponAmountType;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly active: Scalars['Boolean'];
  readonly isCombinable: Scalars['Boolean'];
  readonly partnerIds: ReadonlyArray<Scalars['String']>;
  readonly maxRedemptions: Scalars['Int'];
  readonly maxRedemptionsPerUser: Scalars['Int'];
};

export enum CouponAmountType {
  Relativ = 'RELATIV',
  Absolut = 'ABSOLUT'
}

export type CouponConstraint = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly expression: Scalars['String'];
  readonly logicOperator: LogicOperator;
  readonly operator: ExpressionOperator;
  readonly value: Scalars['String'];
};

export type CouponConstraintInput = {
  readonly logicOperator: Scalars['String'];
  readonly expression: Scalars['String'];
  readonly operator: Scalars['String'];
  readonly value: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export enum CouponType {
  Fixed = 'FIXED',
  Product = 'PRODUCT'
}

export type CronJob = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly isActive: Scalars['Boolean'];
  readonly isPhp: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly timeCode: Scalars['String'];
  readonly command: Scalars['String'];
  readonly comment?: Maybe<Scalars['String']>;
  readonly output?: Maybe<Scalars['String']>;
};

export type Customer = SymfonyUserInterface & {
  readonly id?: Maybe<Scalars['ID']>;
  readonly customerNumber?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly emailAddress: Scalars['String'];
  /**
   * roles contains the assigned roles of the user. It's possible, due to a role hierarchy model, that the user
   * has more roles than there are actually assigned. To get all accessible roles of the user, use the
   * `reachableRoles` field.
   */
  readonly roles: ReadonlyArray<Scalars['String']>;
  /** reachableRows contains the actual resolved roles based on the role hierarchy. */
  readonly reachableRoles: ReadonlyArray<Scalars['String']>;
  readonly group: CustomerGroup;
  readonly condition: CustomerCondition;
  readonly numberOfLogins: Scalars['String'];
  readonly vatIdStatus: Scalars['Boolean'];
  readonly vatId: Scalars['String'];
  readonly checkoutModule?: Maybe<Scalars['String']>;
  readonly adminSchema: Scalars['String'];
  readonly lastLogin?: Maybe<Scalars['DateTime']>;
  readonly lastModificationDate?: Maybe<Scalars['DateTime']>;
  readonly creationDate?: Maybe<Scalars['DateTime']>;
  readonly addresses: ReadonlyArray<Address>;
  readonly addressIds: ReadonlyArray<Scalars['String']>;
  readonly partnerIds: ReadonlyArray<Scalars['String']>;
  readonly orders: ReadonlyArray<Order>;
  readonly orderIds: ReadonlyArray<Scalars['String']>;
  readonly userName: Scalars['String'];
};

export enum CustomerCondition {
  Condition_0 = 'CONDITION_0',
  Condition_1 = 'CONDITION_1',
  Condition_2 = 'CONDITION_2',
  Condition_3 = 'CONDITION_3',
  Condition_4 = 'CONDITION_4',
  Condition_5 = 'CONDITION_5',
  Condition_6 = 'CONDITION_6'
}

export enum CustomerGroup {
  Guest = 'GUEST',
  User = 'USER',
  Admin = 'ADMIN'
}

export type DefaultFilterInput = {
  readonly q?: InputMaybe<Scalars['String']>;
  readonly ids?: ReadonlyArray<Scalars['String']>;
};

export type ExpressionNode = {
  readonly id: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly operators: ReadonlyArray<Scalars['String']>;
  readonly valueType: ExpressionValueType;
};

export type ExpressionNodeFilterInput = {
  readonly type?: InputMaybe<ExpressionNodeType>;
  readonly q?: InputMaybe<Scalars['String']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum ExpressionNodeType {
  Product = 'PRODUCT',
  ShoppingCart = 'SHOPPING_CART'
}

export enum ExpressionOperator {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  GraterThen = 'GRATER_THEN',
  LessThen = 'LESS_THEN',
  GraterOrEqualThen = 'GRATER_OR_EQUAL_THEN',
  LessOrEqualThen = 'LESS_OR_EQUAL_THEN',
  Between = 'BETWEEN',
  In = 'IN',
  NotIn = 'NOT_IN',
  Match = 'MATCH'
}

export enum ExpressionValueType {
  Array = 'ARRAY',
  Boolean = 'BOOLEAN',
  Text = 'TEXT',
  Numeric = 'NUMERIC'
}

export enum FaqModerationStatus {
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export type FaqQuestion = {
  readonly id: Scalars['ID'];
  readonly text: Scalars['String'];
  readonly customer?: Maybe<Customer>;
  readonly moderator?: Maybe<Customer>;
  readonly status: FaqModerationStatus;
  readonly lastModificationDate: Scalars['DateTime'];
  readonly creationDate: Scalars['DateTime'];
  readonly product?: Maybe<Product>;
  readonly answer?: Maybe<Scalars['String']>;
};

export type File = {
  readonly id: Scalars['ID'];
  readonly path: Scalars['String'];
  readonly name: Scalars['String'];
  readonly isDir: Scalars['Boolean'];
  /** Die Größe der Datei in Byte. */
  readonly size: Scalars['Int'];
  readonly creationDate?: Maybe<Scalars['DateTime']>;
  readonly lastModificationDate?: Maybe<Scalars['DateTime']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly mimeType?: Maybe<Scalars['String']>;
  readonly imageMetadata: FileMetadata;
  readonly permissionMetadata: FilePermissions;
};

export type FileFilterInput = {
  readonly q?: InputMaybe<Scalars['String']>;
  readonly path?: InputMaybe<Scalars['String']>;
  readonly ids?: ReadonlyArray<Scalars['String']>;
  readonly accept?: ReadonlyArray<Scalars['String']>;
};

export type FileMetadata = {
  readonly title?: Maybe<Scalars['String']>;
  readonly alt?: Maybe<Scalars['String']>;
};

export type FilePermissions = {
  readonly readRoles?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly writeRoles?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum FileSystemExceptionCode {
  ErrNotFound = 'ERR_NOT_FOUND',
  ErrPermission = 'ERR_PERMISSION',
  ErrExists = 'ERR_EXISTS'
}

export type FormCustomizer = {
  /** unique customizer identifier */
  readonly id?: Maybe<Scalars['ID']>;
  /** name of the customizer */
  readonly name: Scalars['String'];
  /** optional notes to give other admins information about the configurator */
  readonly notes?: Maybe<Scalars['String']>;
  /** a list of products which are linked to the customizer */
  readonly products: ReadonlyArray<Product>;
  /** a list of customizer fields */
  readonly fields: ReadonlyArray<FormCustomizerField>;
};

export type FormCustomizerField = {
  /** Unique identifier for the customizer field */
  readonly id?: Maybe<Scalars['ID']>;
  /** Name for the customizer <input> field */
  readonly name: Scalars['String'];
  /** Title of the field, which will be shown above the input */
  readonly title: Scalars['String'];
  /** Optional description/help text of the field, which will be shown under the input */
  readonly description?: Maybe<Scalars['String']>;
  /** If the field type is set to {@link FormCustomizerFieldType::CHOICE}, the options represents the choices */
  readonly options?: Maybe<ReadonlyArray<FormCustomizerFieldOption>>;
  /**
   * Specify the type of the input
   * - {@link FormCustomizerFieldType::CHOICE} a selection field
   * - {@link FormCustomizerFieldType::INPUT} a single line input field
   * - {@link FormCustomizerFieldType::TEXTAREA} a multirow textarea field
   * - {@link FormCustomizerFieldType::FILE} a file upload field
   */
  readonly type: FormCustomizerFieldType;
  /** Formula with which the prices can be recalculated */
  readonly priceFormula?: Maybe<Scalars['String']>;
  /** Specifies if the input field is required */
  readonly isRequired: Scalars['Boolean'];
  /** Specifies the max text length that the customer can enter */
  readonly maxLength?: Maybe<Scalars['Int']>;
  /** A regular expression to validate the customer input */
  readonly regex?: Maybe<Scalars['String']>;
};

export type FormCustomizerFieldInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly type: FormCustomizerFieldType;
  readonly name: Scalars['String'];
  readonly isRequired: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly maxLength?: InputMaybe<Scalars['Int']>;
  readonly priceFormula?: InputMaybe<Scalars['String']>;
  readonly regex?: InputMaybe<Scalars['String']>;
  readonly options?: InputMaybe<ReadonlyArray<FormCustomizerFieldOptionInput>>;
};

export type FormCustomizerFieldOption = {
  /** value of option for the selection */
  readonly value: Scalars['String'];
};

export type FormCustomizerFieldOptionInput = {
  readonly value: Scalars['String'];
};

export enum FormCustomizerFieldType {
  Input = 'INPUT',
  Textarea = 'TEXTAREA',
  Choice = 'CHOICE',
  File = 'FILE'
}

export type IdRefInput = {
  readonly id: Scalars['String'];
};

export type ImageMetadataInput = {
  readonly title?: InputMaybe<Scalars['String']>;
  readonly alt?: InputMaybe<Scalars['String']>;
};

export type Language = {
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly locale: Scalars['String'];
};

export type LanguageRefInput = {
  readonly code: Scalars['String'];
};

export type ListMetadata = {
  readonly count: Scalars['Int'];
};

export enum LogicOperator {
  LogicAnd = 'LOGIC_AND',
  LogicOr = 'LOGIC_OR'
}

export type Mutation = {
  readonly createCancelOrder: CancelOrder;
  readonly createCategory: Category;
  readonly createCoupon: Coupon;
  readonly createCronJob: CronJob;
  readonly createCustomer: Customer;
  readonly createFaqQuestion: FaqQuestion;
  readonly createFile: File;
  /** create a new form customizer */
  readonly createFormCustomizer: FormCustomizer;
  readonly createOrderMail: OrderMail;
  readonly createPartner: Partner;
  readonly createProduct: Product;
  readonly createProductGroup: ProductGroup;
  readonly createRedirect: Redirect;
  readonly createSearchOptimization: SearchOptimization;
  readonly createSearchOptimizationReview: SearchOptimizationReview;
  readonly createShipping: Shipping;
  readonly deleteCategory: Category;
  readonly deleteCoupon: Coupon;
  readonly deleteCronJob: CronJob;
  readonly deleteCustomer: Customer;
  readonly deleteFaqQuestion: FaqQuestion;
  readonly deleteFile: File;
  /** remove a form customizer */
  readonly deleteFormCustomizer: FormCustomizer;
  readonly deleteOrder: Order;
  readonly deletePartner: Partner;
  readonly deleteProduct: Product;
  readonly deleteProductGroup: ProductGroup;
  readonly deleteRedirect: Redirect;
  readonly deleteSearchOptimization: SearchOptimization;
  readonly deleteSearchOptimizationReview: SearchOptimizationReview;
  readonly deleteShipping: Shipping;
  readonly updateCategory: Category;
  readonly updateCoupon: Coupon;
  readonly updateCronJob: CronJob;
  readonly updateCustomer: Customer;
  readonly updateFaqQuestion: FaqQuestion;
  readonly updateFile: File;
  /** update a form customizer */
  readonly updateFormCustomizer: FormCustomizer;
  readonly updatePartner: Partner;
  readonly updateProduct: Product;
  readonly updateProductGroup: ProductGroup;
  readonly updateRedirect: Redirect;
  readonly updateSearchOptimization: SearchOptimization;
  readonly updateSearchOptimizationReview: SearchOptimizationReview;
  readonly updateSetting: Setting;
  readonly updateShipping: Shipping;
};


export type MutationCreateCancelOrderArgs = {
  orderId: Scalars['Int'];
  hint: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  slug: Scalars['String'];
  url: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateCouponArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  amount: Scalars['Float'];
  isCombinable: Scalars['Boolean'];
  amountType: CouponAmountType;
  type: CouponType;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  partnerIds: ReadonlyArray<Scalars['String']>;
  constraints: ReadonlyArray<CouponConstraintInput>;
  productConstraints?: InputMaybe<ReadonlyArray<CouponConstraintInput>>;
  maxRedemptions?: InputMaybe<Scalars['Int']>;
  maxRedemptionsPerUser?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateCronJobArgs = {
  name: Scalars['String'];
  timeCode: Scalars['String'];
  command: Scalars['String'];
  isActive: Scalars['Boolean'];
  isPhp: Scalars['Boolean'];
  comment?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCustomerArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateFaqQuestionArgs = {
  productId: Scalars['String'];
  text: Scalars['String'];
};


export type MutationCreateFileArgs = {
  path: Scalars['String'];
  file?: InputMaybe<Scalars['Upload']>;
  isDirectory?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateFormCustomizerArgs = {
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  products: ReadonlyArray<IdRefInput>;
  fields: ReadonlyArray<FormCustomizerFieldInput>;
};


export type MutationCreateOrderMailArgs = {
  orderId: Scalars['Int'];
  mailType: OrderMailType;
};


export type MutationCreatePartnerArgs = {
  name: Scalars['String'];
  host: Scalars['String'];
  mediaHost: Scalars['String'];
  staticHost: Scalars['String'];
  imageHost: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  descriptionExpression: Scalars['String'];
  titleExpression: Scalars['String'];
  identifier: Scalars['String'];
  template: Scalars['String'];
  isSapPartner: Scalars['Boolean'];
  trustedShopsId?: InputMaybe<Scalars['String']>;
  googleTagManagerId?: InputMaybe<Scalars['String']>;
  usercentricsId?: InputMaybe<Scalars['String']>;
  checkoutModule: CheckoutModuleType;
  cartStorageMode: CartStorageMode;
};


export type MutationCreateProductArgs = {
  model: Scalars['String'];
};


export type MutationCreateProductGroupArgs = {
  model: Scalars['String'];
};


export type MutationCreateRedirectArgs = {
  path: Scalars['String'];
  target: Scalars['String'];
  statusCode: RedirectStatusCode;
  partnerIds: ReadonlyArray<Scalars['String']>;
};


export type MutationCreateSearchOptimizationArgs = {
  queries: ReadonlyArray<SearchQueryOptimizationQueryInput>;
  items: ReadonlyArray<SearchQueryOptimizationItemInput>;
  comment?: InputMaybe<Scalars['String']>;
};


export type MutationCreateSearchOptimizationReviewArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  query: Scalars['String'];
  message: Scalars['String'];
};


export type MutationCreateShippingArgs = {
  model: Scalars['String'];
  comment: Scalars['String'];
  name: Scalars['String'];
  selectable: Scalars['Boolean'];
  combinable: Scalars['Boolean'];
  autoAppend: ShippingAppendMode;
  shippingModule: IdRefInput;
  partnerIds: ReadonlyArray<Scalars['String']>;
  constraints: ReadonlyArray<ShippingProductConstraintInput>;
  priority?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCronJobArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFaqQuestionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFormCustomizerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePartnerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRedirectArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSearchOptimizationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSearchOptimizationReviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteShippingArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  pinnedProducts?: InputMaybe<ReadonlyArray<IdRefInput>>;
  pinnedProductGroups?: InputMaybe<ReadonlyArray<IdRefInput>>;
  imageIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type MutationUpdateCouponArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  amount: Scalars['Float'];
  isCombinable: Scalars['Boolean'];
  amountType: CouponAmountType;
  type: CouponType;
  language: LanguageRefInput;
  constraints: ReadonlyArray<CouponConstraintInput>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  partnerIds: ReadonlyArray<Scalars['String']>;
  productConstraints?: InputMaybe<ReadonlyArray<CouponConstraintInput>>;
  maxRedemptions?: InputMaybe<Scalars['Int']>;
  maxRedemptionsPerUser?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateCronJobArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  timeCode: Scalars['String'];
  command: Scalars['String'];
  isActive: Scalars['Boolean'];
  isPhp: Scalars['Boolean'];
  comment?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomerArgs = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  partnerIds: ReadonlyArray<Scalars['String']>;
  roles: ReadonlyArray<Role>;
  group: CustomerGroup;
  condition: CustomerCondition;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateFaqQuestionArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  answer?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateFileArgs = {
  id: Scalars['ID'];
  path: Scalars['String'];
  language?: InputMaybe<LanguageRefInput>;
  imageMetadata?: InputMaybe<ImageMetadataInput>;
  permissionMetadata?: InputMaybe<PermissionMetadataInput>;
};


export type MutationUpdateFormCustomizerArgs = {
  id: Scalars['ID'];
  language: LanguageRefInput;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  products: ReadonlyArray<IdRefInput>;
  fields: ReadonlyArray<FormCustomizerFieldInput>;
};


export type MutationUpdatePartnerArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  host: Scalars['String'];
  mediaHost: Scalars['String'];
  staticHost: Scalars['String'];
  imageHost: Scalars['String'];
  language: LanguageRefInput;
  title: Scalars['String'];
  description: Scalars['String'];
  descriptionExpression: Scalars['String'];
  titleExpression: Scalars['String'];
  identifier: Scalars['String'];
  template: Scalars['String'];
  isSapPartner: Scalars['Boolean'];
  trustedShopsId?: InputMaybe<Scalars['String']>;
  googleTagManagerId?: InputMaybe<Scalars['String']>;
  usercentricsId?: InputMaybe<Scalars['String']>;
  checkoutModule?: InputMaybe<CheckoutModuleType>;
  cartStorageMode: CartStorageMode;
  contactName?: InputMaybe<Scalars['String']>;
  contactMail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  ociMappings: ReadonlyArray<OciMappingInput>;
};


export type MutationUpdateProductArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type MutationUpdateProductGroupArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type MutationUpdateRedirectArgs = {
  id: Scalars['ID'];
  path: Scalars['String'];
  target: Scalars['String'];
  statusCode: RedirectStatusCode;
  partnerIds: ReadonlyArray<Scalars['String']>;
};


export type MutationUpdateSearchOptimizationArgs = {
  id: Scalars['ID'];
  queries: ReadonlyArray<SearchQueryOptimizationQueryInput>;
  items: ReadonlyArray<SearchQueryOptimizationItemInput>;
  comment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateSearchOptimizationReviewArgs = {
  id: Scalars['ID'];
  answer: Scalars['String'];
  solved: Scalars['Boolean'];
};


export type MutationUpdateSettingArgs = {
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateShippingArgs = {
  id: Scalars['String'];
  model: Scalars['String'];
  comment: Scalars['String'];
  name: Scalars['String'];
  selectable: Scalars['Boolean'];
  combinable: Scalars['Boolean'];
  autoAppend: ShippingAppendMode;
  shippingModule: IdRefInput;
  partnerIds: ReadonlyArray<Scalars['String']>;
  constraints: ReadonlyArray<ShippingProductConstraintInput>;
  language: LanguageRefInput;
  priority?: InputMaybe<Scalars['Int']>;
};

export type OciFieldValue = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly description?: Maybe<Scalars['String']>;
};

export type OciMapping = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly field?: Maybe<Scalars['String']>;
  readonly valueType?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type OciMappingField = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly length: Scalars['Int'];
  readonly description?: Maybe<Scalars['String']>;
};

export type OciMappingInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly field?: InputMaybe<Scalars['String']>;
  readonly valueType?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
};

export type Order = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly orderNumber: Scalars['String'];
  readonly paymentMethod: Scalars['String'];
  readonly lastModificationDate: Scalars['DateTime'];
  readonly creationDate: Scalars['DateTime'];
  readonly totalValue: Scalars['Float'];
  readonly deliveryAddress?: Maybe<OrderAddress>;
  readonly billingAddress?: Maybe<OrderAddress>;
  readonly customer?: Maybe<Customer>;
  readonly status?: Maybe<Scalars['String']>;
  readonly statusId?: Maybe<OrderStatus>;
  readonly history: ReadonlyArray<OrderStatusHistoryItem>;
  readonly partner: Partner;
  readonly note?: Maybe<Scalars['String']>;
  readonly totals: ReadonlyArray<OrderTotal>;
  readonly products: ReadonlyArray<OrderProduct>;
  readonly orderXml: Scalars['String'];
  readonly catalogOrdered: Scalars['Boolean'];
};

export type OrderAddress = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly fullName: Scalars['String'];
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly streetAddress?: Maybe<Scalars['String']>;
  readonly houseNumber?: Maybe<Scalars['String']>;
  readonly postCode?: Maybe<Scalars['String']>;
  readonly company1?: Maybe<Scalars['String']>;
  readonly company2?: Maybe<Scalars['String']>;
  readonly companyAddition?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly telephoneNumber?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Scalars['String']>;
  readonly lastModificationDate: Scalars['DateTime'];
  readonly creationDate: Scalars['DateTime'];
};

export type OrderMail = {
  readonly orderId: Scalars['Int'];
  readonly type: OrderMailType;
};

export enum OrderMailType {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER'
}

export type OrderProduct = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly type: OrderProductType;
  readonly model: Scalars['String'];
  readonly name: Scalars['String'];
  readonly deliveryTime: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly price: Scalars['Float'];
  readonly customData?: Maybe<Scalars['String']>;
  readonly totalPrice: Scalars['Float'];
  readonly tax: Scalars['Float'];
  readonly savings: Scalars['Float'];
  readonly product?: Maybe<Product>;
};

export enum OrderProductType {
  Product = 'PRODUCT',
  Coupon = 'COUPON',
  Shipping = 'SHIPPING',
  Flatrate = 'FLATRATE'
}

export enum OrderStatus {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  Shipped = 'SHIPPED',
  PaymentCanceled = 'PAYMENT_CANCELED',
  PaymentDeclined = 'PAYMENT_DECLINED',
  PaymentOpen = 'PAYMENT_OPEN',
  PaymentInProgress = 'PAYMENT_IN_PROGRESS',
  PaymentOk = 'PAYMENT_OK',
  PartDeliveryOpen = 'PART_DELIVERY_OPEN',
  PartDeliveryShipped = 'PART_DELIVERY_SHIPPED',
  Canceled = 'CANCELED'
}

export type OrderStatusHistoryItem = {
  readonly status: Scalars['String'];
  readonly isCustomerNotified: Scalars['Boolean'];
  readonly comment?: Maybe<Scalars['String']>;
  readonly dateTime: Scalars['DateTime'];
};

export type OrderTotal = {
  readonly title: Scalars['String'];
  readonly text: Scalars['String'];
  readonly value: Scalars['String'];
};

export type Partner = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly host: Scalars['String'];
  readonly imageHost: Scalars['String'];
  readonly staticHost: Scalars['String'];
  readonly mediaHost?: Maybe<Scalars['String']>;
  readonly orderMailAddress: Scalars['String'];
  readonly template: Scalars['String'];
  readonly trustedShopsId: Scalars['String'];
  readonly trustedShopRanking?: Maybe<Scalars['String']>;
  readonly trustedShopNumRatings?: Maybe<Scalars['Int']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly contactMail?: Maybe<Scalars['String']>;
  readonly contactPhone?: Maybe<Scalars['String']>;
  readonly googleTagManagerId?: Maybe<Scalars['String']>;
  readonly googleAnalyticsId?: Maybe<Scalars['String']>;
  readonly usercentricsId?: Maybe<Scalars['String']>;
  readonly isSapPartner: Scalars['Boolean'];
  readonly ociMappings: ReadonlyArray<OciMapping>;
  readonly checkoutModule: CheckoutModuleType;
  readonly cartStorageMode: CartStorageMode;
  readonly description?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly descriptionExpression?: Maybe<Scalars['String']>;
  readonly titleExpression?: Maybe<Scalars['String']>;
};

export type PermissionMetadataInput = {
  readonly readRoles?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly writeRoles?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Product = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly longText?: Maybe<Scalars['String']>;
  readonly model: Scalars['String'];
  readonly group?: Maybe<Scalars['String']>;
  readonly manufacturerModel?: Maybe<Scalars['String']>;
  readonly precursorModel?: Maybe<Scalars['String']>;
  readonly rebateClass: Scalars['String'];
  readonly salesAverage?: Maybe<Scalars['String']>;
  readonly salesIncrease?: Maybe<Scalars['String']>;
  readonly vpeValue: Scalars['String'];
  readonly weight: Scalars['String'];
  readonly stock: Scalars['Int'];
  readonly active: Scalars['Boolean'];
  readonly replenishmentTime: Scalars['Int'];
  readonly lastModificationDate: Scalars['DateTime'];
  readonly creationDate: Scalars['DateTime'];
  readonly partnerIds: ReadonlyArray<Scalars['String']>;
  readonly parent?: Maybe<ProductGroup>;
  readonly type: Scalars['String'];
  readonly catalogPage?: Maybe<Scalars['Int']>;
  readonly categories: ReadonlyArray<Category>;
  readonly categoryIds: ReadonlyArray<Scalars['String']>;
  readonly sale: Scalars['Boolean'];
  readonly formCustomizer?: Maybe<FormCustomizer>;
  readonly image?: Maybe<Scalars['String']>;
};

export type ProductFilterInput = {
  readonly q?: InputMaybe<Scalars['String']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly category?: InputMaybe<Scalars['String']>;
  readonly model?: InputMaybe<Scalars['String']>;
  readonly isChild?: InputMaybe<Scalars['Boolean']>;
  readonly includeInactive?: InputMaybe<Scalars['Boolean']>;
};

export type ProductGroup = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly model: Scalars['String'];
  readonly group?: Maybe<Scalars['String']>;
  readonly manufacturerModel?: Maybe<Scalars['String']>;
  readonly precursorModel?: Maybe<Scalars['String']>;
};

export type ProductGroupFilterInput = {
  readonly q?: InputMaybe<Scalars['String']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly category?: InputMaybe<Scalars['String']>;
};

export type Query = {
  readonly Address?: Maybe<Address>;
  readonly Category?: Maybe<Category>;
  /** Gibt den Gutschein mit der übergebenen ID zurück */
  readonly Coupon?: Maybe<Coupon>;
  /** Gibt den CronJob mit der übergebenen ID zurück */
  readonly CronJob?: Maybe<CronJob>;
  readonly Customer?: Maybe<Customer>;
  /** Gibt die Bedingung mit dem übergebenen Namen zurück */
  readonly ExpressionNode?: Maybe<ExpressionNode>;
  readonly FaqQuestion?: Maybe<FaqQuestion>;
  readonly File?: Maybe<File>;
  /** returns a form customizer with the passed language. */
  readonly FormCustomizer?: Maybe<FormCustomizer>;
  readonly Language?: Maybe<Language>;
  /** Gibt den OCI Feld Wert mit dem übergebenen Namen zurück */
  readonly OciFieldValue?: Maybe<OciFieldValue>;
  readonly OciMappingField?: Maybe<OciMappingField>;
  readonly Order?: Maybe<Order>;
  /** Gibt ein Partner anhand der ID zurück. */
  readonly Partner?: Maybe<Partner>;
  /** Gibt ein Produkt anhand der ID zurück. */
  readonly Product?: Maybe<Product>;
  /** Gibt eine Produktgruppe anhand der ID zurück. */
  readonly ProductGroup?: Maybe<ProductGroup>;
  /** Gibt den Redirect mit der übergebenen ID zurück */
  readonly Redirect?: Maybe<Redirect>;
  /** Gibt die Suchoptimierung mit der übergebenen ID zurück */
  readonly SearchOptimization?: Maybe<SearchOptimization>;
  /** Gibt das Review mit der übergebenen ID zurück */
  readonly SearchOptimizationReview?: Maybe<SearchOptimizationReview>;
  readonly Setting?: Maybe<Setting>;
  /** Gibt den Versand mit der übergebenen ID zurück */
  readonly Shipping?: Maybe<Shipping>;
  /** Gibt die Versandmethode mit dem übergebenen Namen zurück */
  readonly ShippingModule?: Maybe<ShippingModule>;
  readonly _allAddressesMeta: ListMetadata;
  readonly _allCategoriesMeta: ListMetadata;
  readonly _allCouponsMeta: ListMetadata;
  readonly _allCronJobsMeta: ListMetadata;
  readonly _allCustomersMeta: ListMetadata;
  readonly _allExpressionNodesMeta: ListMetadata;
  readonly _allFaqQuestionsMeta: ListMetadata;
  readonly _allFilesMeta: ListMetadata;
  /** returns the metadata for a list of form customizers */
  readonly _allFormCustomizersMeta: ListMetadata;
  readonly _allLanguagesMeta: ListMetadata;
  readonly _allOciFieldValuesMeta: ListMetadata;
  readonly _allOciMappingFieldsMeta: ListMetadata;
  readonly _allOrdersMeta: ListMetadata;
  readonly _allPartnersMeta: ListMetadata;
  readonly _allProductGroupsMeta: ListMetadata;
  readonly _allProductsMeta: ListMetadata;
  readonly _allRedirectsMeta: ListMetadata;
  readonly _allSearchOptimizationReviewsMeta: ListMetadata;
  readonly _allSearchOptimizationsMeta: ListMetadata;
  readonly _allSettingsMeta: ListMetadata;
  readonly _allShippingModulesMeta: ListMetadata;
  readonly _allShippingsMeta: ListMetadata;
  /** A list of resources that the current user can access. */
  readonly accessibleResources: ReadonlyArray<ApiResource>;
  /** Gibt alle Adressen zurück */
  readonly allAddresses: ReadonlyArray<Address>;
  /** Gibt alle Kunden zurück */
  readonly allCategories: ReadonlyArray<Category>;
  /** Gibt eine Liste aller Gutscheine zurück */
  readonly allCoupons: ReadonlyArray<Coupon>;
  /** Gibt eine Liste aller Cron Jobs zurück */
  readonly allCronJobs: ReadonlyArray<CronJob>;
  /** Gibt alle Kunden zurück */
  readonly allCustomers: ReadonlyArray<Customer>;
  /** Gibt eine Liste aller Bedingungen zurück, welche auf den Warenkorb oder Produkt angewendet werden können. */
  readonly allExpressionNodes: ReadonlyArray<ExpressionNode>;
  /** Gibt alle Fragen aller FAQ's zurück. */
  readonly allFaqQuestions: ReadonlyArray<FaqQuestion>;
  /** Gibt alle Dateien zurück. */
  readonly allFiles: ReadonlyArray<File>;
  readonly allFilesystemErrorCodes: ReadonlyArray<FileSystemExceptionCode>;
  /** returns a list of all form customizers */
  readonly allFormCustomizers: ReadonlyArray<FormCustomizer>;
  /** Gibt alle Kunden zurück */
  readonly allLanguages: ReadonlyArray<Language>;
  /** Gibt eine Liste aller OCI Feldwerte zurück */
  readonly allOciFieldValues: ReadonlyArray<OciFieldValue>;
  /** Returns a list of OCI Field Values */
  readonly allOciMappingFields: ReadonlyArray<OciMappingField>;
  readonly allOrders: ReadonlyArray<Order>;
  /** Gibt alle Partner zurück. */
  readonly allPartners: ReadonlyArray<Partner>;
  /** Gibt alle Produktgruppen zurück. */
  readonly allProductGroups: ReadonlyArray<ProductGroup>;
  /** Gibt alle Produkte zurück. */
  readonly allProducts: ReadonlyArray<Product>;
  /** Gibt eine Liste aller Redirects zurück */
  readonly allRedirects: ReadonlyArray<Redirect>;
  /** A list of all available roles. */
  readonly allRoles: ReadonlyArray<Role>;
  /** Gibt eine Liste aller Reviews zurück */
  readonly allSearchOptimizationReviews: ReadonlyArray<SearchOptimizationReview>;
  /** Gibt eine Liste aller Such Optimierungen zurück */
  readonly allSearchOptimizations: ReadonlyArray<SearchOptimization>;
  readonly allSettings: ReadonlyArray<Setting>;
  /** Gibt eine Liste aller Versandmethoden zurück */
  readonly allShippingModules: ReadonlyArray<ShippingModule>;
  readonly allShippings: ReadonlyArray<Shipping>;
  readonly me?: Maybe<SymfonyUserInterface>;
};


export type QueryAddressArgs = {
  id: Scalars['ID'];
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  language?: InputMaybe<LanguageRefInput>;
};


export type QueryCouponArgs = {
  id: Scalars['ID'];
  language?: InputMaybe<LanguageRefInput>;
};


export type QueryCronJobArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryExpressionNodeArgs = {
  id: Scalars['ID'];
};


export type QueryFaqQuestionArgs = {
  id: Scalars['ID'];
};


export type QueryFileArgs = {
  id: Scalars['ID'];
  language?: InputMaybe<LanguageRefInput>;
};


export type QueryFormCustomizerArgs = {
  id: Scalars['ID'];
  language?: InputMaybe<LanguageRefInput>;
};


export type QueryLanguageArgs = {
  id: Scalars['ID'];
};


export type QueryOciFieldValueArgs = {
  id: Scalars['ID'];
};


export type QueryOciMappingFieldArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryPartnerArgs = {
  id: Scalars['ID'];
  language?: InputMaybe<LanguageRefInput>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductGroupArgs = {
  id: Scalars['ID'];
};


export type QueryRedirectArgs = {
  id: Scalars['ID'];
};


export type QuerySearchOptimizationArgs = {
  id: Scalars['ID'];
};


export type QuerySearchOptimizationReviewArgs = {
  id: Scalars['ID'];
};


export type QuerySettingArgs = {
  id: Scalars['ID'];
};


export type QueryShippingArgs = {
  id: Scalars['ID'];
  language?: InputMaybe<LanguageRefInput>;
};


export type QueryShippingModuleArgs = {
  id: Scalars['ID'];
};


export type Query_AllAddressesMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllCategoriesMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllCouponsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllCronJobsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllCustomersMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllExpressionNodesMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ExpressionNodeFilterInput>;
};


export type Query_AllFaqQuestionsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllFilesMetaArgs = {
  filter?: InputMaybe<FileFilterInput>;
};


export type Query_AllFormCustomizersMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllLanguagesMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type Query_AllOciFieldValuesMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ExpressionNodeFilterInput>;
};


export type Query_AllOciMappingFieldsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ExpressionNodeFilterInput>;
};


export type Query_AllOrdersMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllPartnersMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllProductGroupsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductGroupFilterInput>;
};


export type Query_AllProductsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
};


export type Query_AllRedirectsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllSearchOptimizationReviewsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllSearchOptimizationsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllSettingsMetaArgs = {
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllShippingModulesMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type Query_AllShippingsMetaArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllAddressesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllCategoriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllCouponsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllCronJobsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllCustomersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllExpressionNodesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ExpressionNodeFilterInput>;
};


export type QueryAllFaqQuestionsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllFilesArgs = {
  filter?: InputMaybe<FileFilterInput>;
};


export type QueryAllFormCustomizersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllLanguagesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryAllOciFieldValuesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ExpressionNodeFilterInput>;
};


export type QueryAllOciMappingFieldsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ExpressionNodeFilterInput>;
};


export type QueryAllOrdersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllPartnersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllProductGroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductGroupFilterInput>;
};


export type QueryAllProductsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
};


export type QueryAllRedirectsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllSearchOptimizationReviewsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllSearchOptimizationsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllSettingsArgs = {
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllShippingModulesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};


export type QueryAllShippingsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DefaultFilterInput>;
};

export type Redirect = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly path: Scalars['String'];
  readonly target: Scalars['String'];
  readonly statusCode: RedirectStatusCode;
  readonly partnerIds: ReadonlyArray<Scalars['String']>;
};

export enum RedirectStatusCode {
  MovedPermanently = 'MOVED_PERMANENTLY',
  FoundMovedTemporarily = 'FOUND_MOVED_TEMPORARILY',
  SeeOther = 'SEE_OTHER',
  TemporaryRedirect = 'TEMPORARY_REDIRECT',
  PermanentRedirect = 'PERMANENT_REDIRECT',
  NotFound = 'NOT_FOUND',
  Gone = 'GONE'
}

export enum Role {
  User = 'USER',
  Pm = 'PM',
  Marketing = 'MARKETING',
  Purchasing = 'PURCHASING',
  Sales = 'SALES',
  HumanResources = 'HUMAN_RESOURCES',
  Admin = 'ADMIN',
  Maintainer = 'MAINTAINER',
  Api = 'API'
}

export type SearchOptimization = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly queries: ReadonlyArray<SearchOptimizationQuery>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly items: ReadonlyArray<SearchOptimizationItem>;
};

export type SearchOptimizationItem = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly type: SearchOptimizationItemType;
  readonly term: Scalars['String'];
};

export enum SearchOptimizationItemType {
  BoostUp = 'BOOST_UP',
  BoostDown = 'BOOST_DOWN',
  Remove = 'REMOVE',
  Add = 'ADD'
}

export type SearchOptimizationQuery = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly query: Scalars['String'];
};

export type SearchOptimizationReview = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly email: Scalars['String'];
  readonly searchQuery: Scalars['String'];
  readonly message: Scalars['String'];
  readonly answer?: Maybe<Scalars['String']>;
  readonly solved: Scalars['Boolean'];
};

export type SearchQueryOptimizationItemInput = {
  readonly term: Scalars['String'];
  readonly type: SearchOptimizationItemType;
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type SearchQueryOptimizationQueryInput = {
  readonly query: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type Setting = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
  readonly type: Type;
};

export type Shipping = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly model: Scalars['String'];
  readonly shippingModule?: Maybe<ShippingModule>;
  readonly constraints: ReadonlyArray<ShippingProductConstraint>;
  readonly autoAppend: ShippingAppendMode;
  readonly selectable: Scalars['Boolean'];
  readonly combinable: Scalars['Boolean'];
  readonly priority: Scalars['Int'];
  readonly partnerIds: ReadonlyArray<Scalars['String']>;
  readonly comment: Scalars['String'];
};

export enum ShippingAppendMode {
  None = 'NONE',
  IfEmpty = 'IF_EMPTY',
  Always = 'ALWAYS'
}

export type ShippingModule = {
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type ShippingProductConstraint = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly expression: Scalars['String'];
  readonly logicOperator: LogicOperator;
  readonly operator: ExpressionOperator;
  readonly value: Scalars['String'];
};

export type ShippingProductConstraintInput = {
  readonly logicOperator: Scalars['String'];
  readonly expression: Scalars['String'];
  readonly operator: Scalars['String'];
  readonly value: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
};

export type SymfonyUserImpl = SymfonyUserInterface & {
  readonly userName: Scalars['String'];
  readonly roles: ReadonlyArray<Scalars['String']>;
};

export type SymfonyUserInterface = {
  readonly userName: Scalars['String'];
  readonly roles: ReadonlyArray<Scalars['String']>;
};

export type Thumbnail = {
  readonly width: Scalars['Int'];
  readonly height: Scalars['Int'];
  readonly url: Scalars['String'];
};

export enum Type {
  String = 'STRING',
  Bool = 'BOOL',
  Int = 'INT',
  Float = 'FLOAT',
  Choice = 'CHOICE'
}
