import merge from "lodash/merge";
import {defaultTheme} from "react-admin";
import {ThemeOptions} from "@material-ui/core";

/**
 * PaletteColorKeys is a list of keys of properties with the PaletteColor type in the Themes Palette.
 */
export type PaletteColorKeys = "success" | "info" | "warning" | "error" | "primary" | "secondary";

/**
 * ThemeMode defines if the ui is visible in light or dark mode.
 */
export type ThemeMode = "light" | "dark";

export default (themeMode: string): ThemeOptions => {
    return merge({}, defaultTheme, {
        // eslint-disable-next-line max-len
        // https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=253746&secondary.color=98A4AE&secondary.text.color=ffffff
        palette: {
            primary: {
                contrastText: "#ffffff",
                dark: "#313d45",
                light: "#88959e",
                main: "#5B6770",
            },
            secondary: {
                contrastText: "#ffffff",
                dark: "#6a757f",
                light: "#c9d5e0",
                main: "#98a4ae",
            },
            type: themeMode,
        },
        overrides: {
            MuiFormControl: {
                marginDense: {
                    width: "100%",
                },
            },
            MuiTab: {
                root: {
                    textTransform: "none",
                },
            },
            MuiTable: {
                root: {
                    marginBottom: "2rem",
                },
            },
            MuiTableContainer: {
                root: {
                    width: "100%",
                },
            },
        },
    } as Record<string, unknown>);
};