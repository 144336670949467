/**
 * AuthenticationType represents the type with which an authentication was acquired and determines the shape of the
 * authentication data.
 */
export enum TokenType {
    /**
     * The authentication is provided via a "Authorization" Bearer header.
     * {@see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization}
     */
    BearerToken
}